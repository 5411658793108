import React from 'react';
import { Route } from 'react-router-dom';
import { RouteConfig } from 'src/types';

export const mapRoutes = (routes: Record<string, RouteConfig>) =>
  Object.entries(routes).map(([key, config]) => {
    const { path, component, exact } = config;

    return <Route exact={exact} component={component} path={path} key={key} />;
  });
