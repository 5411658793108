import React, { FC } from 'react';
import { HippocratesContentContacts } from 'src/api/types';
import FormContainer from 'src/components/FormContainer';
import { getHippocratesContentContacts, patchHippocratesContentContacts } from 'src/api';
import fieldsConfig from './fieldsConfig';

const filterSavePayload = (values: HippocratesContentContacts) => {
  const res = { ...values };

  if (typeof res?.logo === 'string') {
    delete res.logo;
  }

  return res;
};

type Props = unknown;

const ContactsPage: FC<Props> = () => {
  return (
    <FormContainer
      config={fieldsConfig}
      getContent={getHippocratesContentContacts}
      saveContent={patchHippocratesContentContacts}
      filterSavePayload={filterSavePayload}
    />
  );
};

export default ContactsPage;
