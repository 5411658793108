import { EventTest, EventTestQuestion, FormFieldConfig, Option } from 'src/types';
import * as yup from 'yup';
import { FormikErrors } from 'formik';
import { isPlainObject } from 'lodash';

export const getInitialValues = (config: FormFieldConfig[], defaultValues?: Record<string, any>) =>
  config.reduce(
    (acc, row) => ({
      ...acc,
      [row.name]: defaultValues?.[row.name] || '',
    }),
    {},
  );

export const getValidationSchema = (config: FormFieldConfig[]) =>
  yup.object().shape(
    config.reduce(
      (acc, row) => ({
        ...acc,
        [row.name]: row.validate,
      }),
      {},
    ),
  );

export const createOptionsFromStrings = (options: string[]): Option[] =>
  options.map(option => ({
    label: option,
    value: option,
  }));

export const getServerTerrors = (data): FormikErrors<any> => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: `ERRORS['${value}']`,
    };
  }, {});
};

export const getTestInitialValues = (test: EventTest) => {
  return test?.questions_set.reduce((acc, question) => {
    return {
      ...acc,
      [question.id]: question.multiple_answers ? [] : '',
    };
  }, {});
};

const getQuestionValidation = (question: EventTestQuestion) => {
  if (!question.answers_set.length) return null;

  return question.multiple_answers ? yup.array().required() : yup.string().required();
};

export const getTestFormValidationSchema = (test: EventTest) => {
  return yup.object().shape(
    test?.questions_set.reduce((acc, question) => {
      return {
        ...acc,
        [question.id]: getQuestionValidation(question),
      };
    }, {}),
  );
};

const getValueForFormData = value => {
  switch (true) {
    case isPlainObject(value):
      return JSON.stringify(value);

    default:
      return value;
  }
};

export function createFormData(initialValue) {
  return Object.entries(initialValue).reduce((formData, [key, srcValue]) => {
    const value = getValueForFormData(srcValue);

    if (Array.isArray(value)) {
      value.forEach(val => {
        formData.append(key, val);
      });
    } else {
      formData.append(key, value);
    }

    return formData;
  }, new FormData());
}
