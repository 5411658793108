import React, { FC, useState } from 'react';
import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backdropInvisible: {
    color: theme.palette.primary.main,
  },
  circle: {},
  circleInvisible: {},
}));

type Props = {
  defaultOpen?: boolean;
  invisible?: boolean;
  classesOverrides?: {
    backdrop?: string;
  };
};

const Backdrop: FC<Props> = ({ defaultOpen = true, invisible = false, classesOverrides }) => {
  const classes = useStyles();
  const [open] = useState(defaultOpen);
  const wrapperClass = clsx(classes.backdrop, classesOverrides?.backdrop, {
    [classes.backdropInvisible]: invisible,
  });
  const circleClass = clsx(classes.circle, { [classes.circleInvisible]: invisible });

  return (
    <MuiBackdrop className={wrapperClass} open={open} invisible={invisible}>
      <CircularProgress classes={{ root: circleClass }} color="inherit" />
    </MuiBackdrop>
  );
};

export default Backdrop;
