import { FormFieldConfig } from 'src/types';
import yup from 'src/utils/validation';

const fieldsConfig: FormFieldConfig[] = [
  {
    name: 'banner',
    label: 'COMMON.BANNER',
    fieldType: 'fileUpload',
    required: false,
    maxfilesize: 10000000,
    acceptedfiles: ['image/jpeg', 'image/png'],
  },
  {
    name: 'banner_button_text',
    label: 'COMMON.BANNER_BUTTON_TEXT',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'banner_header',
    label: 'COMMON.BANNER_HEADER',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'banner_text',
    label: 'COMMON.BANNER_TEXT',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'calendar_block_title',
    label: 'COMMON.CALENDAR_BLOCK_TITLE',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'categories_block_title',
    label: 'COMMON.CATEGORIES_BLOCK_TITLE',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'categories_block_subtitle',
    label: 'COMMON.CATEGORIES_BLOCK_SUBTITLE',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'events_block_title',
    label: 'COMMON.EVENTS_BLOCK_TITLE',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
];

export default fieldsConfig;
