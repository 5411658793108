import { Method, AxiosResponse } from 'axios';
import { ApiRequestOptions, PaginationQueryParams } from 'src/api/types';
import axiosClient, { CustomAxiosRequestConfig } from './axiosClient';
import { createFormData } from '../utils/helpers';

export const createApiRequest = <
  Result = any,
  Payload = Result,
  QueryParams = PaginationQueryParams,
  UrlParams = any
>(
  method: Method,
  url: ((any) => string) | string,
  isPrivate = false,
  formData = false,
) => (
  options?: ApiRequestOptions<Payload, QueryParams, UrlParams>,
): Promise<AxiosResponse<Result>> => {
  const payload =
    options?.payload && formData ? createFormData(options?.payload) : options?.payload;

  return axiosClient.request({
    method,
    url: typeof url === 'function' && options?.urlParams ? url(options?.urlParams) : url,
    data: payload,
    params: options?.queryParams,
    isPrivate,
  } as CustomAxiosRequestConfig);
};

export const createApiBlobRequest = <
  Result = any,
  Payload = Result,
  QueryParams = PaginationQueryParams,
  UrlParams = any
>(
  method: Method,
  url: ((any) => string) | string,
  isPrivate = false,
  formData = false,
) => (
  options?: ApiRequestOptions<Payload, QueryParams, UrlParams>,
): Promise<AxiosResponse<Result>> => {
  const payload =
    options?.payload && formData ? createFormData(options?.payload) : options?.payload;

  return axiosClient.request({
    method,
    url: typeof url === 'function' && options?.urlParams ? url(options?.urlParams) : url,
    data: payload,
    params: options?.queryParams,
    responseType: 'blob',
    isPrivate,
  } as CustomAxiosRequestConfig);
};
