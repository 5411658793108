import React, { FC, useMemo } from 'react';
import { FormEditWebinars } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { FormikConfig, Formik, Form } from 'formik';
import style from './FormControlCourses.module.scss';
import { getInitialValues, getValidationSchema } from '../../../../utils/helpers';
import { Button } from '@material-ui/core';
import FieldWithConfig from '../../../../components/FormWithConfig/FieldWithConfig';
export type Props = {
  config: FormEditWebinars;
  onSubmit: FormikConfig<any>['onSubmit'];
  defaultValues?: Record<string, any>;
  input: any;
};

const FormControlCourses: FC<Props> = ({ config, onSubmit, defaultValues, input }) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => getInitialValues(input, config), [input, config]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);

  return (
    <div className={style.wrapper}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting }) => {
          return (
            <>
              <Form autoComplete="off" noValidate>
                <div className={style.wrapper}>
                  {input.map(field => {
                    return <FieldWithConfig key={field.name} {...field} />;
                  })}
                </div>
                <div className={style.actions}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormControlCourses;
