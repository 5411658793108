import React, { FC, useMemo } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { FieldConfig, FieldProps } from 'formik';

type Props = TextFieldProps & FieldConfig & FieldProps;

const CustomTextField: FC<Props> = ({ field, ...rest }) => {
  const { t } = useTranslation();

  const error = useMemo(() => {
    const err = rest.form.errors[field.name];

    return err ? String(err) : '';
  }, [field, rest.form.errors]);

  return <TextField error={!!error} helperText={t(error)} {...rest} {...field} />;
};

export default CustomTextField;
