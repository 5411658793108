import React, { FC } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Switch, Redirect } from 'react-router-dom';
import { mapRoutes } from 'src/utils/helpers/router';
import { allRoutes, userRoutes } from 'src/pages/routesConfig';
import styles from './Main.module.scss';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

type Props = {
  isOpenDrawer: boolean;
};

const mappedRoutes = mapRoutes(allRoutes);

const Main: FC<Props> = ({ isOpenDrawer }) => {
  const classes = useStyles();

  return (
    <main
      className={clsx(styles.root, classes.content, {
        [classes.contentShift]: isOpenDrawer,
      })}
    >
      <Container disableGutters maxWidth={false}>
        <Switch>
          {mappedRoutes}
          <Redirect exact from="*" to={userRoutes.myProfile.path} />
        </Switch>
      </Container>
    </main>
  );
};

export default Main;
