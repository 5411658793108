import * as yup from 'yup';

yup.setLocale({
  array: {
    required: 'ERRORS.REQUIRED',
  },
  mixed: {
    required: 'ERRORS.REQUIRED',
  },
  string: {
    email: 'ERRORS.INVALID_EMAIL',
  },
});

export default yup;
