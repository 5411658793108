import React, { FC } from 'react';
import className from './UsersManagementPage.module.scss';
import { getUsers, deleteUsersId, getUsersExcel } from '../../../api';
import DataTable from 'src/components/DataTable';
import Container from '@material-ui/core/Container';
import { CellParams, ColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Dialog, Typography, DialogTitle, DialogActions } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import DiaogDelete from '../../../components/Dialog/Delete';
import { getFileNameFromContentDisposition } from '../../../utils/helpers/download';
import download from 'downloadjs';
import { formatDateCell } from '../../../utils/helpers/table';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import classes from '../../User/CertificatesPage/CertificatesPage.module.scss';
import SearchBar from '../../../components/DataTable/SearchBar';

type Props = unknown;

const UsersManagementPage: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [getReload, setGetReload] = React.useState();
  const [deleteItem, setDeleteItem] = React.useState();
  const [loadingDownload, setLoadingDownload] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState();

  const columns: ColDef[] = [
    { field: 'id', headerName: 'DATA_TABLE.ID', type: 'number', width: 100 },
    { field: 'full_name', headerName: 'DATA_TABLE.PIB', width: 360 },
    { field: 'specialization_name', headerName: 'DATA_TABLE.SPECIALTY', width: 150 },
    { field: '', headerName: 'DATA_TABLE.REGISTRATION_DATE', width: 120 },
    { field: 'phone', headerName: 'DATA_TABLE.PHONE_NUMBER', width: 135 },
    { field: 'email', headerName: 'DATA_TABLE.E_MAIL', width: 243 },
    {
      field: 'id',
      headerName: 'DATA_TABLE.ACTIONS',
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;
        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };
        return (
          <>
            <Link aria-label="edit" color="primary" to={{ pathname: `/edit-user/${id}` }}>
              <IconButton aria-label="edit" color="primary">
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };

  const deleteClose = () => {
    setDeleteDialog(false);
  };
  const deleteActive = async (values, actions) => {
    try {
      await deleteUsersId({ urlParams: { usersId: deleteItem } });
      setGetReload(true);
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };

  const usersDownloadExcel = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setLoadingDownload(true);
    getUsersExcel().then(response => {
      const {
        data,
        headers: { filename, 'content-disposition': contentDisposition },
      } = response;

      const nameFromDisposition = getFileNameFromContentDisposition(contentDisposition);
      const name = nameFromDisposition;
      download(data, name);
      setLoadingDownload(false);
    });
  };

  const handleSearch = async (values, actions) => {
    setSearch(values.search);
  };

  return (
    <Container disableGutters maxWidth="lg">
      <div className={className.navigationActions}>
        <Button
          className={className.actionRight}
          onClick={usersDownloadExcel}
          disabled={loadingDownload}
          variant="contained"
          size="large"
          color="primary"
        >
          {t('COMMON.EXPORT_TO_EXCEL')}
          {loadingDownload && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </div>
      <Typography className={className.tableHeader} variant="h6" id="tableTitle">
        {t('ROUTES.USERS_MANAGEMENT')}
      </Typography>

      <SearchBar search={search} handleSearch={handleSearch} />
      <DataTable
        getRowsApi={getUsers}
        columns={columns}
        reload={getReload}
        search={search}
      ></DataTable>

      <DiaogDelete
        deleteDialog={deleteDialog}
        deleteClose={deleteClose}
        removeActive={deleteActive}
      />
    </Container>
  );
};

export default UsersManagementPage;
