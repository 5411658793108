import React, { FC } from 'react';
import { getCourses, deleteCoursesId, postCourses, patchMe } from 'src/api';
import Container from '@material-ui/core/Container';
import DataTable from 'src/components/DataTable';
import { CellParams, ColDef } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Form, Formik } from 'formik';
import DiaogDelete from '../../../components/Dialog/Delete';
import classes from './CoursesManagementPage.module.scss';
import CreateCourses from './CreateCourses';
import { WebinarsForm } from '../../../api/types';
import className from '../StatisticsManagementPage/StatisticsManagementPage.module.scss';
import FieldWithConfig from '../../../components/FormWithConfig/FieldWithConfig';
import yup from '../../../utils/validation';
import styles from '../../../components/FormWithConfig/FormWithConfig.module.scss';
import SearchBar from '../../../components/DataTable/SearchBar';

type Props = unknown;

const CoursesManagementPage: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState();
  const [getReload, setGetReload] = React.useState();
  const [search, setSearch] = React.useState();

  const columns: ColDef[] = [
    { field: 'id', headerName: 'DATA_TABLE.ID', width: 90 },
    { field: 'name', headerName: 'DATA_TABLE.NAME_COURSES', width: 710 },
    { field: 'specialities', headerName: 'DATA_TABLE.SPECIALTY', width: 300 },
    {
      field: '',
      headerName: 'DATA_TABLE.ACTIONS',
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;

        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };
        return (
          <>
            <Link aria-label="edit" color="primary" to={{ pathname: `/edit-course/${id}` }}>
              <IconButton aria-label="edit" color="primary">
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };

  const deleteActive = async () => {
    try {
      await deleteCoursesId({ urlParams: { coursesId: deleteItem } });
      setGetReload(true);
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };

  const deleteClose = () => {
    setDeleteDialog(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    if (typeof res?.course_image === 'string') {
      delete res.course_image;
    }
    ['start_date', 'end_date'].forEach(field => {
      res[field] = new Date(res?.[field]).toISOString();
    });
    return res;
  };

  const handleSearch = async (values, actions) => {
    setSearch(values.search);
  };

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <SearchBar search={search} handleSearch={handleSearch} />
        <DataTable getRowsApi={getCourses} columns={columns} reload={getReload} search={search} />
        <DiaogDelete
          deleteDialog={deleteDialog}
          deleteClose={deleteClose}
          removeActive={deleteActive}
        />
      </Container>

      <div className={classes.wrapper_button}>
        <Button
          variant="contained"
          disabled={open}
          color="primary"
          aria-label="add"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Button>
      </div>

      <CreateCourses
        openModal={setOpen}
        openIs={open}
        saveContent={postCourses}
        reload={setGetReload}
        filterSavePayload={filterSavePayload}
      />
    </>
  );
};

export default CoursesManagementPage;
