import React, { FC, useCallback, useMemo } from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classes from '../../../pages/Admin/WebinarsManagementPage/WebinarsManagementPage.module.scss';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { Form, Formik, FormikConfig, useFormikContext, useFormik } from 'formik';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getInitialValues, getValidationSchema } from '../../../utils/helpers';
import styles from '../../FormWithConfig/FormWithConfig.module.scss';
import FieldWithConfig from '../../FormWithConfig/FieldWithConfig';
import { useTranslation } from 'react-i18next';
import yup from '../../../utils/validation';
import { useSnackbar } from 'notistack';
import { postWebinars } from 'src/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      borderRadius: '100%',
      padding: '20px 16px',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

export type Props = {
  // onSubmit: FormikConfig<any>['onSubmit'];
  saveContent: ({ payload }) => Promise<any>;
  openModal: any;
  openIs: boolean;
  filterSavePayload?: (payload) => any;
};
const CreateWebinarsModal: FC<Props> = ({
  openModal,
  openIs,
  filterSavePayload,
  saveContent,
  ...rest
}) => {
  const style = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [submit, setSubmit] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const input = [
    {
      name: 'is_draft',
      label: 'Чернетка',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'language',
      label: 'Мова',
      fieldType: 'radio',
      required: false,
      validate: null,
      options: [
        {
          label: 'Українська',
          value: 'ua',
        },
        {
          label: 'Русский',
          value: 'ru',
        },
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'name',
      label: 'Назва вебинара',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'description',
      label: 'Опис вебинара',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'start_date',
      label: 'Дати проведення вебинара (Початок)',
      fieldType: 'dateTimePicker',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'end_date',
      label: 'Дати проведення вебинара (Кінець)',
      fieldType: 'dateTimePicker',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'testing_end_date',
      label: 'Дата закриття доступу до тестування',
      fieldType: 'dateTimePicker',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'image',
      label: 'Зображення для сторінки вебинара',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
    {
      name: 'youtube_id',
      label: 'Youtube ID',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'text',
      label: 'Основний текст для сторінки вебинара',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'private',
      label: 'Закритий вебінар',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'points',
      label: 'Балів за вебінар',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 1,
      step: 1,
    },
    {
      name: 'success_percent',
      label: 'Процент правильних відповідей для проходження тесту',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
    {
      name: 'max_tries',
      label: 'Кількість спроб пройти тестування (0 - безліч)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'certificate_title',
      label: 'Назва сертифікату',
      fieldType: 'text',
      required: false,
      validate: null,
    },
  ];
  const initialValues = useMemo(() => getInitialValues(input), [input]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);

  const handleClose = useCallback(() => {
    openModal(false);
  }, [openModal]);

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };
  const submitWebinars = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      await saveContent({ payload });
      openModal(false);
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'lg'}
        open={openIs}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Formik
          onSubmit={submitWebinars}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <>
                <Form className={classes.form} autoComplete="off" noValidate>
                  <AppBar className={style.appBar}>
                    <Toolbar className={classes.flex_just}>
                      <div className={classes.flex}>
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {t('WEBINARS.ADD_WEBINAR')}
                        </Typography>
                      </div>
                      <div>
                        {/*<Button autoFocus color="inherit" onClick={handleClose}>*/}
                        {/*    {t('WEBINARS.ADD_AND_GO')}*/}
                        {/*</Button>*/}
                        <Button autoFocus color="inherit" type="submit">
                          {t('WEBINARS.ADD_TO')}
                        </Button>
                      </div>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <div className={classes.wrapper}>
                      <div className={classes.isForm}>
                        {input.map(field => {
                          return <FieldWithConfig key={field.name} {...field} />;
                        })}
                      </div>
                    </div>
                  </DialogContent>
                </Form>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateWebinarsModal;
