import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { getMe, getSpecializations, patchMe, resetPassword } from 'src/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import ConditionalRender from 'src/components/ConditionalRender';
import Backdrop from 'src/components/Backdrop';
import FormWithConfig from 'src/components/FormWithConfig';
import { useAuth } from 'src/context/AuthContext';
import { Specialization } from 'src/types';
import getFieldsConfig from './fieldsConfig';
import FormResetPassword from '../../../components/FormResetPassword';

type Props = unknown;

const ProfilePage: FC<Props> = () => {
  const [content, setContent] = useState();
  const [reset, setReset] = useState();
  const [specializations, setSpecializations] = useState<Specialization[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { setAuthDetails } = useAuth();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getMe();
      const { data: specializationsData } = await getSpecializations();
      setContent(data);
      setSpecializations(specializationsData.results);
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  const fieldsConfig = useMemo(() => {
    return getFieldsConfig({ specializations });
  }, [specializations]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async (values, actions) => {
    try {
      const payload = values;

      const { data } = await patchMe({ payload });

      setAuthDetails({
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        isAdmin: data.role,
        role: data.role,
      });
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const resetPasswordSubmit = async (values, actions) => {
    try {
      const payload = values;

      const { data } = await resetPassword({ payload });

      enqueueSnackbar(t('COMMON.SAVED_PASSWORD'), { variant: 'success' });
    } catch (e) {
      // console.log(e.response.data);
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <ConditionalRender condition={loading}>
      <Backdrop invisible />
      <Container disableGutters maxWidth="lg">
        <FormWithConfig defaultValues={content} onSubmit={handleSubmit} config={fieldsConfig} />
        <FormResetPassword onSubmit={resetPasswordSubmit} />
      </Container>
    </ConditionalRender>
  );
};

export default ProfilePage;
