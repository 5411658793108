import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SchoolIcon from '@material-ui/icons/School';
import AssessmentIcon from '@material-ui/icons/Assessment';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Category';
import TocIcon from '@material-ui/icons/Toc';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { RouteConfig } from 'src/types';
import withAdmin from 'src/hocs/withAdmin';
import ProfilePage from './User/ProfilePage';
import CoursesPage from './User/CoursesPage';
import ResultsPage from './User/ResultsPage';
import CertificatesPage from './User/CertificatesPage';
import CoursesManagementPage from './Admin/CoursesManagementPage';
import WebinarsManagementPage from './Admin/WebinarsManagementPage';
import EventsManagementPage from './Admin/EventsManagementPage';
import UsersManagementPage from './Admin/UsersManagementPage';
import CategoriesManagementPage from './Admin/CategoriesManagementPage';
import ContentManagementPage from './Admin/ContentManagementPage';
import StatisticsManagementPage from './Admin/StatisticsManagementPage';
import EditWebinarsPage from './Admin/WebinarsManagementPage/pages/EditPage';
import { webinarsRoutes } from './Admin/WebinarsManagementPage/pages/routes';
import { eventsRoutes } from './Admin/EventsManagementPage/pages/routes';
import { categoriesRoutes } from './Admin/CategoriesManagementPage/pages/routes';
import { coursesRoutes } from './Admin/CoursesManagementPage/pages/routes';
import { usersRoutes } from './Admin/UsersManagementPage/pages/routes';
import { statisticsRoutes } from './Admin/StatisticsManagementPage/pages/routes';

export enum UserRoute {
  Profile = 'myProfile',
  Courses = 'myCourses',
  Certificates = 'myCertificates',
}

export enum AdminRoute {
  CoursesManagement = 'coursesManagement',
  WebinarsManagement = 'webinarsManagement',
  EventsManagement = 'eventsManagement',
  UsersManagement = 'usersManagement',
  CategoriesManagement = 'categoriesManagement',
  ContentManagement = 'contentManagement',
  StatisticsManagement = 'statisticsManagement',
}

export const userRoutes: Record<UserRoute, RouteConfig> = {
  [UserRoute.Profile]: {
    path: '/profile',
    tKey: 'ROUTES.MY_PROFILE',
    Icon: AccountBoxIcon,
    component: ProfilePage,
    exact: true,
  },
  [UserRoute.Courses]: {
    path: '/user-courses',
    tKey: 'ROUTES.MY_COURSES',
    Icon: SchoolIcon,
    component: CoursesPage,
    exact: true,
  },
  [UserRoute.Certificates]: {
    path: '/user-certificates',
    tKey: 'ROUTES.MY_CERTIFICATES',
    Icon: EmojiEventsIcon,
    component: CertificatesPage,
    exact: true,
  },
};

export const adminRoutes: Record<AdminRoute, RouteConfig> = {
  [AdminRoute.CoursesManagement]: {
    path: '/course-management',
    tKey: 'ROUTES.COURSE_MANAGEMENT',
    Icon: SchoolIcon,
    component: withAdmin(CoursesManagementPage),
    exact: true,
  },
  [AdminRoute.WebinarsManagement]: {
    path: '/webinars-management',
    tKey: 'ROUTES.WEBINARS_MANAGEMENT',
    Icon: VideoLabelIcon,
    component: withAdmin(WebinarsManagementPage),
    exact: true,
  },
  [AdminRoute.EventsManagement]: {
    path: '/events-management',
    tKey: 'ROUTES.EVENTS_MANAGEMENT',
    Icon: EventIcon,
    component: withAdmin(EventsManagementPage),
    exact: true,
  },
  [AdminRoute.UsersManagement]: {
    path: '/users-management',
    tKey: 'ROUTES.USERS_MANAGEMENT',
    Icon: PeopleIcon,
    component: withAdmin(UsersManagementPage),
    exact: true,
  },
  [AdminRoute.CategoriesManagement]: {
    path: '/categories-management',
    tKey: 'ROUTES.CATEGORIES_MANAGEMENT',
    Icon: CategoryIcon,
    component: withAdmin(CategoriesManagementPage),
    exact: true,
  },
  [AdminRoute.ContentManagement]: {
    path: '/content-management',
    tKey: 'ROUTES.CONTENT_MANAGEMENT.ROOT',
    Icon: TocIcon,
    component: withAdmin(ContentManagementPage),
    exact: false,
  },
  [AdminRoute.StatisticsManagement]: {
    path: '/statistics',
    tKey: 'STATISTICS.STATISTICS',
    Icon: EqualizerIcon,
    component: withAdmin(StatisticsManagementPage),
    exact: true,
  },
};

export const allRoutes = {
  ...userRoutes,
  ...adminRoutes,
  ...webinarsRoutes,
  ...eventsRoutes,
  ...categoriesRoutes,
  ...coursesRoutes,
  ...usersRoutes,
  ...statisticsRoutes,
};
