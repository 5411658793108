import { format, addHours } from 'date-fns';
import { uk, ru } from 'date-fns/locale';
import { hasWindow } from 'src/utils/helpers/ssr';

const locales = {
  uk,
  ru,
};

window.__localeId__ = 'uk';

const clientTzOffset = new Date().getTimezoneOffset() / 60;

export const UTCDatetoLocalDate = (utcDate: Date) => {
  return addHours(utcDate, -clientTzOffset);
};

export const formatDate = (
  date: Date | number | string,
  formatStr: string,
  fromutc = true,
  options?,
) => {
  if (!date) return null;

  let dateVal = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;

  if (fromutc) {
    dateVal = UTCDatetoLocalDate(dateVal);
  }

  return format(dateVal, formatStr || 'PP', {
    locale: hasWindow() ? locales[window.__localeId__] : undefined,
    ...options,
  });
};
