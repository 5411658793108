import React, { FC, useCallback, useMemo } from 'react';
import { FieldConfig, FieldProps } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { bytesToMb, readableMimeTypes } from 'src/utils/helpers';
import clsx from 'clsx';
import styles from './FileUpload.module.scss';

type Props = FieldConfig &
  FieldProps & { multiple: boolean; maxfilesize: number; acceptedfiles: string[]; label?: string };

const FileUpload: FC<Props> = ({ field, multiple, maxfilesize, acceptedfiles, label, ...rest }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = useCallback(
    fs => {
      if (!fs.length) return;

      rest.form.setFieldValue(field.name, multiple ? fs : fs[0]);
    },
    [multiple, rest.form, field.name],
  );

  const selectedFile = useMemo(() => {
    if (!field.value) return null;

    return typeof field.value === 'string' ? field.value : URL.createObjectURL(field.value);
  }, [field.value]);

  const previewClass = clsx(styles.preview, { [styles.previewHidden]: !selectedFile });

  const onDropRejected = useCallback(
    rejectedFiles => {
      const fileName = rejectedFiles[0].name;
      enqueueSnackbar(
        t('ERRORS.FILE_MAX_SIZE', {
          fileName,
          maxSize: `${bytesToMb(maxfilesize)}mb`,
          allowedFormats: readableMimeTypes(acceptedfiles).join(', '),
        }),
        { variant: 'error' },
      );
    },
    [maxfilesize, acceptedfiles, enqueueSnackbar, t],
  );

  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.field}>
        <DropzoneArea
          {...rest}
          maxFileSize={maxfilesize}
          acceptedFiles={acceptedfiles}
          onChange={handleChange}
          showPreviews={false}
          filesLimit={1}
          dropzoneText={t('COMMON.DRAGNDROP_OR_CLICK')}
          useChipsForPreview={false}
          showPreviewsInDropzone={false}
          previewText=""
          showAlerts={false}
          onDropRejected={onDropRejected}
          getFileAddedMessage={() => ''}
          classes={{ root: styles.root, text: styles.text, textContainer: styles.textContainer }}
        />
        <div className={previewClass}>
          <img key={selectedFile} className={styles.previewImg} src={selectedFile} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
