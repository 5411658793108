import React, { FC } from 'react';
import DataTable from 'src/components/DataTable';
import {
  getCoursesStatisticsSummary,
  getCoursesStatisticsSummaryExcel,
  getWebinarsStatisticsSummary,
  getWebinarsStatisticsSummaryExcel,
} from 'src/api';

import Container from '@material-ui/core/Container';
import { CellParams, ColDef, GridApi } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import download from 'downloadjs';
import className from './StatisticsManagementPage.module.scss';
import { getFileNameFromContentDisposition } from '../../../utils/helpers/download';
import classes from '../../User/CertificatesPage/CertificatesPage.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import SearchBar from '../../../components/DataTable/SearchBar';

type Props = unknown;

const StatisticsPage: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loadingDownload, setLoadingDownload] = React.useState<boolean>(false);
  const [downloadId, setDownload] = React.useState(null);
  const [searchCourses, setSearchCourses] = React.useState();
  const [searchWebinars, setSearchWebinars] = React.useState();

  const coursesColumns: ColDef[] = [
    { field: 'id', headerName: 'DATA_TABLE.ID', type: 'number' },
    { field: 'name', headerName: 'DATA_TABLE.NAME_COURSES', width: 500 },
    {
      field: 'pass_counter',
      headerName: 'DATA_TABLE.PASS_COUNTER',
      width: 250,
    },
    {
      field: 'certificates_count',
      headerName: 'DATA_TABLE.CERTIFICATES_RECEIVED',
      width: 250,
    },
    {
      field: '',
      headerName: 'DATA_TABLE.ACTIONS',
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = () => {
          const { api } = params;
          const fields = api
            .getAllColumns()
            .map(c => c.field)
            .filter(c => c !== '__check__' && !!c);
          let thisRow;
          fields.forEach(f => {
            if (f == 'id') {
              thisRow = params.getValue(f);
            }
          });
          return thisRow;
        };
        return (
          <>
            <Link
              aria-label="edit"
              color="primary"
              to={{ pathname: `/statistics/courses/${id()}` }}
            >
              <IconButton aria-label="edit" color="primary">
                <VisibilityIcon />
              </IconButton>
            </Link>
          </>
        );
      },
    },
  ];

  const webinarsColumns: ColDef[] = [
    { field: 'id', headerName: 'DATA_TABLE.ID', type: 'number' },
    { field: 'name', headerName: 'DATA_TABLE.NAME_COURSES', width: 500 },
    {
      field: 'tests_count',
      headerName: 'DATA_TABLE.TESTS_COUNT',
      width: 150,
    },
    {
      field: 'certificates_count',
      headerName: 'DATA_TABLE.CERTIFICATES_RECEIVED',
      width: 150,
    },
    {
      field: 'users_registered',
      headerName: 'DATA_TABLE.USERS_REGISTERED',
      width: 150,
    },
    {
      field: 'users_online',
      headerName: 'DATA_TABLE.USERS_ONLINE',
      width: 150,
    },
    {
      field: 'anonymous_users_online',
      headerName: 'DATA_TABLE.ANONYMOUS_USERS_ONLINE',
      width: 150,
    },
    {
      field: 'authorized_users_online',
      headerName: 'DATA_TABLE.AUTHORIZED_USERS_ONLINE',
      width: 150,
    },
    {
      field: '',
      headerName: 'DATA_TABLE.ACTIONS',
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = () => {
          const { api } = params;
          const fields = api
            .getAllColumns()
            .map(c => c.field)
            .filter(c => c !== '__check__' && !!c);
          let thisRow;
          fields.forEach(f => {
            if (f == 'id') {
              thisRow = params.getValue(f);
            }
          });
          return thisRow;
        };
        return (
          <>
            <Link
              aria-label="edit"
              color="primary"
              to={{ pathname: `/statistics/webinars/${id()}` }}
            >
              <IconButton aria-label="edit" color="primary">
                <VisibilityIcon />
              </IconButton>
            </Link>
          </>
        );
      },
    },
  ];

  const coursesDownloadExcel = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setLoadingDownload(true);
    setDownload(1);
    getCoursesStatisticsSummaryExcel().then(response => {
      const {
        data,
        headers: { filename, 'content-disposition': contentDisposition },
      } = response;

      const nameFromDisposition = getFileNameFromContentDisposition(contentDisposition);
      const name = nameFromDisposition;

      download(data, name);
      setLoadingDownload(false);
    });
  };

  const webinarsDownloadExcel = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setLoadingDownload(true);
    setDownload(2);
    getWebinarsStatisticsSummaryExcel().then(response => {
      const {
        data,
        headers: { filename, 'content-disposition': contentDisposition },
      } = response;

      const nameFromDisposition = getFileNameFromContentDisposition(contentDisposition);
      const name = nameFromDisposition;

      download(data, name);
      setLoadingDownload(false);
    });
  };

  const handleSearchCourses = async (values, actions) => {
    setSearchCourses(values.search);
  };

  const handleSearchWebinars = async (values, actions) => {
    setSearchWebinars(values.search);
  };

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <div className={className.navigationActions}>
          <Button
            className={className.actionRight}
            disabled={loadingDownload && downloadId == 1}
            onClick={coursesDownloadExcel}
            variant="contained"
            size="large"
            color="primary"
          >
            {t('COMMON.EXPORT_TO_EXCEL')}
            {loadingDownload && downloadId == 1 && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </div>
        <Typography className={className.tableHeader} variant="h6" id="tableTitle">
          {t('STATISTICS.COURSES_STATISTICS')}
        </Typography>
        <SearchBar search={searchCourses} handleSearch={handleSearchCourses} />
        <DataTable
          getRowsApi={getCoursesStatisticsSummary}
          columns={coursesColumns}
          search={searchCourses}
        />
      </Container>

      <Container disableGutters maxWidth="lg">
        <div className={className.navigationActions}>
          <Button
            className={className.actionRight}
            onClick={webinarsDownloadExcel}
            disabled={loadingDownload && downloadId == 2}
            variant="contained"
            size="large"
            color="primary"
          >
            {t('COMMON.EXPORT_TO_EXCEL')}
            {loadingDownload && downloadId == 2 && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </div>
        <Typography className={className.tableHeader} variant="h6" id="tableTitle">
          {t('STATISTICS.WEBINARS_STATISTICS')}
        </Typography>
        <SearchBar search={searchWebinars} handleSearch={handleSearchWebinars} />
        <DataTable
          getRowsApi={getWebinarsStatisticsSummary}
          columns={webinarsColumns}
          search={searchWebinars}
        />
      </Container>
    </>
  );
};

export default StatisticsPage;
