import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import DataTable from 'src/components/DataTable';
import { getWebinars, postWebinars, deleteWebinarId } from 'src/api';
import { CellParams, ColDef } from '@material-ui/data-grid';
import { formatDateCell } from '../../../utils/helpers/table';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import classes from './WebinarsManagementPage.module.scss';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RouteConfig } from '../../../types';
import { Link } from 'react-router-dom';
import CreateWebinarsModal from '../../../components/Webinars/CreateModal';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { WebinarsForm } from '../../../api/types';
import DiaogDelete from '../../../components/Dialog/Delete';
import SearchBar from '../../../components/DataTable/SearchBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      borderRadius: '100%',
      padding: '20px 16px',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

type Props = {
  routes: Record<string, RouteConfig>;
  defaultRoute: string;
};

const WebinarsManagementPage: FC<Props> = () => {
  const style = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [getReload, setGetReload] = React.useState();
  const [deleteItem, setDeleteItem] = React.useState();
  const [search, setSearch] = React.useState();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };

  const deleteClose = () => {
    setDeleteDialog(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'DATA_TABLE.ID',
      width: 90,
    },
    {
      field: 'name',
      headerName: 'DATA_TABLE.NAME',
      width: 710,
    },
    {
      field: 'start_date',
      headerName: 'DATA_TABLE.DATE',
      width: 150,
      type: 'dateTime',
      valueFormatter: formatDateCell,
    },
    {
      field: 'end_date',
      headerName: 'DATA_TABLE.DATE',
      width: 150,
      type: 'dateTime',
      valueFormatter: formatDateCell,
    },
    {
      field: '',
      headerName: 'DATA_TABLE.ACTIONS',
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;
        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };

        return (
          <>
            <Link aria-label="edit" color="primary" to={{ pathname: `/edit-translation/${id}` }}>
              <IconButton aria-label="edit" color="primary">
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const deleteActive = async (values, actions) => {
    try {
      await deleteWebinarId({ urlParams: { webinarsId: deleteItem } });
      setGetReload(true);
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };

  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    if (typeof res?.image === 'string') {
      delete res.image;
    }

    ['start_date', 'end_date', 'testing_end_date'].forEach(field => {
      res[field] = new Date(res?.[field]).toISOString();
    });

    return res;
  };

  const handleSearch = async (values, actions) => {
    setSearch(values.search);
  };

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <SearchBar search={search} handleSearch={handleSearch} />
        <DataTable getRowsApi={getWebinars} columns={columns} reload={getReload} search={search} />
      </Container>
      <div className={classes.wrapper_button}>
        <Button
          variant="contained"
          disabled={open}
          color="primary"
          aria-label="add"
          className={style.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Button>
      </div>

      <CreateWebinarsModal
        openModal={setOpen}
        openIs={open}
        saveContent={postWebinars}
        filterSavePayload={filterSavePayload}
      />
      <DiaogDelete
        deleteDialog={deleteDialog}
        deleteClose={deleteClose}
        removeActive={deleteActive}
      />
    </>
  );
};

export default WebinarsManagementPage;
