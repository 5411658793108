import React, { FC, useCallback, useEffect, useState } from 'react';
import Backdrop from 'src/components/Backdrop';
import Container from '@material-ui/core/Container';
import ConditionalRender from 'src/components/ConditionalRender';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Bar, Pie } from 'react-chartjs-2';
import className from '../../EditPage/WebinarsEdidPage.module.scss';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getWebinarRealtimePollResultCharts } from '../../../../../../api';

type Props = unknown;

const RealtimePollResultsWebinarsPage: FC<Props> = () => {
  const { webinarsId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [series, setSeries] = useState();
  const [chartsData, setChartsData] = useState();

  const colors = [
    '#89dbec',
    '#fa9d00',
    '#ed0026',
    '#00909e',
    '#ffd08d',
    '#b00051',
    '#f68370',
    '#006884',
    '#feabb9',
    '#6e006c',
    '#91278f',
    '#cf97d7',
    '#000000',
    '#5b5b5b',
    '#d4d4d4',
  ];

  const fetchSeries = async () => {
    setLoading(true);

    try {
      const { data } = await getWebinarRealtimePollResultCharts({
        urlParams: { webinarsId },
      });

      console.log(data);

      setSeries(data);
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchChartsSeries = useEffect(() => {
    fetchSeries();
  }, [webinarsId]);

  const processChartsData = useEffect(() => {
    if (series) {
      setChartsData(
        series.map(item => {
          return {
            data: {
              labels: item.columns,
              datasets: [
                {
                  backgroundColor: colors,
                  data: item.series,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: item.question,
                fontColor: '#00000',
                fontStyle: 'normal',
                fontSize: 18,
                padding: 30,
              },
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      fontColor: '#00000',
                      fontSize: 14,
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      fontColor: '#00000',
                      fontSize: 14,
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem, data) => {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = dataset.data.reduce(function (
                      previousValue,
                      currentValue,
                      currentIndex,
                      array,
                    ) {
                      return previousValue + currentValue;
                    });
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = Math.floor((currentValue / total) * 100 + 0.5);
                    return `${currentValue} (${percentage + '%'})`;
                  },
                },
              },
            },
          };
        }),
      );
    }
  }, [series]);

  return (
    <ConditionalRender condition={loading}>
      <Backdrop invisible />
      <Container disableGutters maxWidth="lg">
        <Link
          className={[className.link].join(' ')}
          to={{ pathname: `/translation-realtime-poll-edit/${webinarsId}` }}
        >
          <Button variant="contained" size="large" color="default">
            {t('COMMON.BACK')}
          </Button>
        </Link>
        <Typography variant="h6" id="tableTitle">
          {t('WEBINARS.REALTIME_POLL_RESULTS')}
        </Typography>
        {chartsData &&
          chartsData.map((opts, key) => {
            return (
              <>
                <Bar key={key} height={100} data={opts.data} options={opts.options} />
                {/*<Pie key={key} height={100} data={opts.data} options={opts.options} />*/}
              </>
            );
          })}
      </Container>
    </ConditionalRender>
  );
};

export default RealtimePollResultsWebinarsPage;
