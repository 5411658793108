import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Backdrop from '../../../../../components/Backdrop';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ConditionalRender from '../../../../../components/ConditionalRender';
import className from './CoursesEdidPage.module.scss';
import { getCoursesId, getSpecializations, putCoursesId, deleteLessonsId } from 'src/api';
import FormControlCourses from '../../FormControl';
import Typography from '@material-ui/core/Typography';
import { Specialization, CoursesSet } from '../../../../../types';
import yup from '../../../../../utils/validation';
import { DataGrid, ColDef, CellParams } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DiaogDelete from '../../../../../components/Dialog/Delete';
import { WebinarsForm } from '../../../../../api/types';
import CreateCoursesLesson from '../../CreateLesson';

type Props = unknown;

const CoursesEditPage: FC<Props> = () => {
  const { coursesId } = useParams();
  const [content, setContent] = useState();
  const [contentLesson, setContentLesson] = useState<CoursesSet[]>([] as any);
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [specializations, setSpecializations] = useState<Specialization[]>();
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [getReload, setGetReload] = React.useState();
  const [deleteItem, setDeleteItem] = React.useState();
  const [open, setOpen] = React.useState(false);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getCoursesId({ urlParams: { coursesId } });
      const { data: specializationsData } = await getSpecializations();

      setContent(data);
      setSpecializations(specializationsData.results);

      const enhancers: any = data.lessons_set;
      if (data.lessons_set) {
        const lessons_set: any = enhancers.filter(a => (a.lesson_test = []));
        setContentLesson(lessons_set);
      }
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const input = [
    {
      name: 'is_draft',
      label: 'Чернетка',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'language',
      label: 'Мова',
      fieldType: 'radio',
      required: false,
      validate: null,
      options: [
        {
          label: 'Українська',
          value: 'ua',
        },
        {
          label: 'Русский',
          value: 'ru',
        },
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'add_to_favourites',
      label: 'Додати курс в обрані',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'name',
      label: 'Назва курса',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'description',
      label: 'Опис курса',
      fieldType: 'textarea',
      required: false,
      validate: null,
    },
    {
      name: 'points',
      label: 'Балів за курс',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 1,
      step: 1,
    },
    {
      name: 'author',
      label: 'Автор курса',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'specializations',
      label: 'Спеціальності користувачів, які зможуть приймати участь у навчанні',
      fieldType: 'autocomplete',
      required: false,
      validate: null,
      options: specializations
        ? specializations.map(spec => ({
            label: spec.name,
            value: spec.id,
          }))
        : [],
    },
    {
      name: 'start_date',
      label: 'Дати проведення курсу (початок)',
      fieldType: 'datePicker',
      required: true,
      validate: yup.string().required(),
      fromutc: true,
    },
    {
      name: 'end_date',
      label: 'Дати проведення курсу (кінец)',
      fieldType: 'datePicker',
      required: true,
      validate: yup.string().required(),
      fromutc: true,
    },

    {
      name: 'certificate_title',
      label: 'Назва сертифікату',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'certificate_available',
      label: 'Наявність сертифікату',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'test_time',
      label: 'Час на проходження тесту (хвилини)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'success_percent',
      label: 'Процент правильних відповідей для проходження курсу',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
    {
      name: 'max_tries',
      label: 'Кількість можливостей пройти курс (0 - безліч)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'course_image',
      label: 'Зображення для сторінки курсу',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
    {
      name: 'text',
      label: 'Опис курса',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'has_test',
      label: 'Додати тест після курсу',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'test_questions_count',
      label: 'Кількість тестових питань',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
  ];
  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };

  const deleteClose = () => {
    setDeleteDialog(false);
  };
  const deleteActive = async (values, actions) => {
    try {
      await deleteLessonsId({ urlParams: { coursesId: content?.id, lessonsId: deleteItem } });

      setContentLesson(contentLesson.filter(a => a.id !== deleteItem));
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    if (typeof res?.course_image === 'string') {
      delete res.course_image;
    }

    ['start_date', 'end_date'].forEach(field => {
      res[field] = new Date(res?.[field]).toISOString();
    });

    return res;
  };

  const handleSubmit = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;

      await putCoursesId({
        payload,
        urlParams: { coursesId },
      });

      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };
  const columns: ColDef[] = [
    { field: 'id', headerName: t('DATA_TABLE.ID') },
    { field: 'name', headerName: t('DATA_TABLE.NAME'), width: 1000 },
    {
      field: '',
      headerName: t('DATA_TABLE.ACTIONS'),
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;

        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };
        return (
          <>
            <Link
              aria-label="edit"
              color="primary"
              to={{ pathname: `/edit-course/${content?.id}/lesson/${id}` }}
            >
              <IconButton aria-label="edit" color="primary">
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  return (
    <>
      <ConditionalRender condition={loading}>
        <Backdrop invisible />
        <Container disableGutters maxWidth="lg">
          <Link className={className.link} to={{ pathname: `/course-management` }}>
            <Button variant="contained" size="large" color="default">
              {t('COMMON.BACK')}
            </Button>
          </Link>
          <Typography variant="h6" id="tableTitle" className={className.title}>
            {t('COURSESPAGE.EDIT')}
          </Typography>
          <FormControlCourses
            onSubmit={handleSubmit}
            defaultValues={content}
            config={content}
            input={input}
          />
          <div className={[className.buttonFooter, className.tableBottom].join(' ')}>
            <Link
              className={[className.link, className.tableBottom].join(' ')}
              to={{ pathname: `/course-test-edit/${content?.id}` }}
            >
              <Button
                variant="contained"
                size="large"
                color="default"
                className={className.tableBottom}
              >
                {' '}
                {t('COURSESPAGE.EDIT_TEST_COURSE')}
              </Button>
            </Link>
            <DataGrid autoHeight columns={columns} rows={contentLesson || []} loading={loading} />
          </div>
          <Button
            variant="contained"
            size="large"
            color="default"
            className={className.link}
            disabled={open}
            onClick={handleClickOpen}
          >
            {t('COURSESPAGE.CREATE_LESSON')}
          </Button>

          <DiaogDelete
            deleteDialog={deleteDialog}
            deleteClose={deleteClose}
            removeActive={deleteActive}
          />
        </Container>
      </ConditionalRender>
      <CreateCoursesLesson
        openModal={setOpen}
        openIs={open}
        coursesId={content?.id}
        saveContent={setContentLesson}
        reload={setGetReload}
        filterSavePayload={filterSavePayload}
      />
    </>
  );
};

export default CoursesEditPage;
