import React, { FC, useMemo } from 'react';
import { FormFieldConfig } from 'src/types';
import { Form, Formik, FormikConfig } from 'formik';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { getInitialValues, getValidationSchema } from '../../utils/helpers';

import styles from './FormWithConfig.module.scss';
import FieldWithConfig from './FieldWithConfig';
import Backdrop from '../Backdrop';

export type Props = {
  config: FormFieldConfig[];
  onSubmit: FormikConfig<any>['onSubmit'];
  defaultValues?: Record<string, any>;
};

const FormWithConfig: FC<Props> = ({ config, onSubmit, defaultValues }) => {
  const { t } = useTranslation();
  const initialValues = useMemo(() => getInitialValues(config, defaultValues), [
    config,
    defaultValues,
  ]);

  const validationSchema = useMemo(() => getValidationSchema(config), [config]);

  return (
    <div className={styles.wrapper}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting }) => {
          return (
            <>
              {isSubmitting && <Backdrop invisible />}
              <Form className={styles.form} autoComplete="off" noValidate>
                {config.map(field => {
                  return <FieldWithConfig key={field.name} {...field} />;
                })}
                <Divider />
                <div className={styles.actions}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormWithConfig;
