import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ConditionalRender from '../../../../../components/ConditionalRender';
import Backdrop from '../../../../../components/Backdrop';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import className from '../EditPage/CoursesEdidPage.module.scss';
import {
  getCoursesLessonsId,
  getCoursesLessonsIdFiles,
  putCoursesId,
  putCoursesLessonsId,
  postCoursesLessonsIdFiles,
  deleteCoursesLessonsIdFiles,
} from 'src/api';
import DeleteIcon from '@material-ui/icons/Delete';

import FormControlCourses from '../../FormControl';
import styles from '../../../../../components/FormWithConfig/FieldWithConfig/FileUpload/FileUpload.module.scss';
import { DropzoneArea } from 'material-ui-dropzone';
import { bytesToMb, readableMimeTypes } from '../../../../../utils/helpers';
import DataTable from 'src/components/DataTable';
import { CellParams, ColDef, DataGrid } from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/core/SvgIcon/SvgIcon';
import DiaogDelete from '../../../../../components/Dialog/Delete';

type Props = unknown;
const CoursesEditLessonsPage: FC<Props> = () => {
  const { coursesId } = useParams();
  const { coursesLessonId } = useParams();
  const [content, setContent] = useState();
  const [acceptedfiles, setAcceptedfiles] = useState();
  const [file, setFile] = useState();
  const [fileUpload, setFileUpload] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getCoursesLessonsId({
        urlParams: { coursesId: coursesId, lessonsId: coursesLessonId },
      });
      const { data: files } = await getCoursesLessonsIdFiles({
        urlParams: {
          coursesId: coursesId,
          lessonsId: coursesLessonId,
        },
      });
      setContent(data);
      setFile(files?.results);
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const input = [
    {
      name: 'name',
      label: 'Назва курса',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'author',
      label: 'Автор уроку',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'has_test',
      label: 'Додати тест після уроку',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'test_questions_count',
      label: 'Кількість тестових питань',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 1,
    },
    {
      name: 'order_number',
      label: 'Порядковий номер уроку',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 1,
    },
    {
      name: 'test_time',
      label: 'Час проходження уроку',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'text',
      label: 'Основний текст для сторінки курсу',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
  ];
  const handleSubmit = async (values, actions) => {
    try {
      const payload = values;

      await putCoursesLessonsId({
        payload,
        urlParams: { coursesId: coursesId, lessonsId: coursesLessonId },
      });

      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleChange = useCallback(
    async fs => {
      if (!fs.length) return;
      try {
        const payload = { file: fs };
        const { data } = await postCoursesLessonsIdFiles({
          payload,
          urlParams: {
            coursesId: coursesId,
            lessonsId: coursesLessonId,
          },
        });
        setFile(Array => [...Array, data]);
      } catch {
        enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [file, fileUpload],
  );

  const selectedFile = useMemo(() => {}, [file, fileUpload]);

  const onDropRejected = useCallback(
    rejectedFiles => {
      const fileName = rejectedFiles[0].name;
      enqueueSnackbar(
        t('ERRORS.FILE_MAX_SIZE', {
          fileName,
          maxSize: `${bytesToMb(500000000)}mb`,
          allowedFormats: readableMimeTypes(acceptedfiles).join(', '),
        }),
        { variant: 'error' },
      );
    },
    [acceptedfiles, enqueueSnackbar, t],
  );
  const deleteActive = async (values, actions) => {
    try {
      await deleteCoursesLessonsIdFiles({
        urlParams: {
          coursesId: coursesId,
          lessonsId: coursesLessonId,
          filesId: deleteItem,
        },
      });

      setFile(file.filter(a => a.id != deleteItem));
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };
  const deleteClose = () => {
    setDeleteDialog(false);
  };
  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };
  const columns: ColDef[] = [
    { field: 'name', headerName: t('DATA_TABLE.NAME_COURSES'), width: 560 },
    {
      field: '',
      headerName: t('DATA_TABLE.ACTIONS'),
      width: 78,
      headerClassName: styles.wrapperTable,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;

        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };
        return (
          <>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ConditionalRender condition={loading}>
        <Backdrop invisible />
        <Container disableGutters maxWidth="lg">
          <Link className={className.link} to={{ pathname: `/edit-course/${coursesId}` }}>
            <Button variant="contained" size="large" color="default">
              {t('COMMON.BACK')}
            </Button>
          </Link>
          <Typography variant="h6" id="tableTitle" className={className.title}>
            {t('COURSESPAGE.EDIT_LESSON')} "{content?.name}"
          </Typography>
          <FormControlCourses
            onSubmit={handleSubmit}
            defaultValues={content}
            config={content}
            input={input}
          />

          <div className={styles.wrapperFile}>
            <div className={styles.fieldDownload}>
              <div className={styles.fieldDownloadTable}>
                <DataGrid autoHeight columns={columns} rows={file || []} loading={loading} />
              </div>
              <DropzoneArea
                {...setFileUpload}
                maxFileSize={100000000000}
                acceptedFiles={acceptedfiles}
                onChange={handleChange}
                showPreviews={false}
                filesLimit={1}
                dropzoneText={t('COMMON.DRAGNDROP_OR_CLICK')}
                useChipsForPreview={false}
                showPreviewsInDropzone={false}
                previewText=""
                showAlerts={false}
                onDropRejected={onDropRejected}
                getFileAddedMessage={() => ''}
                classes={{
                  root: styles.root,
                  text: styles.text,
                  textContainer: styles.textContainer,
                }}
              />
            </div>
          </div>

          <div className={[className.buttonFooter, className.tableBottom].join(' ')}>
            <Link
              className={[className.link, className.tableBottom].join(' ')}
              to={{ pathname: `/edit-course/${coursesId}/lesson/${content?.id}/test` }}
            >
              <Button
                variant="contained"
                size="large"
                color="default"
                className={className.tableBottom}
              >
                {t('COURSESPAGE.EDIT_LESSON_TEST_COURSE')}
              </Button>
            </Link>
          </div>
        </Container>
      </ConditionalRender>
      <DiaogDelete
        deleteDialog={deleteDialog}
        deleteClose={deleteClose}
        removeActive={deleteActive}
      />
    </>
  );
};
export default CoursesEditLessonsPage;
