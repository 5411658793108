import React, { FC, useCallback, useMemo, useState } from 'react';
import DataTable from 'src/components/DataTable';
import { getWebinarId, getWebinarsStatisticsById, getWebinarsStatisticsByIdExcel } from 'src/api';
import { Link, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import columns from './columns';
import className from './../../StatisticsManagementPage.module.scss';
import { Button } from '@material-ui/core';
import { downloadFileUtil } from '../../../../User/CertificatesPage';
import { getFileNameFromContentDisposition } from '../../../../../utils/helpers/download';
import download from 'downloadjs';

type Props = unknown;

const WebinarStatisticsDetailPage: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { webinarsId } = useParams();
  const [course, setCourse] = useState();

  const { t } = useTranslation();

  useMemo(async () => {
    try {
      const { data } = await getWebinarId({ urlParams: { webinarsId } });
      setCourse(data);
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    }
  }, [webinarsId]);

  const fetchRows = async params => {
    const data = await getWebinarsStatisticsById({
      queryParams: params.queryParams,
      urlParams: { webinarsId },
    });
    return data;
  };

  const downloadExcel = async (event: React.MouseEvent) => {
    event.stopPropagation();

    getWebinarsStatisticsByIdExcel({
      urlParams: { webinarsId },
    }).then(response => {
      const {
        data,
        headers: { filename, 'content-disposition': contentDisposition },
      } = response;

      const nameFromDisposition = getFileNameFromContentDisposition(contentDisposition);
      const name = nameFromDisposition;

      download(data, name);
    });
  };

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <div className={className.navigationActions}>
          <Link
            className={`${className.action} ${className.link}`}
            to={{ pathname: `/statistics` }}
          >
            <Button variant="contained" size="large" color="default">
              {t('COMMON.BACK')}
            </Button>
          </Link>
          <Button
            className={className.actionRight}
            onClick={downloadExcel}
            variant="contained"
            size="large"
            color="primary"
          >
            {t('COMMON.EXPORT_TO_EXCEL')}
          </Button>
        </div>
        <Typography className={className.tableHeader} variant="h6" id="tableTitle">
          {course?.name}
        </Typography>
        <DataTable getRowsApi={fetchRows} columns={columns} />
      </Container>
    </>
  );
};

export default WebinarStatisticsDetailPage;
