import { FormFieldConfig } from 'src/types';
import yup from 'src/utils/validation';

const fieldsConfig: FormFieldConfig[] = [
  {
    name: 'logo',
    label: 'COMMON.BANNER',
    fieldType: 'fileUpload',
    required: false,
    maxfilesize: 10000000,
    acceptedfiles: ['image/jpeg', 'image/png'],
  },
  {
    name: 'title',
    label: 'COMMON.TITLE',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
];

export default fieldsConfig;
