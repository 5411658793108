import React, { FC, useCallback, useMemo, useState } from 'react';
import { FieldConfig, FieldProps } from 'formik';
import ChipInput from 'material-ui-chip-input';
import { castArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './CustomChipInput.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Option } from '../../../../types';
import { TFunction } from 'i18next';

type Props = FieldConfig &
  FieldProps & { options: Option[]; mapoptions: (options: Option[], t: TFunction) => Option[] };

const CustomChipInput: FC<Props> = ({ field, options, ...rest }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { t } = useTranslation();

  const error = useMemo(() => {
    const err = rest.form.errors[field.name];
    const touched = rest.form?.touched?.[field.name];

    return err && touched ? String(err) : '';
  }, [field, rest.form]);

  const wrapperClass = clsx(styles.wrapper, { [styles.wrapperError]: !!error });

  const onInputValueUpdate = e => {
    setInputValue(e.currentTarget.value);
  };

  const onAdd = useCallback(
    chip => {
      if (!chip) return;

      const newVal = [...field.value, chip];
      rest.form.setFieldValue(field.name, newVal);
      setInputValue('');
    },
    [field.name, field.value, rest.form],
  );

  const onDelete = useCallback(
    chip => {
      const newVal = field.value.filter(val => val !== chip);
      rest.form.setFieldValue(field.name, newVal);
    },
    [field.name, field.value, rest.form],
  );

  return (
    <ChipInput
      blurBehavior="add"
      error={!!error}
      helperText={t(error)}
      value={castArray(field.value)}
      onAdd={onAdd}
      onDelete={onDelete}
      inputValue={inputValue}
      onUpdateInput={onInputValueUpdate}
      className={wrapperClass}
      {...rest}
    />
  );
};

export default CustomChipInput;
