import React, { ComponentType, FC, useMemo } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRouter from '../../hooks/useRouter';

type Props = {
  icon?: ComponentType<any>;
  text: string;
  to: string;
};

const ListItemLink: FC<Props> = ({ icon: Icon, text, to }) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const isCurrent = useMemo(() => {
    return pathname.includes(to);
  }, [to, pathname]);

  const renderLink = React.useMemo(
    // @ts-ignore
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} selected={isCurrent}>
        {Icon ? (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        ) : null}
        <ListItemText primary={t(text)} />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
