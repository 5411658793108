import { RouteConfig } from '../../../../types';
import withAdmin from '../../../../hocs/withAdmin';
import CategoriesEditPage from './EditPage';
import SchoolIcon from '@material-ui/core/SvgIcon/SvgIcon';

export enum CategoriesRoute {
  categoriesId = ':categoriesId',
}
export const categoriesRoutes: Record<CategoriesRoute, RouteConfig> = {
  [CategoriesRoute.categoriesId]: {
    path: '/edit-speciality/:categoriesId',
    tKey: 'ROUTES.CATEGORIES.EDIT',
    Icon: SchoolIcon,
    component: withAdmin(CategoriesEditPage),
    exact: true,
  },
};
