import { RouteConfig } from '../../../../types';
import SchoolIcon from '@material-ui/core/SvgIcon/SvgIcon';
import withAdmin from '../../../../hocs/withAdmin';
import EditUsersPage from './EditPage';
export enum UsersRoute {
  userId = ':id',
}
export const usersRoutes: Record<UsersRoute, RouteConfig> = {
  [UsersRoute.userId]: {
    path: '/edit-user/:id',
    tKey: 'ROUTES.USERS.EDIT',
    Icon: SchoolIcon,
    component: withAdmin(EditUsersPage),
    exact: true,
  },
};
