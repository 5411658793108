import { FormFieldConfig } from 'src/types';
import yup from 'src/utils/validation';

const fieldsConfig: FormFieldConfig[] = [
  {
    name: 'banner',
    label: 'COMMON.BANNER',
    fieldType: 'fileUpload',
    required: false,
    maxfilesize: 10000000,
    acceptedfiles: ['image/jpeg', 'image/png'],
  },
  {
    name: 'title',
    label: 'COMMON.TITLE',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'text',
    label: 'COMMON.TEXT',
    fieldType: 'richText',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'first_logo',
    label: 'COMMON.FIRST_LOGO',
    fieldType: 'fileUpload',
    required: false,
    maxfilesize: 10000000,
    acceptedfiles: ['image/jpeg', 'image/png'],
  },
  {
    name: 'second_logo',
    label: 'COMMON.SECOND_LOGO',
    fieldType: 'fileUpload',
    required: false,
    maxfilesize: 10000000,
    acceptedfiles: ['image/jpeg', 'image/png'],
  },
];

export default fieldsConfig;
