import React, { FC, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import {
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Drawer,
  makeStyles,
  List,
  Menu,
  MenuItem,
  Button,
  ListSubheader,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
import useTheme from '@material-ui/core/styles/useTheme';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FaceIcon from '@material-ui/icons/Face';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import { useAuth } from 'src/context/AuthContext';
import { logout } from 'src/api/auth';
import { siteUrl } from '../../constants/sites';
import { adminRoutes, allRoutes, UserRoute, userRoutes } from '../../pages/routesConfig';
import ListItemLink from '../ListItemLink';
import useRouter from '../../hooks/useRouter';
import ConditionalRender from '../ConditionalRender';
import useMobileDetect from '../MobileDetect';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#20aeea',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  userProfile: {
    marginLeft: 'auto',
  },
  accountBtn: {
    textTransform: 'none',
  },
}));

type Props = {
  onDrawerOpenChange: (open: boolean) => void;
};

const Header: FC<Props> = ({ onDrawerOpenChange }) => {
  const { t } = useTranslation();
  const currentDevice = useMobileDetect();

  const {
    state: { isLoggedIn, firstName, lastName, isAdmin, role },
    removeAuthDetails,
  } = useAuth();

  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(!currentDevice.isMobile());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openUserMenu = Boolean(anchorEl);
  const { pathname, history } = useRouter();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    onDrawerOpenChange(openDrawer);
  }, [onDrawerOpenChange, openDrawer]);

  const handleRouting = route => {
    history.push(route);
    handleMenuClose();
  };

  const handleLogout = () => {
    logout();
    removeAuthDetails();
  };

  const handleHome = () => {
    window.location.href = siteUrl;
  };

  const currentPage = useMemo(() => {
    return (
      Object.values(allRoutes).find(({ path }) => pathname.includes(path))?.tKey || 'ADMIN_PANEL'
    );
  }, [pathname]);

  return (
    <>
      <AppBar
        position="sticky"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer,
        })}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, openDrawer && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">{t(currentPage)}</Typography>
            {isLoggedIn && (
              <div className={classes.userProfile}>
                <IconButton onClick={handleHome}>
                  <HomeIcon htmlColor="#fefefe" />
                </IconButton>
                <Button
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  color="inherit"
                  className={classes.accountBtn}
                >
                  <ConditionalRender condition={firstName && lastName}>
                    <Typography>
                      {firstName} {lastName}
                    </Typography>
                    <FaceIcon />
                  </ConditionalRender>
                  {openUserMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openUserMenu}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleRouting(userRoutes[UserRoute.Profile].path)}>
                    {t('ROUTES.MY_PROFILE')}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>{t('AUTH.LOGOUT')}</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {Object.values(userRoutes).map(({ Icon, tKey, path }) => {
            return <ListItemLink key={tKey} to={path} text={tKey} icon={Icon} />;
          })}
        </List>
        <Divider />
        {isAdmin && role === 'admin' && (
          <List>
            <ListSubheader>{t('MANAGEMENT')}</ListSubheader>
            {Object.values(adminRoutes).map(({ Icon, tKey, path }) => {
              return <ListItemLink key={tKey} to={path} text={tKey} icon={Icon} />;
            })}
          </List>
        )}
      </Drawer>
    </>
  );
};

export default Header;
