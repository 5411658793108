import React, { FC, useMemo } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { FieldConfig, FieldProps } from 'formik';
import { addHours, format } from 'date-fns';

type Props = TextFieldProps & FieldConfig & FieldProps;

const clientTzOffset = new Date().getTimezoneOffset() / 60;

export const UTCDatetoLocalDate = (utcDate: Date) => {
  return addHours(utcDate, -clientTzOffset);
};

const DateTimePickersField: FC<Props> = ({ field, ...rest }) => {
  const { t } = useTranslation();

  useMemo(() => {
    if (rest['fromutc'] == true) {
      let field_ = Object.assign({}, field),
        value = format(UTCDatetoLocalDate(new Date(field_.value)), "yyyy-MM-dd'T'HH:mm:ss");
      field.value = value;
      rest.value = value;
    }
  }, []);

  const error = useMemo(() => {
    const err = rest.form.errors[field.name];
    return err ? String(err) : '';
  }, [field, rest.form.errors]);

  return (
    <TextField
      error={!!error}
      helperText={t(error)}
      {...rest}
      {...field}
      type="datetime-local"
      InputLabelProps={{
        ...rest.InputLabelProps,
        shrink: true,
      }}
    />
  );
};

export default DateTimePickersField;
