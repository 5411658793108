import download from 'downloadjs';

export const getFileNameFromContentDisposition = disposition => {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }

  return '';
};

const downloadFileUtil = (response, { fileName: fileNameFromMeta, extension }) => {
  const {
    data,
    headers: { filename, 'content-disposition': contentDisposition },
  } = response;

  const predefinedName = extension ? `${fileNameFromMeta}.${extension}` : fileNameFromMeta;
  const nameFromDisposition = getFileNameFromContentDisposition(contentDisposition);
  const name = nameFromDisposition || filename || predefinedName;

  download(data, name);
};

export default downloadFileUtil;
