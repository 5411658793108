import React, { FC, useMemo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Container, makeStyles, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#20aeea',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

type Props = {
  isOpenDrawer: boolean;
};

const Footer: FC<Props> = ({ isOpenDrawer }) => {
  const classes = useStyles();

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <AppBar
      position="sticky"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpenDrawer,
      })}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography variant="caption">@ {currentYear}</Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
