import { RouteConfig } from '../../../../types';
import VideoLabelIcon from '@material-ui/core/SvgIcon/SvgIcon';
import withAdmin from '../../../../hocs/withAdmin';
import EditEventPage from './EditPage';
import SchoolIcon from '@material-ui/core/SvgIcon/SvgIcon';
import EditTestEventPage from './EditTest';
import EditOpinionEventPage from './EditOpinion/index.';

export enum EventsRoute {
  eventId = ':eventId',
  eventTestId = ':eventTest',
  eventOpinionId = ':eventOpinion',
}

export const eventsRoutes: Record<EventsRoute, RouteConfig> = {
  [EventsRoute.eventId]: {
    path: '/edit-event/:eventId',
    tKey: 'ROUTES.EVENT.EDIT',
    Icon: SchoolIcon,
    component: withAdmin(EditEventPage),
    exact: true,
  },
  [EventsRoute.eventTestId]: {
    path: '/edit-event/:eventId/test/',
    tKey: 'ROUTES.EVENT.EDIT',
    Icon: SchoolIcon,
    component: withAdmin(EditTestEventPage),
    exact: true,
  },
  [EventsRoute.eventOpinionId]: {
    path: '/edit-event/:eventId/opinion/',
    tKey: 'ROUTES.EVENT.EDIT',
    Icon: SchoolIcon,
    component: withAdmin(EditOpinionEventPage),
    exact: true,
  },
};
