export const downloadFile = (filePath, fileName) => {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = fileName;
  link.dispatchEvent(new MouseEvent('click'));
  link.remove();
};

export const bytesToMb = (num, bytesBase = 1000) => num / bytesBase ** 2;

export const readableMimeTypes = (mimeTypes: string[]) => {
  return mimeTypes.map(mimeType => {
    switch (mimeType) {
      case 'image/jpeg':
        return 'jpg/jpeg';
      case 'image/png':
        return 'png';
      case 'image/svg+xml':
        return 'svg';
      case 'application/pdf':
        return 'pdf';
      default:
        return mimeType;
    }
  });
};
