import { RouteConfig } from '../../../../types';
import CourseStatisticsDetail from './CourseStatisticsDetail';
import withAdmin from '../../../../hocs/withAdmin';
import VideoLabelIcon from '@material-ui/core/SvgIcon/SvgIcon';
import WebinarStatisticsDetailPage from './WebinarStatisticsDetail';

export enum StatisticsRoute {
  CourseStatisticsId = 'courseStatisticsDetail',
  WebinarStatisticsId = 'webinarStatisticsDetail',
}

export const statisticsRoutes: Record<StatisticsRoute, RouteConfig> = {
  [StatisticsRoute.CourseStatisticsId]: {
    path: '/statistics/courses/:coursesId',
    tKey: 'STATISTICS.COURSE_STATISTICS',
    Icon: VideoLabelIcon,
    component: withAdmin(CourseStatisticsDetail),
    exact: true,
  },
  [StatisticsRoute.WebinarStatisticsId]: {
    path: '/statistics/webinars/:webinarsId',
    tKey: 'STATISTICS.WEBINAR_STATISTICS',
    Icon: VideoLabelIcon,
    component: withAdmin(WebinarStatisticsDetailPage),
    exact: true,
  },
};
