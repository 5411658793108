import React, { FC, useMemo } from 'react';
import { FieldConfig, FieldProps } from 'formik';
import { Option } from '../../../../types';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  makeStyles,
  RadioProps,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextFieldProps,
  FormLabel,
} from '@material-ui/core';
import Select from '@material-ui/core/Select/Select';

type Props = TextFieldProps &
  FieldConfig &
  FieldProps & { options: Option[]; mapoptions: (options: Option[], t: TFunction) => Option[] };

const RadioField: FC<Props> = ({ field, options, mapoptions, ...rest }) => {
  const { t } = useTranslation();
  const error = useMemo(() => {
    const err = rest.form.errors[field.name];

    return err ? String(err) : '';
  }, [field, rest.form.errors]);

  const opts = useMemo(() => (mapoptions ? mapoptions(options, t) : options), [
    options,
    t,
    mapoptions,
  ]);
  const [value, setValue] = React.useState(field?.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  return (
    <FormControl component="fieldset" error={!!error}>
      <FormLabel component="legend">{rest?.label}</FormLabel>
      <RadioGroup
        row
        aria-label="position"
        name={field?.name}
        value={value}
        onChange={handleChange}
      >
        {opts?.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio color="primary" />}
            label={t(label)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioField;
