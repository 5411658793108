import React, { FC } from 'react';
import className from '../../pages/Admin/StatisticsManagementPage/StatisticsManagementPage.module.scss';
import { Form, Formik } from 'formik';
import styles from '../FormWithConfig/FormWithConfig.module.scss';
import Grid from '@material-ui/core/Grid';
import FieldWithConfig from '../FormWithConfig/FieldWithConfig';
import yup from '../../utils/validation';
import Button from '@material-ui/core/Button';
import classes from '../../components/DataTable/DataTable.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  search: string;
  handleSearch: any;
};

const SearchBar: FC<Props> = ({ search, handleSearch }) => {
  const { t } = useTranslation();
  console.log(search);
  return (
    <>
      <div className={className.navigationActions}>
        <Formik initialValues={{ search: search || '' }} onSubmit={handleSearch}>
          <Form className={styles.form} autoComplete="off" noValidate>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={8} lg={10} xl={10}>
                <FieldWithConfig
                  name="search"
                  label="COMMON.SEARCH"
                  fieldType="text"
                  required={false}
                  validate={yup.string().required()}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2} xl={2}>
                <Button
                  className={classes.searchBtn}
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                >
                  {t('COMMON.SEARCH')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default SearchBar;
