import React, { Suspense, useCallback, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';

import i18n from 'src/utils/i18n';
import { SnackbarProvider } from 'notistack';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import styles from './App.module.scss';
import { AuthProvider } from './context/AuthContext';
import Backdrop from './components/Backdrop';

function App() {
  const [isOpenDrawer, setIsOpenDrawer] = useState();
  const onDrawerOpenChange = useCallback((isOpen: boolean) => {
    setIsOpenDrawer(isOpen);
  }, []);

  return (
    <div className={styles.root}>
      <BrowserRouter>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <AuthProvider>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Suspense fallback={<Backdrop />}>
                <Header onDrawerOpenChange={onDrawerOpenChange} />
                <Main isOpenDrawer={isOpenDrawer} />
                <Footer isOpenDrawer={isOpenDrawer} />
              </Suspense>
            </SnackbarProvider>
          </AuthProvider>
        </I18nextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
