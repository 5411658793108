import React, { FC } from 'react';
import { CellParams, ColDef, GridApi } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import {
  deleteUsersId,
  getSpecializationsAll,
  deleteSpecializationsId,
  postSpecializations,
} from 'src/api';
import DataTable from 'src/components/DataTable';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import DiaogDelete from '../../../components/Dialog/Delete';
import classes from '../WebinarsManagementPage/WebinarsManagementPage.module.scss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CreateCategoryes from './CreateCategoryes';
import CreateWebinarsModal from '../../../components/Webinars/CreateModal';
import { WebinarsForm } from '../../../api/types';
import SearchBar from '../../../components/DataTable/SearchBar';

type Props = unknown;

const CategoriesManagementPage: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [getReload, setGetReload] = React.useState();
  const [deleteItem, setDeleteItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState();

  const columns: ColDef[] = [
    { field: 'id', headerName: 'DATA_TABLE.ID', type: 'number', width: 80 },
    { field: 'name', headerName: 'DATA_TABLE.NAME', width: 300 },
    { field: '1', headerName: 'DATA_TABLE.EDUCATIONS_PROGRAMS', width: 150 },
    { field: '2', headerName: 'DATA_TABLE.CERTIFICATES', width: 150 },
    { field: '3', headerName: 'DATA_TABLE.USERS', width: 160 },
    { field: '4', headerName: 'DATA_TABLE.PASSED_PROGRAMS', width: 150 },
    { field: '5', headerName: 'DATA_TABLE.SUCCESSFULLY_PASSED', width: 170 },
    {
      field: '',
      headerName: 'DATA_TABLE.ACTIONS',
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;

        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };
        return (
          <>
            <Link aria-label="edit" color="primary" to={{ pathname: `/edit-speciality/${id}` }}>
              <IconButton aria-label="edit" color="primary">
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };

  const deleteClose = () => {
    setDeleteDialog(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    if (typeof res?.banner === 'string') {
      delete res.banner;
    }

    return res;
  };
  const deleteActive = async (values, actions) => {
    try {
      await deleteSpecializationsId({ urlParams: { categoriesId: deleteItem } });
      setGetReload(true);
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };

  const handleSearch = async (values, actions) => {
    setSearch(values.search);
  };

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <SearchBar search={search} handleSearch={handleSearch} />
        <DataTable
          getRowsApi={getSpecializationsAll}
          columns={columns}
          reload={getReload}
          search={search}
        ></DataTable>

        <DiaogDelete
          deleteDialog={deleteDialog}
          deleteClose={deleteClose}
          removeActive={deleteActive}
        />
      </Container>
      <div className={classes.wrapper_button}>
        <Button
          variant="contained"
          disabled={open}
          color="primary"
          aria-label="add"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Button>
      </div>
      <CreateCategoryes
        openModal={setOpen}
        openIs={open}
        saveContent={postSpecializations}
        reload={setGetReload}
        filterSavePayload={filterSavePayload}
      ></CreateCategoryes>
    </>
  );
};

export default CategoriesManagementPage;
