import React, { FC, useMemo } from 'react';
import { FormikConfig, Formik, Form } from 'formik';
import styles from './FormResetPassword.module.scss';
import { getInitialValues, getValidationSchema } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import Backdrop from '../Backdrop';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FieldWithConfig from '../FormWithConfig/FieldWithConfig';
import yup from '../../utils/validation';

export type Props = {
  onSubmit: FormikConfig<any>['onSubmit'];
};

const FormResetPassword: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const input = [
    {
      name: 'current_password',
      label: 'COMMON.CURRENT_PASSWORD',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'new_password',
      label: 'COMMON.NEW_PASSWORD',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'confirm_password',
      label: 'COMMON.CONFIRM_PASSWORD',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
  ];
  const initialValues = useMemo(() => getInitialValues(input), [input]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);

  return (
    <div className={styles.wrapper}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting }) => {
          return (
            <>
              <Typography variant="h6" id="tableTitle">
                {t('COMMON.CHANGE_PASSWORD')}
              </Typography>
              {isSubmitting && <Backdrop invisible />}
              <Form className={styles.form} autoComplete="off" noValidate>
                {input.map(field => {
                  return <FieldWithConfig key={field.name} {...field} />;
                })}
                <div className={styles.actions}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormResetPassword;
