import React, { FC, useCallback, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getEventById, putEventById, deleteEventPartners } from 'src/api';
import Typography from '@material-ui/core/Typography';
import { DataGrid, ColDef, CellParams } from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { WebinarsForm } from '../../../../../api/types';
import yup from '../../../../../utils/validation';
import FormControlCourses from '../../../CoursesManagementPage/FormControl';
import className from '../../../WebinarsManagementPage/pages/EditPage/WebinarsEdidPage.module.scss';
import Backdrop from '../../../../../components/Backdrop';
import ConditionalRender from '../../../../../components/ConditionalRender';
import DiaogDelete from '../../../../../components/Dialog/Delete';
import styles from './EventEdidPage.module.scss';
import CreatePartnersEvent from '../../CreatePartnersEvent';
import { CoursesSet, EventPartners } from '../../../../../types';
import EditIcon from '@material-ui/icons/Edit';

type Props = unknown;

const EditEventPage: FC<Props> = () => {
  const { eventId } = useParams();
  const [content, setContent] = useState();
  const [partners, setPartners] = useState<EventPartners[]>([] as any);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [getReload, setGetReload] = React.useState();
  const [partnerConfig, setPartnerConfig] = React.useState([] as any);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const fetchData = useCallback(async () => {
    try {
      const { data } = await getEventById({ urlParams: { eventId } });
      setContent(data);

      setPartners(data.partners_set);
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };

  const deleteClose = () => {
    setDeleteDialog(false);
  };

  const input = [
    {
      name: 'is_draft',
      label: 'Чернетка',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'language',
      label: 'Мова',
      fieldType: 'radio',
      required: false,
      validate: null,
      options: [
        {
          label: 'Українська',
          value: 'ua',
        },
        {
          label: 'Русский',
          value: 'ru',
        },
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'is_private',
      label: 'Закрита подія',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'name',
      label: 'Назва події',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'description',
      label: 'Опис події',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'start_date',
      label: 'Дати проведення події (Початок)',
      fieldType: 'dateTimePicker',
      required: false,
      validate: null,
      fromutc: true,
    },
    {
      name: 'end_date',
      label: 'Дати проведення події (Кінець)',
      fieldType: 'dateTimePicker',
      required: false,
      validate: null,
      fromutc: true,
    },
    {
      name: 'testing_end_date',
      label: 'Дата закриття доступу до тестування',
      fieldType: 'dateTimePicker',
      required: false,
      validate: null,
      fromutc: true,
    },
    {
      name: 'event_plan',
      label: 'Файл для події',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
    },
    {
      name: 'image',
      label: 'Зображення для сторінки події',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
    {
      name: 'youtube_id',
      label: 'Youtube ID',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'points',
      label: 'Балів за подію',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      step: 1,
    },
    {
      name: 'success_percent',
      label: 'Процент правильних відповідей для проходження тесту',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
    {
      name: 'max_tries',
      label: 'Кількість спроб пройти тестування (0 - безліч)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'text',
      label: 'Основний текст для сторінки події',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'certificate_title',
      label: 'Назва сертифікату',
      fieldType: 'text',
      required: false,
      validate: null,
    },

    {
      name: 'partners_banner',
      label: 'Зображеня для партнерів',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
  ];

  const columns: ColDef[] = [
    { field: 'id', headerName: t('DATA_TABLE.ID') },
    { field: 'name', headerName: t('DATA_TABLE.NAME'), width: 950 },
    { field: 'order_number', headerName: t('DATA_TABLE.ORDER'), width: 100 },
    {
      field: '',
      headerName: t('DATA_TABLE.ACTIONS'),
      width: 115,
      align: 'center',
      headerClassName: styles.center_row,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;

        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };

        const onEdit = () => {
          console.log(params?.data);
          setOpen(true);
          setPartnerConfig(params?.data);
        };
        return (
          <>
            <IconButton aria-label="edit" color="primary" onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    ['image', 'event_plan', 'partners_banner'].forEach(field => {
      if (typeof res?.[field] === 'string') {
        delete res[field];
      }
    });

    ['start_date', 'end_date', 'testing_end_date'].forEach(field => {
      res[field] = new Date(res?.[field]).toISOString();
    });

    return res;
  };

  const handleSubmit = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      await putEventById({
        payload,
        urlParams: { eventId },
      });

      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const deletePartnersActive = async (values, actions) => {
    try {
      await deleteEventPartners({ urlParams: { eventId, partnerId: deleteItem } });
      setPartners(partners.filter(a => a.id !== deleteItem));
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    setPartnerConfig(null);
  };

  return (
    <>
      <ConditionalRender condition={loading}>
        <Backdrop invisible />
        <Container disableGutters maxWidth="lg">
          <Link className={className.link} to={{ pathname: `/events-management` }}>
            <Button variant="contained" size="large" color="default">
              {t('COMMON.BACK')}
            </Button>
          </Link>
          <Typography variant="h6" id="tableTitle" className={className.title}>
            {t('EVENT.EDIT')}
          </Typography>
          <FormControlCourses
            onSubmit={handleSubmit}
            defaultValues={content}
            config={content}
            input={input}
          />

          <div className={styles.buttonFooter}>
            <Link
              className={[styles.link, styles.mr_10].join(' ')}
              to={{ pathname: `/edit-event/${content?.id}/test/` }}
            >
              <Button variant="contained" size="large" color="default">
                {t('EVENT.EDIT_TEST')}
              </Button>
            </Link>
            <Link
              className={[styles.link, styles.mr_10].join(' ')}
              to={{ pathname: `/edit-event/${content?.id}/opinion/` }}
            >
              <Button variant="contained" size="large" color="default">
                {t('EVENT.EDIT_POLL')}
              </Button>
            </Link>
          </div>

          <div className={[styles.buttonFooter, styles.tableBottom].join(' ')}>
            <Typography variant="h6" id="tableTitle" className={className.title}>
              {t('EVENT.LIST_PARTNERS')}
            </Typography>
            <DataGrid autoHeight columns={columns} rows={partners} loading={loading} />
          </div>
          <Button
            variant="contained"
            size="large"
            color="default"
            className={styles.link}
            disabled={open}
            onClick={handleClickOpen}
          >
            {t('EVENT.CREATE_PARTNERS')}
          </Button>
          <DiaogDelete
            deleteDialog={deleteDialog}
            deleteClose={deleteClose}
            removeActive={deletePartnersActive}
          />
        </Container>
      </ConditionalRender>
      <CreatePartnersEvent
        openModal={setOpen}
        openIs={open}
        saveContent={setPartners}
        eventId={content?.id}
        reload={setGetReload}
        config={partnerConfig}
        filterSavePayload={filterSavePayload}
      />
    </>
  );
};
export default EditEventPage;
