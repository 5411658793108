import React, { FC } from 'react';
import { Form, Formik, FormikConfig } from 'formik';
import styles from '../FormWithConfig/FormWithConfig.module.scss';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TextField from '@material-ui/core/TextField/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { useTranslation } from 'react-i18next';

export type Props = {
  handleSubmit?: FormikConfig<any>['onSubmit'];
  contentSet?: any[];
  removeItem?: any;
  removeItemList?: any;
  radioChange?: any;
  radioAnswersChange?: any;
  checkedAnswersChange?: any;
  add_Question?: any;
  add_QuestionItem?: any;
  itemChange?: any;
  itemIsSave?: any;
  itemAnswersIsSave?: any;
  saveItem?: any;
  saveItemAnswers?: any;
  itemChangeAnswers?: any;
  setContentCreate?: any;
  launchPollQuestion?: any;
};

const FormLessonsItem: FC<Props> = ({
  handleSubmit,
  contentSet,
  removeItem,
  radioChange,
  radioAnswersChange,
  removeItemList,
  checkedAnswersChange,
  add_QuestionItem,
  add_Question,
  itemChange,
  itemChangeAnswers,
  itemIsSave,
  saveItem,
  saveItemAnswers,
  itemAnswersIsSave,
  setContentCreate,
  launchPollQuestion,
}) => {
  const { t } = useTranslation();
  return (
    <Formik onSubmit={handleSubmit} initialValues={contentSet}>
      {({ isSubmitting }) => {
        return (
          <>
            <Form className={styles.form} autoComplete="off" noValidate>
              {contentSet &&
                contentSet.map((field, key) => {
                  return (
                    <div key={key}>
                      <div className={styles.formGroup}>
                        <FormLabel component="legend" className={styles.title_set}>
                          {t('LESSON_ITEM.QUESTION')} "{key + 1}"
                        </FormLabel>
                        <div className={styles.flex}>
                          {launchPollQuestion !== undefined && (
                            <Button
                              variant="contained"
                              className={styles.buttonRemoveItem}
                              aria-label="launch"
                              color="primary"
                              onClick={() => launchPollQuestion(field?.id)}
                              title={t('LESSON_ITEM.LAUNCH_ONLINE_QUESTION')}
                            >
                              <PlayArrowIcon />
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            className={styles.buttonRemoveItem}
                            aria-label="delete"
                            color="secondary"
                            onClick={() => removeItem(field?.id)}
                          >
                            <DeleteIcon />
                          </Button>

                          <TextField
                            id={String(field?.id)}
                            defaultValue={field?.title ? field?.title : ' '}
                            className={styles.formField}
                            variant="outlined"
                            label={t('LESSON_ITEM.NAME_QUESTION')}
                            onChange={e => itemChange(e, field?.id, true)}
                          />
                        </div>

                        <FormControl component="fieldset" className={styles.marginTop}>
                          {field?.multiple_answers !== undefined && (
                            <>
                              <FormLabel component="legend" className={styles.title_set}>
                                {t('LESSON_ITEM.QUESTION_TYPE')} "{key + 1}"
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-label="position"
                                name={field?.id + '_type'}
                                value={field?.multiple_answers}
                                onChange={event => {
                                  radioChange(event, field?.id);
                                  itemChange(event, field?.id, false);
                                }}
                              >
                                <FormControlLabel
                                  value={false}
                                  control={<Radio color="primary" />}
                                  label={t('LESSON_ITEM.SINGLE_CHOICE')}
                                />
                                <FormControlLabel
                                  value={true}
                                  control={<Radio color="primary" />}
                                  label={t('LESSON_ITEM.MULTIPLE_CHOICE')}
                                />
                              </RadioGroup>
                            </>
                          )}

                          {itemIsSave && itemIsSave.filter(a => a == field?.id).length > 0 && (
                            <div>
                              <Button
                                variant="contained"
                                className={styles.buttonRemoveItem}
                                aria-label="save"
                                color="primary"
                                onClick={() => saveItem(field?.id)}
                              >
                                <SaveIcon />
                              </Button>
                            </div>
                          )}
                        </FormControl>

                        <FormLabel component="legend" className={styles.title_set}>
                          {t('LESSON_ITEM.ANSWERS_TO_QUESTION')} "{key + 1}"
                        </FormLabel>
                        <div className={styles.list_button}>
                          {field?.answers_set.map((list, index, arr) => {
                            return (
                              <div key={index} className={styles.padLeft}>
                                {field?.multiple_answers == false && (
                                  <div className={[styles.flex, styles.marginTop].join(' ')}>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name={list?.id + '_answers'}
                                      value={list?.is_correct}
                                      onChange={event => {
                                        radioAnswersChange(event, field?.id, list?.id);
                                      }}
                                    >
                                      {list?.is_correct !== undefined && (
                                        <FormControlLabel
                                          value={true}
                                          control={<Radio color="primary" />}
                                          label={''}
                                        />
                                      )}
                                    </RadioGroup>
                                    <TextField
                                      id={String(list?.id)}
                                      defaultValue={list?.title}
                                      className={styles.formField}
                                      variant="outlined"
                                      label={t('LESSON_ITEM.REPLY')}
                                      onChange={e =>
                                        itemChangeAnswers(e, field?.id, list?.id, true)
                                      }
                                    ></TextField>
                                    <Button
                                      variant="contained"
                                      className={styles.buttonRemoveList}
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() => removeItemList(field?.id, list?.id)}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </div>
                                )}
                                {field?.multiple_answers == true && (
                                  <div className={[styles.flex, styles.marginTop].join(' ')}>
                                    <FormControlLabel
                                      name={list?.id + '_answers'}
                                      value={true}
                                      control={
                                        <Checkbox
                                          checked={list?.is_correct}
                                          color="primary"
                                          onChange={event =>
                                            checkedAnswersChange(event, field?.id, list?.id)
                                          }
                                        />
                                      }
                                      label={''}
                                    />
                                    <TextField
                                      id={String(list?.id)}
                                      defaultValue={list?.title}
                                      className={styles.formField}
                                      variant="outlined"
                                      label={t('LESSON_ITEM.REPLY')}
                                      onChange={e =>
                                        itemChangeAnswers(e, field?.id, list?.id, true)
                                      }
                                    ></TextField>
                                    <Button
                                      variant="contained"
                                      className={styles.buttonRemoveList}
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() => removeItemList(field?.id, list?.id)}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          <div className={styles.inline_button}>
                            <Button
                              variant="contained"
                              size="medium"
                              color="primary"
                              disabled={isSubmitting}
                              onClick={() => add_QuestionItem(field?.id)}
                            >
                              {t('LESSON_ITEM.ADD_ANSWER')}
                            </Button>
                            {itemAnswersIsSave &&
                              itemAnswersIsSave.filter(
                                a => field?.answers_set.filter(b => b.id == a)[0],
                              ).length > 0 && (
                                <div>
                                  <Button
                                    variant="contained"
                                    className={styles.buttonRemoveList}
                                    aria-label="save"
                                    color="primary"
                                    onClick={() => saveItemAnswers(field?.id)}
                                  >
                                    <SaveIcon />
                                  </Button>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {setContentCreate && (
                <div className={styles.actions}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={add_Question}
                  >
                    {t('LESSON_ITEM.ADD_QUESTION')}
                  </Button>
                </div>
              )}
              {setContentCreate === undefined && (
                <div className={styles.actions}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={add_Question}
                  >
                    {t('LESSON_ITEM.ADD_QUESTION')}
                  </Button>
                </div>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default FormLessonsItem;
