import React, { ReactChildren } from 'react';

type Props = {
  condition: boolean;
  children: ReactChildren;
};

const ConditionalRender: any = ({ children, condition }: Props) => {
  const childrenArray = React.Children.toArray(children);
  if (!childrenArray.length) return null;
  return condition ? childrenArray[0] : childrenArray[1] || null;
};

export default ConditionalRender;
