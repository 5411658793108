import React, { FC } from 'react';
import { getHippocratesContentMain, patchHippocratesContentMain } from 'src/api';
import FormContainer from 'src/components/FormContainer';
import { HippocratesContentMain } from 'src/api/types';
import fieldsConfig from './fieldsConfig';

type Props = unknown;

const filterSavePayload = (values: HippocratesContentMain) => {
  const res = { ...values };

  if (typeof res?.logo === 'string') {
    delete res.logo;
  }

  return res;
};

const GeneralPage: FC<Props> = () => (
  <FormContainer
    config={fieldsConfig}
    getContent={getHippocratesContentMain}
    saveContent={patchHippocratesContentMain}
    filterSavePayload={filterSavePayload}
  />
);

export default GeneralPage;
