import { apiUrls } from 'src/api/apiUrls';
import {
  User,
  Specialization,
  WithPagination,
  EventTest,
  ResetPassword,
  Webinars,
  FormEditWebinars,
  FormEditWebinarsOpinion,
  Users,
  Specializations,
  WebinarsPayload,
  CourseStatisticsSummary,
  CourseStatisticsUserDetail,
  WebinarStatisticsSummary,
  WebinarStatisticsUserDetail,
  EventId,
  EventPayload,
  UsersPayload,
  CoursesId,
  specializationsId,
  SpecializationsIdPayload,
  CoursesIdPayload,
  EditWebinarsTest,
  Questions_set,
  EventTestQuestionAnswer,
  TestQuestionAnswerPayload,
  EventTestQuestionAnswerId,
  FileLwssons,
  fileForm,
  EventPartners,
  RealtimePollResultChartData,
} from 'src/types';

import { createApiBlobRequest, createApiRequest } from 'src/api/utils';
import { type } from 'os';
import {
  LoginPayload,
  LoginResult,
  RefreshPayload,
  RefreshResult,
  SignUpPayload,
  SignUpResult,
  PaginationQueryParams,
  TestResultsPayload,
  HippocratesContentMain,
  HippocratesContentHome,
  HippocratesContentProjects,
  HippocratesContentContacts,
  WebinarsId,
} from './types';

export const postSignup = createApiRequest<SignUpResult, SignUpPayload>('post', apiUrls.signup);

export const postLogin = createApiRequest<LoginResult, LoginPayload, never, never>(
  'post',
  apiUrls.login,
);

export const confirmRegistration = createApiRequest<
  never,
  never,
  never,
  { token: string; id: string }
>('get', apiUrls.confirmRegistration);

export const postRefreshToken = createApiRequest<RefreshResult, RefreshPayload>(
  'post',
  apiUrls.refresh,
);

export const getMe = createApiRequest<User>('get', apiUrls.me, true);
// export const getCertificates = createApiRequest<User>('get', apiUrls.certificates, true);

export const patchMe = createApiRequest<User>('patch', apiUrls.me, true);

export const resetPassword = createApiRequest<ResetPassword>('post', apiUrls.resetPassword, true);

export const getSpecializations = createApiRequest<WithPagination<Specialization>>(
  'get',
  apiUrls.specializations,
);

export const getSpecializationsId = createApiRequest<
  unknown,
  never,
  never,
  { categoriesId: string }
>('get', apiUrls.specializationsId, true);

export const postSpecializations = createApiRequest<specializationsId>(
  'post',
  apiUrls.specializations,
  true,
  true,
);

export const deleteSpecializationsId = createApiRequest<
  unknown,
  never,
  never,
  { categoriesId: string }
>('delete', apiUrls.specializationsId, true);

export const putSpecializationsId = createApiRequest<
  specializationsId,
  SpecializationsIdPayload,
  never,
  { categoriesId: string }
>('put', apiUrls.specializationsId, true, true);

export const getSpecializationsAll = createApiRequest<
  WithPagination<Specializations>,
  never,
  PaginationQueryParams &
    Partial<Specializations> & { start_date__gte?: string; start_date__lte?: string },
  never
>('get', apiUrls.specializations, true);

export const getCertificate = createApiRequest<WithPagination<Specialization>>(
  'get',
  apiUrls.certificates,
  true,
);

export const getCertificatesFiles = createApiBlobRequest<
  unknown,
  never,
  never,
  { url: string; certificatesId: string }
>('get', apiUrls.downloadCertificates, true);

export const getStatisticsCourses = createApiRequest('get', apiUrls.statisticsCourses, true);

export const getStatisticsCoursesId = createApiRequest<unknown, never, never, { Id: string }>(
  'get',
  apiUrls.statisticsCoursesID,
  true,
);

export const getEvents = createApiRequest<
  WithPagination<EventId>,
  never,
  PaginationQueryParams & Partial<EventId> & { start_date__gte?: string; start_date__lte?: string },
  never
>('get', apiUrls.events);

export const postEvents = createApiRequest<EventId>('post', apiUrls.events, true, true);

export const getEventById = createApiRequest<EventId, never, never, { eventId: string }>(
  'get',
  apiUrls.eventById,
  true,
);
export const deleteEventById = createApiRequest<EventId, never, never, { eventId: string }>(
  'delete',
  apiUrls.eventById,
  true,
);
export const putEventById = createApiRequest<EventId, EventPayload, never, { eventId: string }>(
  'put',
  apiUrls.eventById,
  true,
  true,
);
export const getEventPartners = createApiRequest<
  WithPagination<EventPartners>,
  never,
  PaginationQueryParams &
    Partial<EventPartners> & { start_date__gte?: string; start_date__lte?: string },
  { eventId: string }
>('get', apiUrls.eventPartners, true);

export const postEventPartners = createApiRequest<
  EventPartners,
  EventPayload,
  never,
  { eventId: string }
>('post', apiUrls.eventPartners, true, true);
export const putEventPartners = createApiRequest<
  EventPartners,
  EventPayload,
  never,
  { eventId: string; partnerId: string }
>('put', apiUrls.eventPartnersId, true, true);

export const deleteEventPartners = createApiRequest<
  EventPartners,
  never,
  never,
  { eventId: string; partnerId: string }
>('delete', apiUrls.eventPartnersId, true);

export const getEventTest = createApiRequest<EventTest, never, never, { eventId: string }>(
  'get',
  apiUrls.eventTest,
  true,
);

export const postEventTestResults = createApiRequest<
  never,
  TestResultsPayload,
  never,
  { eventId: string }
>('post', apiUrls.eventTestResults, true);

export const getEventTestResultsCertificate = createApiRequest<
  unknown,
  never,
  never,
  { eventId: string }
>('get', apiUrls.eventTestResultsCertificate, true);

export const postEventRegistration = createApiRequest<unknown, never, never, { eventId: string }>(
  'post',
  apiUrls.registerToEvent,
);
export const deleteEventTestQuestionsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { eventId: string; questionId: string }
>('delete', apiUrls.eventTestQuestionById, true);

export const deleteEventTestQuestionsAnswersId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { eventId: string; questionId: string; answerId: string }
>('delete', apiUrls.eventTestQuestionAnswerById, true);
export const postEventTest = createApiRequest<EditWebinarsTest, never, never, { eventId: string }>(
  'post',
  apiUrls.eventTest,
  true,
);
export const postEventTestQuestions = createApiRequest<never, never, never, { eventId: string }>(
  'post',
  apiUrls.eventTestQuestions,
  true,
);

export const postEventTestQuestionsAnswers = createApiRequest<
  never,
  never,
  never,
  { eventId: string; questionId: string }
>('post', apiUrls.eventTestQuestionAnswers, true);

export const putEventTestQuestionsId = createApiRequest<
  Questions_set,
  WebinarsPayload,
  never,
  { eventId: string; questionId: string }
>('put', apiUrls.eventTestQuestionById, true);

export const putEventTestQuestionsAnswersId = createApiRequest<
  EventTestQuestionAnswerId,
  TestQuestionAnswerPayload,
  never,
  { eventId: string; questionId: string; answerId: string }
>('put', apiUrls.eventTestQuestionAnswerById, true);

export const getEventPoll = createApiRequest<
  FormEditWebinarsOpinion,
  never,
  never,
  { eventId: string }
>('get', apiUrls.eventOpinion, true);

export const postEventPoll = createApiRequest<
  FormEditWebinarsOpinion,
  never,
  never,
  { eventId: string }
>('post', apiUrls.eventOpinion, true);

export const postEventPollQuestions = createApiRequest<never, never, never, { eventId: string }>(
  'post',
  apiUrls.eventPollQuestions,
  true,
);

export const postEventPollQuestionsAnswers = createApiRequest<
  never,
  never,
  never,
  { eventId: string; questionsId: string }
>('post', apiUrls.eventPollQuestionsAnswers, true);

export const putEventPollQuestionsId = createApiRequest<
  Questions_set,
  WebinarsPayload,
  never,
  { eventId: string; questionsId: string }
>('put', apiUrls.eventPollQuestionsId, true);

export const putEventPollQuestionsAnswersId = createApiRequest<
  EventTestQuestionAnswerId,
  TestQuestionAnswerPayload,
  never,
  { eventId: string; questionsId: string; answersId: string }
>('put', apiUrls.eventPollQuestionsAnswersId, true);

export const deleteEventPollQuestionsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { eventId: string; questionsId: string }
>('delete', apiUrls.eventPollQuestionsId, true);

export const deleteEventPollQuestionsAnswersId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { eventId: string; questionsId: string; answersId: string }
>('delete', apiUrls.eventPollQuestionsAnswersId, true);

export const getCheckEventRegistration = createApiRequest<
  unknown,
  never,
  never,
  { eventId: string }
>('get', apiUrls.registerToEventCheck);

export const postSubscribe = createApiRequest<unknown, { email: string }, never, never>(
  'post',
  apiUrls.subscriptions,
);

export const patchHippocratesContentMain = createApiRequest<Partial<HippocratesContentMain>>(
  'patch',
  apiUrls.hippocratesContentMain,
  true,
  true,
);

export const getHippocratesContentMain = createApiRequest<HippocratesContentMain>(
  'get',
  apiUrls.hippocratesContentMain,
  true,
);

export const getHippocratesContentHome = createApiRequest<HippocratesContentHome>(
  'get',
  apiUrls.hippocratesContentHome,
  true,
);

export const patchHippocratesContentHome = createApiRequest<HippocratesContentHome>(
  'patch',
  apiUrls.hippocratesContentHome,
  true,
  true,
);

export const getHippocratesContentProjects = createApiRequest<HippocratesContentProjects>(
  'get',
  apiUrls.hippocratesContentProjects,
);
export const patchHippocratesContentProjects = createApiRequest<HippocratesContentProjects>(
  'patch',
  apiUrls.hippocratesContentProjects,
  true,
  true,
);

export const getHippocratesContentContacts = createApiRequest<HippocratesContentContacts>(
  'get',
  apiUrls.hippocratesContentContacts,
  true,
);
export const patchHippocratesContentContacts = createApiRequest<HippocratesContentContacts>(
  'patch',
  apiUrls.hippocratesContentContacts,
  true,
  true,
);

export const getWebinars = createApiRequest<
  WithPagination<Webinars>,
  never,
  PaginationQueryParams &
    Partial<Webinars> & { start_date__gte?: string; start_date__lte?: string },
  never
>('get', apiUrls.webinars);

export const postWebinars = createApiRequest<Webinars>('post', apiUrls.webinars, true, true);

export const putWebinarsId = createApiRequest<
  Webinars,
  WebinarsPayload,
  never,
  { webinarsId: string }
>('put', apiUrls.webinarsId, true, true);

export const getWebinarId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsId: string }
>('get', apiUrls.webinarsId, true);

export const deleteWebinarId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsId: string }
>('delete', apiUrls.webinarsId, true);

export const getWebinarTest = createApiRequest<
  EditWebinarsTest,
  never,
  never,
  { webinarsTest: string }
>('get', apiUrls.webinarsTest, true);
export const postWebinarTest = createApiRequest<
  EditWebinarsTest,
  never,
  never,
  { webinarsTest: string }
>('post', apiUrls.webinarsTest, true);

export const postWebinarTestQuestions = createApiRequest<
  never,
  never,
  never,
  { webinarsTest: string }
>('post', apiUrls.webinarsTestQuestions, true);

export const postWebinarPoll = createApiRequest<
  FormEditWebinarsOpinion,
  never,
  never,
  { webinarsOpinion: string }
>('post', apiUrls.webinarsOpinion, true);

export const postWebinarPollQuestions = createApiRequest<
  never,
  never,
  never,
  { webinarsOpinion: string }
>('post', apiUrls.webinarsPollQuestions, true);

export const postWebinarPollQuestionsAnswers = createApiRequest<
  never,
  never,
  never,
  { webinarsOpinion: string; questionsId: string }
>('post', apiUrls.webinarsPollQuestionsAnswers, true);

export const putWebinarPollQuestionsId = createApiRequest<
  Questions_set,
  WebinarsPayload,
  never,
  { webinarsOpinion: string; questionsId: string }
>('put', apiUrls.webinarsPollQuestionsId, true);

export const putWebinarPollQuestionsAnswersId = createApiRequest<
  EventTestQuestionAnswerId,
  TestQuestionAnswerPayload,
  never,
  { webinarsOpinion: string; questionsId: string; answersId: string }
>('put', apiUrls.webinarsPollQuestionsAnswersId, true);

export const deleteWebinarPollQuestionsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsOpinion: string; questionsId: string }
>('delete', apiUrls.webinarsPollQuestionsId, true);

export const deleteWebinarsPollQuestionsAnswersId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsOpinion: string; questionsId: string; answersId: string }
>('delete', apiUrls.webinarsPollQuestionsAnswersId, true);

export const postWebinarTestQuestionsAnswers = createApiRequest<
  never,
  never,
  never,
  { webinarsTest: string; questionsId: string }
>('post', apiUrls.webinarsTestQuestionsAnswers, true);

export const putWebinarTestQuestionsId = createApiRequest<
  Questions_set,
  WebinarsPayload,
  never,
  { webinarsTest: string; questionsId: string }
>('put', apiUrls.webinarsTestQuestionsId, true);

export const deleteWebinarTestQuestionsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsTest: string; questionsId: string }
>('delete', apiUrls.webinarsTestQuestionsId, true);

export const deleteWebinarsTestQuestionsAnswersId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsTest: string; questionsId: string; answersId: string }
>('delete', apiUrls.webinarsTestQuestionsAnswersId, true);

export const putWebinarTestQuestionsAnswersId = createApiRequest<
  EventTestQuestionAnswerId,
  TestQuestionAnswerPayload,
  never,
  { webinarsTest: string; questionsId: string; answersId: string }
>('put', apiUrls.webinarsTestQuestionsAnswersId, true);

export const getWebinarPoll = createApiRequest<
  FormEditWebinarsOpinion,
  never,
  never,
  { webinarsOpinion: string }
>('get', apiUrls.webinarsOpinion, true);

export const getCourses = createApiRequest<
  WithPagination<CoursesId>,
  never,
  PaginationQueryParams &
    Partial<CoursesId> & { start_date__gte?: string; start_date__lte?: string },
  never
>('get', apiUrls.courses, true);

export const getCoursesId = createApiRequest<CoursesId, never, never, { coursesId: string }>(
  'get',
  apiUrls.coursesId,
  true,
);
export const postCourses = createApiRequest<CoursesId>('post', apiUrls.courses, true, true);

export const deleteCoursesId = createApiRequest<CoursesId, never, never, { coursesId: string }>(
  'delete',
  apiUrls.coursesId,
  true,
);
export const putCoursesId = createApiRequest<
  CoursesId,
  CoursesIdPayload,
  never,
  { coursesId: string }
>('put', apiUrls.coursesId, true, true);

export const getCoursesTestId = createApiRequest<
  EditWebinarsTest,
  never,
  never,
  { coursesTestId: string }
>('get', apiUrls.coursesTestId, true);

export const getUsers = createApiRequest<
  WithPagination<Users>,
  never,
  PaginationQueryParams & Partial<Users> & { start_date__gte?: string; start_date__lte?: string },
  never
>('get', apiUrls.users, true);

export const getUsersExcel = createApiBlobRequest<unknown, never, never, never>(
  'get',
  apiUrls.usersDownloadExcel,
  true,
);

export const getCoursesStatisticsSummary = createApiRequest<
  WithPagination<CourseStatisticsSummary>,
  never,
  PaginationQueryParams &
    Partial<CourseStatisticsSummary> & { start_date__gte?: string; start_date__lte?: string },
  never
>('get', apiUrls.statisticsCoursesSummary, true);

export const getCoursesStatisticsSummaryExcel = createApiBlobRequest<unknown, never, never, never>(
  'get',
  apiUrls.statisticsCoursesSummaryExcel,
  true,
);

export const getCoursesStatisticsById = createApiRequest<
  WithPagination<CourseStatisticsUserDetail>,
  never,
  PaginationQueryParams & Partial<CourseStatisticsUserDetail>,
  { coursesId: string }
>('get', apiUrls.statisticsCoursesSummaryByID, true);

export const getCoursesStatisticsByIdExcel = createApiBlobRequest<
  unknown,
  never,
  never,
  { coursesId: string }
>('get', apiUrls.statisticsCoursesSummaryByIDExcel, true);

export const getWebinarsStatisticsSummary = createApiRequest<
  WithPagination<WebinarStatisticsSummary>,
  never,
  PaginationQueryParams &
    Partial<CourseStatisticsSummary> & { start_date__gte?: string; start_date__lte?: string },
  never
>('get', apiUrls.statisticsWebinarsSummary, true);

export const getWebinarsStatisticsSummaryExcel = createApiBlobRequest<unknown, never, never, never>(
  'get',
  apiUrls.statisticsWebinarsSummaryExcel,
  true,
);

export const getWebinarsStatisticsById = createApiRequest<
  WithPagination<WebinarStatisticsUserDetail>,
  never,
  PaginationQueryParams & Partial<CourseStatisticsUserDetail>,
  { webinarsId: string }
>('get', apiUrls.statisticsWebinarsSummaryByID, true);

export const getWebinarsStatisticsByIdExcel = createApiBlobRequest<
  unknown,
  never,
  never,
  { webinarsId: string }
>('get', apiUrls.statisticsWebinarsSummaryByIDExcel, true);

export const getUsersId = createApiRequest<FormEditWebinars, never, never, { usersId: string }>(
  'get',
  apiUrls.usersId,
  true,
);
export const deleteUsersId = createApiRequest<FormEditWebinars, never, never, { usersId: string }>(
  'delete',
  apiUrls.usersId,
  true,
);
export const putUsersId = createApiRequest<Users, UsersPayload, never, { usersId: string }>(
  'patch',
  apiUrls.usersId,
  true,
);

export const postCoursesTestQuestions = createApiRequest<
  never,
  never,
  never,
  { coursesTestId: string }
>('post', apiUrls.coursesTestQuestions, true);

export const putCoursesTestQuestionsId = createApiRequest<
  Questions_set,
  WebinarsPayload,
  never,
  { coursesTestId: string; questionsId: string }
>('put', apiUrls.coursesTestQuestionsId, true);

export const putCoursesTestQuestionsAnswersId = createApiRequest<
  EventTestQuestionAnswerId,
  TestQuestionAnswerPayload,
  never,
  { coursesTestId: string; questionsId: string; answersId: string }
>('put', apiUrls.coursesTestQuestionsAnswersId, true);

export const deleteCoursesTestQuestionsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { coursesTestId: string; questionsId: string }
>('delete', apiUrls.coursesTestQuestionsId, true);

export const deleteCoursesTestQuestionsAnswersId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { coursesTestId: string; questionsId: string; answersId: string }
>('delete', apiUrls.coursesTestQuestionsAnswersId, true);

export const postCoursesTestQuestionsAnswers = createApiRequest<
  never,
  never,
  never,
  { coursesTestId: string; questionsId: string }
>('post', apiUrls.coursesTestQuestionsAnswers, true);

export const getCoursesLessonsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { coursesId: string; lessonsId: string }
>('get', apiUrls.coursesLessonsId, true);

export const putCoursesLessonsId = createApiRequest<
  CoursesId,
  CoursesIdPayload,
  never,
  { coursesId: string; lessonsId: string }
>('put', apiUrls.coursesLessonsId, true, true);

export const postCoursesLessons = createApiRequest<
  CoursesId,
  CoursesIdPayload,
  never,
  { coursesId: string }
>('post', apiUrls.coursesLessons, true);

export const getCoursesLessonsIdFiles = createApiRequest<
  fileForm,
  never,
  never,
  { coursesId: string; lessonsId: string }
>('get', apiUrls.coursesLessonsFiles, true);

export const postCoursesLessonsIdFiles = createApiRequest<
  FileLwssons,
  CoursesIdPayload,
  never,
  { coursesId: string; lessonsId: string }
>('post', apiUrls.coursesLessonsFiles, true, true);

export const deleteCoursesLessonsIdFiles = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { coursesId: string; lessonsId: string; filesId: string }
>('delete', apiUrls.coursesLessonsFilesId, true);

export const getCoursesLessonsTest = createApiRequest<
  EditWebinarsTest,
  never,
  never,
  { coursesId: string; lessonsId: string }
>('get', apiUrls.coursesLessonsTest, true);

export const postCoursesLessonsTestQuestions = createApiRequest<
  never,
  never,
  never,
  { coursesId: string; lessonsId: string }
>('post', apiUrls.coursesLessonsTestQuestions, true);

export const putCoursesLessonsTestQuestionsId = createApiRequest<
  Questions_set,
  WebinarsPayload,
  never,
  { coursesId: string; lessonsId: string; questionsId: string }
>('put', apiUrls.coursesLessonsTestQuestionsId, true);

export const putCoursesLessonsTestQuestionsAnswersId = createApiRequest<
  EventTestQuestionAnswerId,
  TestQuestionAnswerPayload,
  never,
  { coursesId: string; lessonsId: string; questionsId: string; answersId: string }
>('put', apiUrls.coursesLessonsTestQuestionsAnswersId, true);

export const deleteCoursesLessonsTestQuestionsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { coursesId: string; lessonsId: string; questionsId: string }
>('delete', apiUrls.coursesLessonsTestQuestionsId, true);

export const deleteCoursesLessonsTestQuestionsAnswersId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { coursesId: string; lessonsId: string; questionsId: string; answersId: string }
>('delete', apiUrls.coursesLessonsTestQuestionsAnswersId, true);

export const postCoursesLessonsTestQuestionsAnswers = createApiRequest<
  never,
  never,
  never,
  { coursesId: string; lessonsId: string; questionsId: string }
>('post', apiUrls.coursesLessonsTestQuestionsAnswers, true);

export const deleteLessonsId = createApiRequest<
  CoursesId,
  never,
  never,
  { coursesId: string; lessonsId: string }
>('delete', apiUrls.coursesLessonsId, true);

export const getWebinarRealtimePoll = createApiRequest<
  FormEditWebinarsOpinion,
  never,
  never,
  { webinarsId: string }
>('get', apiUrls.webinarsRealtimePoll, true);

export const getWebinarRealtimePollResultCharts = createApiRequest<
  never,
  never,
  never,
  { webinarsId: string }
>('get', apiUrls.webinarsRealtimePollResultsChart, true);

export const postWebinarRealtimePoll = createApiRequest<
  FormEditWebinarsOpinion,
  never,
  never,
  { webinarsId: string }
>('post', apiUrls.webinarsRealtimePoll, true);

export const postWebinarRealtimePollQuestions = createApiRequest<
  never,
  never,
  never,
  { webinarsId: string }
>('post', apiUrls.webinarsRealtimePollQuestions, true);

export const postWebinarRealtimePollQuestionLaunch = createApiRequest<
  FormEditWebinarsOpinion,
  never,
  never,
  { webinarsId: string; questionsId: string }
>('post', apiUrls.webinarsRealtimePollQuestionsIdLaunch, true);

export const postWebinarRealtimePollQuestionsAnswers = createApiRequest<
  never,
  never,
  never,
  { webinarsId: string; questionsId: string }
>('post', apiUrls.webinarsRealtimePollQuestionsAnswers, true);

export const putWebinarRealtimePollQuestionsId = createApiRequest<
  Questions_set,
  WebinarsPayload,
  never,
  { webinarsId: string; questionsId: string }
>('put', apiUrls.webinarsRealtimePollQuestionsId, true);

export const putWebinarRealtimePollQuestionsAnswersId = createApiRequest<
  EventTestQuestionAnswerId,
  TestQuestionAnswerPayload,
  never,
  { webinarsId: string; questionsId: string; answersId: string }
>('put', apiUrls.webinarsRealtimePollQuestionsAnswersId, true);

export const deleteWebinarRealtimePollQuestionsId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsId: string; questionsId: string }
>('delete', apiUrls.webinarsRealtimePollQuestionsId, true);

export const deleteWebinarsRealtimePollQuestionsAnswersId = createApiRequest<
  FormEditWebinars,
  never,
  never,
  { webinarsId: string; questionsId: string; answersId: string }
>('delete', apiUrls.webinarsRealtimePollQuestionsAnswersId, true);
