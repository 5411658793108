import { RouteConfig } from '../../../../types';
import SchoolIcon from '@material-ui/core/SvgIcon/SvgIcon';
import withAdmin from '../../../../hocs/withAdmin';
import CoursesEditPage from './EditPage';
import CoursesEditTestPage from './EditTest';
import CoursesEditLessonsPage from './EditLesson';
import CoursesEditLessonTest from './EditLessonTest';
export enum CoursesRoute {
  coursesId = ':coursesId',
  coursesTestId = ':coursesTestId',
  coursesLessonId = ':coursesLessonId',
  coursesLessonIdTest = 'test',
}
export const coursesRoutes: Record<CoursesRoute, RouteConfig> = {
  [CoursesRoute.coursesId]: {
    path: '/edit-course/:coursesId',
    tKey: 'ROUTES.COURSES.EDIT',
    Icon: SchoolIcon,
    component: withAdmin(CoursesEditPage),
    exact: true,
  },
  [CoursesRoute.coursesTestId]: {
    path: '/course-test-edit/:coursesTestId',
    tKey: 'ROUTES.COURSES.TEST_EDIT',
    Icon: SchoolIcon,
    component: withAdmin(CoursesEditTestPage),
    exact: true,
  },
  [CoursesRoute.coursesLessonId]: {
    path: '/edit-course/:coursesId/lesson/:coursesLessonId',
    tKey: 'ROUTES.COURSES.TEST_EDIT',
    Icon: SchoolIcon,
    component: withAdmin(CoursesEditLessonsPage),
    exact: true,
  },
  [CoursesRoute.coursesLessonIdTest]: {
    path: '/edit-course/:coursesId/lesson/:coursesLessonId/test',
    tKey: 'ROUTES.COURSES.TEST_EDIT',
    Icon: SchoolIcon,
    component: withAdmin(CoursesEditLessonTest),
    exact: true,
  },
};
