import React, { FC } from 'react';
import { getHippocratesContentHome, patchHippocratesContentHome } from 'src/api';
import FormContainer from 'src/components/FormContainer';
import { HippocratesContentHome } from 'src/api/types';
import fieldsConfig from './fieldsConfig';

const filterSavePayload = (values: HippocratesContentHome) => {
  const res = { ...values };

  if (typeof res?.banner === 'string') {
    delete res.banner;
  }

  return res;
};

type Props = unknown;

const MainPage: FC<Props> = () => {
  return (
    <FormContainer
      config={fieldsConfig}
      getContent={getHippocratesContentHome}
      saveContent={patchHippocratesContentHome}
      filterSavePayload={filterSavePayload}
    />
  );
};

export default MainPage;
