import { supplant } from '../utils/helpers';

export const root = process.env.REACT_APP_API_URL;

export const apiUrls = {
  root,
  signup: '/users/register/',
  confirmRegistration: supplant('/users/confirmation/{{id}}/{{token}}'),
  login: '/auth/token/',
  refresh: '/auth/token/refresh/',
  me: '/me/',
  resetPassword: '/auth/reset_password/',
  specializations: '/specializations/',
  specializationsId: supplant('/specializations/{{categoriesId}}'),
  events: '/events/',
  eventById: supplant('/events/{{eventId}}/'),
  eventPartners: supplant('/events/{{eventId}}/partners/'),
  eventPartnersId: supplant('/events/{{eventId}}/partners/{{partnerId}}/'),
  eventTest: supplant('/events/{{eventId}}/test/'),
  eventTestQuestions: supplant('/events/{{eventId}}/test/questions/'),
  eventTestQuestionById: supplant('/events/{{eventId}}/test/questions/{{questionId}}/'),

  eventTestQuestionAnswers: supplant('/events/{{eventId}}/test/questions/{{questionId}}/answers/'),

  eventTestQuestionAnswerById: supplant(
    '/events/{{eventId}}/test/questions/{{questionId}}/answers/{{answerId}}',
  ),
  eventOpinion: supplant('/events/{{eventId}}/poll/'),
  eventPollQuestions: supplant('/events/{{eventId}}/poll/questions/'),
  eventPollQuestionsId: supplant('/events/{{eventId}}/poll/questions/{{questionsId}}/'),
  eventPollQuestionsAnswersId: supplant(
    '/events/{{eventId}}/poll/questions/{{questionsId}}/answers/{{answersId}}/',
  ),
  eventPollQuestionsAnswers: supplant(
    '/events/{{eventId}}/poll/questions/{{questionsId}}/answers/',
  ),

  eventRealtimePoll: supplant('/events/{{eventId}}/realtime_poll/'),
  eventRealtimePollResultsChart: supplant('/events/{{eventId}}/realtime_poll/results/charts/'),
  eventRealtimePollQuestions: supplant('/events/{{eventId}}/realtime_poll/questions/'),
  eventRealtimePollQuestionsId: supplant(
    '/events/{{eventId}}/realtime_poll/questions/{{questionsId}}/',
  ),
  eventRealtimePollQuestionsIdLaunch: supplant(
    '/events/{{eventId}}/realtime_poll/questions/{{questionsId}}/launch/',
  ),
  eventRealtimePollQuestionsAnswersId: supplant(
    '/events/{{eventId}}/realtime_poll/questions/{{questionsId}}/answers/{{answersId}}/',
  ),
  eventRealtimePollQuestionsAnswers: supplant(
    '/events/{{eventId}}/realtime_poll/questions/{{questionsId}}/answers/',
  ),

  eventTestResults: supplant('/events/{{eventId}}/results/'),
  eventTestResultsCertificate: supplant('/events/{{eventId}}/results/certificate/'),
  registerToEvent: supplant('/events/{{eventId}}/register/'),
  registerToEventCheck: supplant('/events/{{eventId}}/register/check/'),
  subscriptions: '/subscriptions/',
  hippocratesContentMain: '/content/hippocrates/main/',
  hippocratesContentHome: '/content/hippocrates/home/',
  hippocratesContentProjects: '/content/hippocrates/projects/',
  hippocratesContentContacts: '/content/hippocrates/contacts/',

  certificates: '/statistics/certificates/',
  downloadCertificates: supplant('/{{url}}/certificates/{{certificatesId}}/'),

  statisticsCourses: '/statistics/courses/',
  statisticsCoursesID: supplant('statistics/courses/{{Id}}/results/'),

  statisticsCoursesSummary: '/statistics/courses/summary/',
  statisticsCoursesSummaryExcel: '/statistics/courses/summary/excel/',
  statisticsCoursesSummaryByID: supplant('statistics/courses/summary/{{coursesId}}/'),
  statisticsCoursesSummaryByIDExcel: supplant('statistics/courses/summary/{{coursesId}}/excel/'),

  statisticsWebinarsSummary: '/statistics/webinars/summary/',
  statisticsWebinarsSummaryExcel: '/statistics/webinars/summary/excel/',
  statisticsWebinarsSummaryByID: supplant('statistics/webinars/summary/{{webinarsId}}/'),
  statisticsWebinarsSummaryByIDExcel: supplant('statistics/webinars/summary/{{webinarsId}}/excel/'),

  webinars: '/webinars/',
  webinarsId: supplant('/webinars/{{webinarsId}}/'),
  webinarsTest: supplant('/webinars/{{webinarsTest}}/test/'),
  webinarsTestQuestions: supplant('/webinars/{{webinarsTest}}/test/questions/'),
  webinarsTestQuestionsId: supplant('/webinars/{{webinarsTest}}/test/questions/{{questionsId}}/'),
  webinarsTestQuestionsAnswers: supplant(
    '/webinars/{{webinarsTest}}/test/questions/{{questionsId}}/answers/',
  ),
  webinarsTestQuestionsAnswersId: supplant(
    '/webinars/{{webinarsTest}}/test/questions/{{questionsId}}/answers/{{answersId}}/',
  ),
  webinarsOpinion: supplant('/webinars/{{webinarsOpinion}}/poll/'),
  webinarsPollQuestions: supplant('/webinars/{{webinarsOpinion}}/poll/questions/'),
  webinarsPollQuestionsId: supplant(
    '/webinars/{{webinarsOpinion}}/poll/questions/{{questionsId}}/',
  ),
  webinarsPollQuestionsAnswersId: supplant(
    '/webinars/{{webinarsOpinion}}/poll/questions/{{questionsId}}/answers/{{answersId}}/',
  ),
  webinarsPollQuestionsAnswers: supplant(
    '/webinars/{{webinarsOpinion}}/poll/questions/{{questionsId}}/answers/',
  ),

  webinarsRealtimePoll: supplant('/webinars/{{webinarsId}}/realtime_poll/'),
  webinarsRealtimePollResultsChart: supplant(
    '/webinars/{{webinarsId}}/realtime_poll/results/charts/',
  ),
  webinarsRealtimePollQuestions: supplant('/webinars/{{webinarsId}}/realtime_poll/questions/'),
  webinarsRealtimePollQuestionsId: supplant(
    '/webinars/{{webinarsId}}/realtime_poll/questions/{{questionsId}}/',
  ),
  webinarsRealtimePollQuestionsIdLaunch: supplant(
    '/webinars/{{webinarsId}}/realtime_poll/questions/{{questionsId}}/launch/',
  ),
  webinarsRealtimePollQuestionsAnswersId: supplant(
    '/webinars/{{webinarsId}}/realtime_poll/questions/{{questionsId}}/answers/{{answersId}}/',
  ),
  webinarsRealtimePollQuestionsAnswers: supplant(
    '/webinars/{{webinarsId}}/realtime_poll/questions/{{questionsId}}/answers/',
  ),

  courses: '/courses/',
  coursesId: supplant('/courses/{{coursesId}}/'),
  coursesTestId: supplant('/courses/{{coursesTestId}}/testing/'),
  coursesTestQuestions: supplant('/courses/{{coursesTestId}}/testing/questions/'),
  coursesTestQuestionsId: supplant('/courses/{{coursesTestId}}/testing/questions/{{questionsId}}/'),
  coursesTestQuestionsAnswersId: supplant(
    '/courses/{{coursesTestId}}/testing/questions/{{questionsId}}/answers/{{answersId}}/',
  ),
  coursesTestQuestionsAnswers: supplant(
    '/courses/{{coursesTestId}}/testing/questions/{{questionsId}}/answers/',
  ),
  coursesLessons: supplant('/courses/{{coursesId}}/lessons/'),
  coursesLessonsId: supplant('/courses/{{coursesId}}/lessons/{{lessonsId}}/'),
  coursesLessonsFiles: supplant('/courses/{{coursesId}}/lessons/{{lessonsId}}/files/'),
  coursesLessonsFilesId: supplant(
    '/courses/{{coursesId}}/lessons/{{lessonsId}}/files/{{filesId}}/',
  ),
  coursesLessonsTest: supplant('/courses/{{coursesId}}/lessons/{{lessonsId}}/testing/'),
  coursesLessonsTestQuestions: supplant(
    '/courses/{{coursesId}}/lessons/{{lessonsId}}/testing/questions/',
  ),
  coursesLessonsTestQuestionsId: supplant(
    '/courses/{{coursesId}}/lessons/{{lessonsId}}/testing/questions/{{questionsId}}/',
  ),
  coursesLessonsTestQuestionsAnswersId: supplant(
    '/courses/{{coursesId}}/lessons/{{lessonsId}}/testing/questions/{{questionsId}}/answers/{{answersId}}/',
  ),
  coursesLessonsTestQuestionsAnswers: supplant(
    '/courses/{{coursesId}}/lessons/{{lessonsId}}/testing/questions/{{questionsId}}/answers/',
  ),
  users: '/users/',
  usersId: supplant('/users/{{usersId}}'),
  usersDownloadExcel: '/users/excel/',
};
