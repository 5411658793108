import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classes from './CertificatesPage.module.scss';
import { getCertificate, getCertificatesFiles } from '../../../api';
import ConditionalRender from '../../../components/ConditionalRender';
import download from 'downloadjs';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = unknown;

const CertificatesPage: FC<Props> = () => {
  const [content, setContent] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDownload, setLoadingDownload] = React.useState(false);
  const [DownloadId, setDownload] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getCertificate();
      setContent(data);
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(true);
    }
  }, [enqueueSnackbar, t]);

  function arrayContetn(el) {
    const array = [];
    if (el) {
      el.forEach(a => {
        const date = new Date(a.created);
        array.push({
          id: a.id,
          name: a.name,
          url: a.url,
          created: date.getDate() + ' ' + t('MONTH.' + date.getMonth()) + ' ' + date.getFullYear(),
        });
      });
    }

    return array;
  }

  const fieldsConfig = useMemo(() => {
    const array = [];
    const course = {
      name: t('CERTIFICATES.COURSE'),
      url: 'courses',
      data: arrayContetn(content?.course_certificates),
    };
    const event = {
      name: t('CERTIFICATES.EVENT'),
      url: 'events',
      data: arrayContetn(content?.event_certificates),
    };
    const webinar = {
      name: t('CERTIFICATES.WEBINA'),
      url: 'webinars',
      data: arrayContetn(content?.webinar_certificates),
    };

    if (content && content.course_certificates.length > 0) {
      array.push(course);
    }
    if (content && content.event_certificates.length > 0) {
      array.push(event);
    }
    if (content && content.webinar_certificates.length > 0) {
      array.push(webinar);
    }

    return array;
  }, [content]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const download = async (
    event: React.MouseEvent,
    certificatesId: string,
    name: string,
    url: string,
  ) => {
    event.stopPropagation();
    if (!loadingDownload) {
      setSuccess(false);
      setLoadingDownload(true);
      setDownload(certificatesId);

      let downloadFile = getCertificatesFiles({
        urlParams: { url, certificatesId },
      }).then(resp => {
        setSuccess(true);
        setLoadingDownload(false);
        setDownload(null);
        downloadFileUtil(resp, {
          fileName: name,
          extension: 'pdf',
        });
      });
    }
  };

  return (
    <ConditionalRender condition={loading}>
      <Container disableGutters maxWidth="lg">
        {fieldsConfig
          ? fieldsConfig.map((item, key) => {
              return (
                <Card className={classes.root} key={item}>
                  <CardContent>
                    <TableContainer>
                      <Typography variant="h6" id="tableTitle">
                        {item.name}
                      </Typography>
                      <Table className={classes.table} aria-label="enhanced table">
                        <TableHead>
                          <TableRow>
                            <TableCell width="55%">{t('CERTIFICATES.TABLE.NAME')}</TableCell>
                            <TableCell width="27%" align="right">
                              {' '}
                            </TableCell>
                            <TableCell width="18%" align="right">
                              {t('CERTIFICATES.TABLE.DATE')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item.data
                            ? item.data.map(row => (
                                <TableRow key={row.name}>
                                  <TableCell component="th" scope="row">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Button
                                      variant="contained"
                                      disabled={loadingDownload}
                                      className={classes.buttonClassname}
                                      onClick={event => download(event, row.id, row.name, item.url)}
                                    >
                                      {t('CERTIFICATES.TABLE.DOWNLOAD')}
                                      {loadingDownload && DownloadId == row.id && (
                                        <CircularProgress
                                          size={24}
                                          className={classes.buttonProgress}
                                        />
                                      )}
                                    </Button>
                                  </TableCell>
                                  <TableCell align="right">{row.created}</TableCell>
                                </TableRow>
                              ))
                            : ''}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              );
            })
          : ''}
      </Container>
    </ConditionalRender>
  );
};

export default CertificatesPage;

const getFileNameFromContentDisposition = disposition => {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }

  return '';
};
export const downloadFileUtil = (response, { fileName: fileNameFromMeta, extension }) => {
  const {
    data,
    headers: { filename, 'content-disposition': contentDisposition },
  } = response;

  const predefinedName = extension ? `${fileNameFromMeta}.${extension}` : fileNameFromMeta;
  const nameFromDisposition = getFileNameFromContentDisposition(contentDisposition);
  const name = nameFromDisposition || filename || predefinedName;

  download(data, name);
};
