import { CellValue, SortModel, ValueFormatterParams } from '@material-ui/data-grid';
import { formatDate } from './date';

export const formatDateCell = ({ value }: ValueFormatterParams): CellValue =>
  formatDate(String(value), 'Pp');

export const sortModelToOrderingProp = (sortModel: SortModel): string => {
  const arrResult = sortModel.map(sort => {
    const prefix = sort.sort === 'desc' ? '-' : '';

    return `${prefix}${sort.field}`;
  });

  return arrResult.join(',');
};
