import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ConditionalRender from '../../../../../components/ConditionalRender';
import className from './UserEdidPage.module.scss';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Backdrop from '../../../../../components/Backdrop';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FormWithConfig from '../../../../../components/FormWithConfig';
import Typography from '@material-ui/core/Typography';
import { getSpecializations, getUsersId, patchMe, putUsersId } from 'src/api';
import yup from '../../../../../utils/validation';
import { regionsOptions } from '../../../../../constants/regionsOptions';
import { Specialization } from '../../../../../types';
import getFieldsConfig from '../../../../User/ProfilePage/fieldsConfig';

type Props = unknown;

const EditUsersPage: FC<Props> = () => {
  const { id } = useParams();
  const [content, setContent] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const [specializations, setSpecializations] = useState<Specialization[]>();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      // console.log(id);
      const { data } = await getUsersId({ urlParams: { usersId: id } });
      const { data: specializationsData } = await getSpecializations();

      setContent(data);
      setSpecializations(specializationsData.results);
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const fieldsConfig = useMemo(() => {
    return getFieldsConfig({ specializations });
  }, [specializations]);
  const input = [
    {
      name: 'role',
      label: 'Роль користувача',
      fieldType: 'radio',
      required: false,
      validate: null,
      options: [
        {
          label: 'Користувач',
          value: 'user',
        },
        {
          label: 'Модератор',
          value: 'moder',
        },
        {
          label: 'Адміністратор',
          value: 'admin',
        },
      ],
    },
    {
      name: 'last_name',
      label: 'COMMON.LAST_NAME',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'first_name',
      label: 'COMMON.FIRST_NAME',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'parent_name',
      label: 'COMMON.MIDDLE_NAME',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'birth_date',
      label: 'COMMON.BIRTHDAY',
      fieldType: 'datePicker',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'email',
      label: 'COMMON.E_MAIL',
      fieldType: 'text',
      required: true,
      validate: yup.string().email().required(),
    },
    {
      name: 'phone',
      label: 'COMMON.PHONE_NUMBER',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'region',
      label: 'COMMON.STATE',
      fieldType: 'select',
      required: true,
      validate: yup.string().required(),
      options: regionsOptions.map(region => ({
        label: region,
        value: region,
      })),
      mapoptions: (options, t) =>
        options.map(({ label, value }) => ({
          label,
          value: t(value),
        })),
    },
    {
      name: 'town',
      label: 'COMMON.CITY',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'college',
      label: 'COMMON.EDU',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'diploma',
      label: 'COMMON.DIPLOMA_NUMBER',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'start_activity_date',
      label: 'COMMON.CAREER_START_DATE',
      fieldType: 'datePicker',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'job_place',
      label: 'COMMON.WORK_PLACE',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'specialization',
      label: 'COMMON.SPECIALITY',
      fieldType: 'select',
      required: true,
      validate: yup.string().required(),
      options: specializations
        ? specializations.map(spec => ({
            label: spec.name,
            value: spec.id,
          }))
        : [],
    },
    {
      name: 'job_name',
      label: 'COMMON.JOB_POSITION',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'work_phone',
      label: 'COMMON.WORK_PHONE_NUMBER',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
  ];

  const handleSubmit = async (values, actions) => {
    try {
      const payload = values;

      const { data } = await putUsersId({ payload, urlParams: { usersId: id } });
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <ConditionalRender condition={loading}>
      <Backdrop invisible />
      <Container disableGutters maxWidth="lg">
        <Link className={className.link} to={{ pathname: `/users-management` }}>
          <Button variant="contained" size="large" color="default">
            {t('COMMON.BACK')}
          </Button>
        </Link>
        <Typography variant="h6" id="tableTitle" className={className.title}>
          Редагувати користувача
        </Typography>
        <FormWithConfig defaultValues={content} onSubmit={handleSubmit} config={input} />
      </Container>
    </ConditionalRender>
  );
};

export default EditUsersPage;
