import { FormFieldConfig, Specialization } from 'src/types';
import yup from 'src/utils/validation';
import { regionsOptions } from 'src/constants/regionsOptions';

type OptionsConfig = {
  specializations: Specialization[];
};

const getFieldsConfig = ({ specializations }: OptionsConfig): FormFieldConfig[] => [
  {
    name: 'last_name',
    label: 'COMMON.LAST_NAME',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'first_name',
    label: 'COMMON.FIRST_NAME',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'parent_name',
    label: 'COMMON.MIDDLE_NAME',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'birth_date',
    label: 'COMMON.BIRTHDAY',
    fieldType: 'datePicker',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'email',
    label: 'COMMON.E_MAIL',
    fieldType: 'text',
    required: true,
    validate: yup.string().email().required(),
  },
  {
    name: 'phone',
    label: 'COMMON.PHONE_NUMBER',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'region',
    label: 'COMMON.STATE',
    fieldType: 'select',
    required: true,
    validate: yup.string().required(),
    options: regionsOptions.map(region => ({
      label: region,
      value: region,
    })),
    mapoptions: (options, t) =>
      options.map(({ label, value }) => ({
        label,
        value: t(value),
      })),
  },
  {
    name: 'town',
    label: 'COMMON.CITY',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'college',
    label: 'COMMON.EDU',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'diploma',
    label: 'COMMON.DIPLOMA_NUMBER',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'start_activity_date',
    label: 'COMMON.CAREER_START_DATE',
    fieldType: 'datePicker',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'job_place',
    label: 'COMMON.WORK_PLACE',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'specialization',
    label: 'COMMON.SPECIALITY',
    fieldType: 'select',
    required: true,
    validate: yup.string().required(),
    options: specializations
      ? specializations.map(spec => ({
          label: spec.name,
          value: spec.id,
        }))
      : [],
  },
  {
    name: 'job_name',
    label: 'COMMON.JOB_POSITION',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'work_phone',
    label: 'COMMON.WORK_PHONE_NUMBER',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
];

export default getFieldsConfig;
