import withAdmin from 'src/hocs/withAdmin';
import { RouteConfig } from 'src/types';
import GeneralPage from './GeneralPage';
import MainPage from './MainPage';
import ProjectPage from './ProjectPage';
// import SectionsPage from './SectionsPage';
// import CalendarPage from './CalendarPage';
import ContactsPage from './ContactsPage';

export enum ContentManagementPage {
  General = 'general',
  Main = 'main',
  Project = 'project',
  // Sections = 'sections',
  // Calendar = 'calendar',
  Contacts = 'contacts',
}

export const contentManagementRoutes: Record<ContentManagementPage, RouteConfig> = {
  [ContentManagementPage.General]: {
    path: '/content-management/general',
    tKey: 'ROUTES.CONTENT_MANAGEMENT.GENERAL',
    component: withAdmin(GeneralPage),
    exact: true,
  },
  [ContentManagementPage.Main]: {
    path: '/content-management/main',
    tKey: 'ROUTES.CONTENT_MANAGEMENT.MAIN',
    component: withAdmin(MainPage),
    exact: true,
  },
  [ContentManagementPage.Project]: {
    path: '/content-management/project',
    tKey: 'ROUTES.CONTENT_MANAGEMENT.PROJECT',
    component: ProjectPage,
    exact: true,
  },
  // [ContentManagementPage.Sections]: {
  //   path: '/content-management/sections',
  //   tKey: 'ROUTES.CONTENT_MANAGEMENT.SECTIONS',
  //   component: SectionsPage,
  //   exact: true,
  // },
  // [ContentManagementPage.Calendar]: {
  //   path: '/content-management/calendar',
  //   tKey: 'ROUTES.CONTENT_MANAGEMENT.CALENDAR',
  //   component: CalendarPage,
  //   exact: true,
  // },
  [ContentManagementPage.Contacts]: {
    path: '/content-management/contacts',
    tKey: 'ROUTES.CONTENT_MANAGEMENT.CONTACTS',
    component: ContactsPage,
    exact: true,
  },
};
