import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Container from '@material-ui/core/Container';
import { Card, FormControl } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import ConditionalRender from '../../../components/ConditionalRender';
import { getStatisticsCourses, getStatisticsCoursesId } from '../../../api';
import classes from './CoursesPage.module.scss';

type Props = unknown;

const CoursesPage: FC<Props> = () => {
  const [content, setContent] = useState([]);
  const [contentCourses, setContentCourses] = useState();
  const [age, setAge] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getStatisticsCourses();
      setContent(data);
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(true);
    }
  }, [enqueueSnackbar, t]);

  const CoursesId = async (id: string) => {
    setAge(id);
    try {
      const cours = await getStatisticsCoursesId({
        urlParams: { Id: id },
      });
      setContentCourses(cours.data);
    } finally {
      setLoadingCourses(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    CoursesId(event.target.value);
  };

  const fieldsConfig = useMemo(() => {
    let course = [];
    if (content && content.length > 0) {
      course = content;
      CoursesId(content[0].id);
    }
    return course;
  }, [content]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ConditionalRender condition={loading}>
      <Container disableGutters maxWidth="lg">
        <Card className={classes.root}>
          <CardContent>
            <FormControl className={classes.formControl}>
              <InputLabel id="simple-select-label">{t('COURSESPAGE.CHOOSE_A_COURSE')}</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={age}
                onChange={handleChange}
              >
                {!fieldsConfig
                  ? ''
                  : fieldsConfig.map((item, key) => {
                      return (
                        <MenuItem key={item} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
        <ConditionalRender condition={loadingCourses}>
          <Card className={classes.root}>
            <CardContent>
              {contentCourses &&
                contentCourses.map(row => {
                  return (
                    <>
                      <Table className={classes.table} key={row} aria-label="enhanced table">
                        <TableHead>
                          <TableRow>
                            <TableCell width="55%">{t('COURSESPAGE.LESSON')}</TableCell>
                            <TableCell width="27%">
                              {t('COURSESPAGE.AVAILABILITY_OF_TESTING')}
                            </TableCell>
                            <TableCell width="18%">{t('COURSESPAGE.RESULT')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.lessons.map(item => {
                            return (
                              <TableRow key={item}>
                                <TableCell component="th" scope="row">
                                  {item.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {item.has_test ? t('COURSESPAGE.YES') : t('COURSESPAGE.NO')}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {item.result}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {row.result !== null && (
                        <>
                          <p className={classes.final_test}>
                            {t('COURSESPAGE.FINAL_TEST')} {row?.result}
                          </p>
                        </>
                      )}
                    </>
                  );
                })}
            </CardContent>
          </Card>
        </ConditionalRender>
      </Container>
    </ConditionalRender>
  );
};

export default CoursesPage;
