import React from 'react';
import { ColDef } from '@material-ui/data-grid';

const columns: ColDef[] = [
  { field: 'id', headerName: 'DATA_TABLE.ID', type: 'number', width: 100 },
  { field: 'email', headerName: 'STATISTICS.USERS_TABLE.EMAIL', width: 250 },
  { field: 'full_name', headerName: 'STATISTICS.USERS_TABLE.FULL_NAME', width: 250 },
  { field: 'phone', headerName: 'STATISTICS.USERS_TABLE.PHONE', width: 150 },
  { field: 'work_phone', headerName: 'STATISTICS.USERS_TABLE.WORK_PHONE', width: 150 },
  { field: 'region', headerName: 'STATISTICS.USERS_TABLE.REGION', width: 150 },
  { field: 'town', headerName: 'STATISTICS.USERS_TABLE.TOWN', width: 100 },
  { field: 'job_place', headerName: 'STATISTICS.USERS_TABLE.JOB_PLACE', width: 250 },
  { field: 'job_name', headerName: 'STATISTICS.USERS_TABLE.JOB_NAME', width: 250 },
  {
    field: 'specialization_name',
    headerName: 'STATISTICS.USERS_TABLE.SPECIALIZATION_NAME',
    width: 250,
  },
  { field: 'pass_counter', headerName: 'STATISTICS.USERS_TABLE.PASS_COUNTER', width: 150 },
  {
    field: 'certificates_count',
    headerName: 'STATISTICS.USERS_TABLE.CERTIFICATES_COUNT',
    width: 150,
  },
];

export default columns;
