import { FormFieldConfig } from 'src/types';
import yup from 'src/utils/validation';

const fieldsConfig: FormFieldConfig[] = [
  {
    name: 'email',
    label: 'COMMON.E_MAIL',
    fieldType: 'text',
    required: true,
    validate: yup.string().email().required(),
  },
  {
    name: 'phones',
    label: 'COMMON.PHONE_NUMBER',
    fieldType: 'chip',
    required: true,
    validate: yup.string().required(),
  },
  {
    name: 'logo',
    label: 'COMMON.LOGO',
    fieldType: 'fileUpload',
    required: false,
    maxfilesize: 5000000,
    acceptedfiles: ['image/jpeg', 'image/png'],
  },
  {
    name: 'copyright',
    label: 'COMMON.SITE_CAPTION',
    fieldType: 'text',
    required: true,
    validate: yup.string().required(),
  },
];

export default fieldsConfig;
