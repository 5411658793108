import React, { FC } from 'react';
import { Tab, Tabs, AppBar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useRouter from '../../hooks/useRouter';
import { RouteConfig } from '../../types';

import styles from './RouterTabs.module.scss';

type Props = {
  routes: Record<string, RouteConfig>;
};

const RouterTabs: FC<Props> = ({ routes }) => {
  const { pathname, history } = useRouter();
  const { t } = useTranslation();

  const onChange = (_e, newValue) => {
    history.push(newValue);
  };

  return (
    <div className={styles.wrapper}>
      <AppBar position="static">
        <Tabs value={pathname} onChange={onChange} variant="fullWidth">
          {Object.values(routes).map(({ tKey, path }) => (
            <Tab key={tKey} label={t(tKey)} value={path} />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
};

export default RouterTabs;
