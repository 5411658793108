import React, { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const withAdmin = (WrappedComponent: ComponentType<any>) => {
  return props => {
    const {
      state: { isAdmin },
    } = useAuth();

    return isAdmin ? <WrappedComponent {...props} /> : <Redirect to="/profile" />;
  };
};

export default withAdmin;
