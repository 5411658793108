import Cookies from 'universal-cookie';
import jsonwebtoken from 'jsonwebtoken';
import { User } from 'src/types';
import { AxiosResponse } from 'axios';
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_ACCESS_TOKEN_EXP,
  COOKIE_REFRESH_TOKEN,
} from 'src/constants/auth';
import { RefreshResult } from 'src/api/types';
import { getSharedDomain } from 'src/utils/helpers';
import { getMe, postRefreshToken } from '.';

export const saveToken = (accessToken: string, refreshToken: string): Promise<unknown> => {
  const decodedAccess = jsonwebtoken.decode(accessToken);

  const cookies = new Cookies();
  const shared_domain = getSharedDomain();
  if (accessToken) {
    cookies.remove(COOKIE_ACCESS_TOKEN, { domain: shared_domain, path: '/' });
    cookies.remove(COOKIE_ACCESS_TOKEN_EXP, { domain: shared_domain, path: '/' });
    cookies.set(COOKIE_ACCESS_TOKEN, accessToken, { domain: shared_domain, path: '/' });
    if (decodedAccess) {
      cookies.set(COOKIE_ACCESS_TOKEN_EXP, decodedAccess.exp, { domain: shared_domain, path: '/' });
    }
  }
  if (refreshToken) cookies.set(COOKIE_REFRESH_TOKEN, refreshToken, { path: '/' });
  return Promise.resolve();
};

export const checkAuthToken = async (): Promise<AxiosResponse<User>> => {
  return getMe();
};

export const refresh = async (): Promise<AxiosResponse<RefreshResult>> => {
  const cookies = new Cookies();

  const cookieRefreshToken = cookies.get(COOKIE_REFRESH_TOKEN);

  if (!cookieRefreshToken) {
    return Promise.reject();
  }

  return postRefreshToken({
    payload: {
      refresh: cookieRefreshToken,
    },
  });
};

export const authenticateToken = async () => {
  const cookies = new Cookies();
  const shared_domain = getSharedDomain();
  const cookieAccessToken = cookies.get(COOKIE_ACCESS_TOKEN);
  if (!cookieAccessToken) return null;

  try {
    const { data } = await checkAuthToken();
    return data;
  } catch (e) {
    console.log(e);
    try {
      const { data } = await refresh();

      await saveToken(data.access, data.refresh);

      return authenticateToken();
    } catch {
      return null;
    }
  }
};

export const logout = async () => {
  const cookies = new Cookies();
  const shared_domain = getSharedDomain();
  cookies.remove(COOKIE_ACCESS_TOKEN, { domain: shared_domain, path: '/' });
  cookies.remove(COOKIE_ACCESS_TOKEN_EXP, { domain: shared_domain, path: '/' });
  cookies.remove(COOKIE_REFRESH_TOKEN, { domain: shared_domain, path: '/' });
  return Promise.resolve();
};
