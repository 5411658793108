import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { EventTestQuestion } from '../../../../../types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ConditionalRender from '../../../../../components/ConditionalRender';
import Backdrop from '../../../../../components/Backdrop';
import Container from '@material-ui/core/Container';
import classet from './EventEdidOpinionPage.module.scss';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  getEventPoll,
  postEventPoll,
  postEventPollQuestions,
  postEventPollQuestionsAnswers,
  putEventPollQuestionsId,
  putEventPollQuestionsAnswersId,
  deleteEventPollQuestionsId,
  deleteEventPollQuestionsAnswersId,
} from '../../../../../api';
import { WebinarsForm } from '../../../../../api/types';
import FormLessonsItem from '../../../../../components/FormLessonsItem';

type Props = unknown;

const EditOpinionEventPage: FC<Props> = () => {
  const { eventId } = useParams();
  const [content, setContent] = useState();
  const [contentCreate, setContentCreate] = useState<boolean>(true);
  const [contentSet, setContentSet] = useState<EventTestQuestion[]>([]);
  const [contentOld, setContentOld] = useState([]);
  const [contentItemSave, setContentItemSave] = useState([]);
  const [contentItemAnswersSave, setContentItemAnswersSave] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      try {
        const { data } = await getEventPoll({ urlParams: { eventId } });
        setContent(data);
        setContentSet(data?.questions_set);
        if (data?.questions_set.length == 0) {
          setContentCreate(true);
        } else {
          setContentCreate(false);
        }

        let old = data?.questions_set.map(x => ({ ...x }));
        old = old.map(x => {
          x.answers_set = x.answers_set.map(i => ({ ...i }));
          return x;
        });
        setContentOld(old);
      } catch (e) {
        setContentCreate(true);
      }
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const add_Question = async () => {
    try {
      if (!content) {
        try {
          await postEventPoll({ urlParams: { eventId } });
          await postEventPollQuestions({
            urlParams: { eventId },
          });
          await fetchData();
        } catch {
          setContentCreate(true);
        }
      } else {
        const { data } = await postEventPollQuestions({
          urlParams: { eventId },
        });
        let old: any = Object.assign({}, data);
        old['answers_set'] = old['answers_set'].map(i => ({ ...i }));
        setContentSet(Array => [...Array, Object.assign({}, data)]);
        setContentOld(Array => [...Array, old]);
        setContentCreate(false);
      }

      enqueueSnackbar(t('COMMON.ADD_QUESTION'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const add_QuestionItem = async id => {
    try {
      const { data } = await postEventPollQuestionsAnswers({
        urlParams: { eventId, questionsId: id },
      });
      setContentSet(
        contentSet.map(obj => {
          if (obj.id === id) {
            obj.answers_set.push(Object.assign({}, data));
          }
          return obj;
        }),
      );
      setContentOld(
        contentOld.map(obj => {
          if (obj.id === id) {
            obj.answers_set.push(Object.assign({}, data));
          }
          return obj;
        }),
      );
      enqueueSnackbar(t('COMMON.ADD_QUESTION_ANSWERS'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const filterSaveItemPayload = (values: WebinarsForm) => {
    const res = { ...values };

    if (typeof res?.answers_set) {
      delete res.answers_set;
    }

    return res;
  };
  const saveItem = async id => {
    try {
      const payload = filterSaveItemPayload
        ? filterSaveItemPayload(contentSet.filter(a => a.id === id)[0])
        : contentSet.filter(a => a.id === id)[0];
      const { data } = await putEventPollQuestionsId({
        payload,
        urlParams: { eventId, questionsId: id },
      });
      setContentOld(
        contentOld.map(a => {
          if (a.id === id) {
            a = data;
          }
          return a;
        }),
      );
      setContentItemSave(contentItemSave.filter(a => a != id));

      enqueueSnackbar(t('COMMON.ADD_QUESTION_ANSWERS'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const saveItemAnswers = async (id, cID) => {
    try {
      for (const item of contentSet.filter(a => a.id === id)[0].answers_set) {
        const payload = item;
        const { data } = await putEventPollQuestionsAnswersId({
          payload,
          urlParams: { eventId, questionsId: id, answersId: String(item.id) },
        });
        setContentOld(
          contentOld.map(a => {
            if (a.id == id) {
              a.answers_set = a.answers_set.map(b => {
                if (b.id === item.id) {
                  b = data;
                }
                return b;
              });
            }
            return a;
          }),
        );
        setContentItemAnswersSave(prevState => prevState.filter(a => a !== item.id));
      }
      enqueueSnackbar(t('COMMON.ADD_QUESTION_ANSWERS'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const removeItem = async id => {
    try {
      await deleteEventPollQuestionsId({
        urlParams: { eventId, questionsId: id },
      });
      setContentSet(contentSet.filter(obj => obj.id !== id));
      setContentCreate(true);
      enqueueSnackbar(t('COMMON.DELETE_QUESTION'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const removeItemList = async (id, cID) => {
    try {
      await deleteEventPollQuestionsAnswersId({
        urlParams: { eventId, questionsId: id, answersId: cID },
      });
      setContentSet(
        contentSet.filter(obj => (obj.answers_set = obj.answers_set.filter(a => a.id !== cID))),
      );

      enqueueSnackbar(t('COMMON.DELETE_QUESTION'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const radioChange = (event: React.ChangeEvent<HTMLInputElement>, id) => {
    setContentSet(
      contentSet.map(obj => {
        if (obj.id == id) {
          obj.multiple_answers = (event.target as HTMLInputElement).value == 'true' ? true : false;
        }
        return obj;
      }),
    );
  };
  const radioAnswersChange = (event: React.ChangeEvent<HTMLInputElement>, id, cID) => {
    setContentSet(
      contentSet.map(obj => {
        if (obj.id === id) {
          obj.answers_set.map(item => {
            if (item.id === cID) {
              item.is_correct = (event.target as HTMLInputElement).value == 'true' ? true : false;
            } else {
              item.is_correct = false;
            }
            return item;
          });
        }
        return obj;
      }),
    );

    let old = contentOld.filter(a => a.id == id)[0].answers_set;
    old.map(a => {
      let Set = contentSet.filter(a => a.id == id)[0].answers_set.filter(b => b.id == a.id)[0];
      let titleOld = old?.title != undefined ? old.title : '';
      let titleSet = Set?.title != undefined ? Set.title : '';
      if (titleOld !== titleSet || a.is_correct !== Set.is_correct) {
        setContentItemAnswersSave(array => [...array, a.id]);
      } else {
        setContentItemAnswersSave(contentItemAnswersSave.filter(t => t != a.id));
      }
    });
  };
  const checkedAnswersChange = (event: React.ChangeEvent<HTMLInputElement>, id, cID) => {
    setContentSet(
      contentSet.map(obj => {
        if (obj.id == id) {
          obj.answers_set.map(item => {
            if (item.id == cID) {
              if (item.is_correct) {
                item.is_correct = false;
              } else {
                item.is_correct = true;
              }
            }
            return item;
          });
        }
        return obj;
      }),
    );

    let old = contentOld.filter(a => a.id == id)[0].answers_set.filter(a => a.id == cID)[0];
    let Set = contentSet.filter(a => a.id == id)[0].answers_set.filter(a => a.id == cID)[0];
    let titleOld = old?.title != undefined ? old.title : '';
    let titleSet = Set?.title != undefined ? Set.title : '';
    if (titleOld !== titleSet || old.is_correct !== Set.is_correct) {
      setContentItemAnswersSave(array => [...array, cID]);
    } else {
      setContentItemAnswersSave(contentItemAnswersSave.filter(a => a != cID));
    }
  };
  const itemChange = (event: React.ChangeEvent<HTMLInputElement>, id, type) => {
    setContentSet(
      contentSet.map(obj => {
        if (obj.id == id && type) {
          obj.title = event.target.value;
        }
        return obj;
      }),
    );
    let old = contentOld.filter(a => a.id == id)[0];
    let Set = contentSet.filter(a => a.id == id)[0];
    let titleOld = old?.title != undefined ? old.title : '';
    let titleSet = Set?.title != undefined ? Set.title : '';
    if (titleOld !== titleSet || old.multiple_answers !== Set.multiple_answers) {
      setContentItemSave(array => [...array, id]);
    } else {
      setContentItemSave(contentItemSave.filter(a => a != id));
    }
  };
  const itemChangeAnswers = (event: React.ChangeEvent<HTMLInputElement>, id, answersId, type) => {
    setContentSet(
      contentSet.filter(
        obj =>
          (obj.answers_set = obj.answers_set.filter(a => {
            if (a.id === answersId && type) {
              if (event.target.value) {
                a.title = event.target.value;
              } else {
                a.title = null;
              }
            }
            return a;
          })),
      ),
    );

    const old = contentOld
      .filter(a => a.id === id)[0]
      .answers_set.filter(a => a.id === answersId)[0];
    const Set = contentSet
      .filter(a => a.id === id)[0]
      .answers_set.filter(a => a.id === answersId)[0];
    const titleOld = old?.title !== undefined && old?.title !== null ? old.title : '';
    const titleSet = Set?.title !== undefined && Set?.title !== null ? Set.title : '';

    const is_correct = old?.is_correct !== undefined ? old.is_correct : false;

    if (titleOld !== titleSet || is_correct !== Set.is_correct) {
      setContentItemAnswersSave(array => [...array, answersId]);
    } else {
      setContentItemAnswersSave(contentItemAnswersSave.filter(a => a !== answersId));
    }
  };

  return (
    <ConditionalRender condition={loading}>
      <Backdrop invisible />
      <Container disableGutters maxWidth="lg">
        <Link className={classet.link} to={{ pathname: `/edit-event/${eventId}` }}>
          <Button variant="contained" size="large" color="default">
            {t('COMMON.BACK')}
          </Button>
        </Link>
        <Typography variant="h6" id="tableTitle">
          {t('EVENT.EDIT_SURVEY')}
        </Typography>
        <FormLessonsItem
          contentSet={contentSet}
          setContentCreate={contentCreate}
          saveItem={saveItem}
          saveItemAnswers={saveItemAnswers}
          itemIsSave={contentItemSave}
          itemAnswersIsSave={contentItemAnswersSave}
          removeItem={removeItem}
          removeItemList={removeItemList}
          radioChange={radioChange}
          radioAnswersChange={radioAnswersChange}
          checkedAnswersChange={checkedAnswersChange}
          add_Question={add_Question}
          add_QuestionItem={add_QuestionItem}
          itemChange={itemChange}
          itemChangeAnswers={itemChangeAnswers}
        />
      </Container>
    </ConditionalRender>
  );
};

export default EditOpinionEventPage;
