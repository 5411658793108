import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataGrid,
  ColDef,
  SortModel,
  RowData,
  PageChangeParams,
  SortModelParams,
} from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';

import { AxiosResponse } from 'axios';
import styles from './DataTable.module.scss';
import { ApiRequestOptions } from '../../api/types';
import { WithPagination } from '../../types';
import { sortModelToOrderingProp } from '../../utils/helpers/table';
import CustomLoadingOverlay from './CustomLoadingOverlay';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';

type Props = {
  getRowsApi: (options?: ApiRequestOptions) => Promise<AxiosResponse<WithPagination<any>>>;
  columns: ColDef[];
  reload?: any;
  search?: string;
};

const DataTable: React.FC<Props> = ({ columns, getRowsApi, reload, search }) => {
  const { t } = useTranslation();
  const [sortModel, setSortModel] = React.useState<SortModel>([]);
  const [rows, setRows] = useState<WithPagination<RowData>>();
  const [pagination, setPagination] = useState();
  const [loading, setLoading] = useState();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getRowsApi({
        queryParams: {
          page: pagination.page,
          page_size: pagination.pageSize,
          ordering: sortModelToOrderingProp(sortModel),
          search: search,
        },
      });

      setRows(data);
    } finally {
      setLoading(false);
    }
  }, [pagination, sortModel, search]);

  useEffect(() => {
    if (!pagination) return;

    fetchData();
  }, [pagination, sortModel, search, reload]);

  const mappedColumns = useMemo(() => {
    return columns.map(column => {
      return {
        ...column,
        headerName: t(column.headerName),
      };
    });
  }, [columns, t]);

  const handleSortModelChange = (params: SortModelParams) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  const handlePageChange = (params: PageChangeParams) => {
    setPagination({
      page: params.page,
      pageSize: params.pageSize,
    });
  };

  return (
    <div className={styles.wrapper}>
      <DataGrid
        autoHeight
        rows={rows?.results || []}
        columns={mappedColumns}
        sortModel={sortModel}
        sortingMode="server"
        paginationMode="server"
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageChange}
        pageSize={pagination?.pageSize || 10}
        page={pagination?.page || 1}
        rowCount={rows?.count}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pagination
        loading={loading}
        disableSelectionOnClick
        components={{
          loadingOverlay: CustomLoadingOverlay,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </div>
  );
};

export default DataTable;
