import React, { useReducer, useContext, useEffect } from 'react';
import { authenticateToken, saveToken } from 'src/api/auth';

import useRouter from 'src/hooks/useRouter';
import ConditionalRender from 'src/components/ConditionalRender';
import { siteLoginUrl } from '../constants/sites';
import Backdrop from '../components/Backdrop';

export const AuthStateContext = React.createContext({});

const initialState = { firstName: '', lastName: '', email: '', isLoggedIn: false };

export const SET_AUTH_DETAILS = 'SET_AUTH_DETAILS';
export const REMOVE_AUTH_DETAILS = 'REMOVE_AUTH_DETAILS';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_DETAILS:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        isAdmin: action.payload.isAdmin || true,
        role: action.payload.role,
        isLoggedIn: true,
      };
    case REMOVE_AUTH_DETAILS:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const AuthProvider = ({ children }) => {
  let localState = null;
  if (localStorage.getItem('hippocrates-admin-user-info')) {
    localState = JSON.parse(localStorage.getItem('hippocrates-admin-user-info') || '');
  }
  const [state, dispatch] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem('hippocrates-admin-user-info', JSON.stringify(state));
  }, [state]);

  const { queryParams, history } = useRouter();

  const setAuthDetails = ({ firstName, lastName, email, isAdmin, role }) => {
    dispatch({
      type: SET_AUTH_DETAILS,
      payload: {
        firstName,
        lastName,
        email,
        isAdmin,
        role,
      },
    });
  };

  const removeAuthDetails = () => {
    dispatch({
      type: REMOVE_AUTH_DETAILS,
    });
  };

  useEffect(() => {
    (async () => {
      const { a, r } = queryParams;

      if (a && r) {
        await saveToken(a, r);
        history.replace('/');
      }

      const res = await authenticateToken();

      if (res) {
        setAuthDetails({
          firstName: res.first_name,
          lastName: res.last_name,
          email: res.email,
          isAdmin: res.is_admin,
          role: res.role,
        });
      } else {
        removeAuthDetails();
        window.location.href = `${siteLoginUrl}?origin=${window.location.origin}`;
      }
    })();
  }, [history, queryParams, state.isLoggedIn]);

  return (
    <AuthStateContext.Provider value={{ state, setAuthDetails, removeAuthDetails }}>
      <ConditionalRender condition={state.isLoggedIn}>
        {children}
        <Backdrop />
      </ConditionalRender>
    </AuthStateContext.Provider>
  );
};

export const useAuth: any = () => useContext(AuthStateContext);
