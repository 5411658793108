import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { mapRoutes } from 'src/utils/helpers/router';
import RouterTabs from 'src/components/RouterTabs';
import { RouteConfig } from 'src/types';
import { contentManagementRoutes } from './pages/routes';

type Props = {
  routes: Record<string, RouteConfig>;
  defaultRoute: string;
};

const mappedRoutes = mapRoutes(contentManagementRoutes);

const ContentManagementPage: FC<Props> = ({ routes, defaultRoute }) => {
  return (
    <div>
      <RouterTabs routes={contentManagementRoutes} />
      <Switch>
        {mappedRoutes}
        <Redirect exact from="*" to={contentManagementRoutes.general.path} />
      </Switch>
    </div>
  );
};

export default ContentManagementPage;
