import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nConfig = {
  fallbackLng: 'ua',
  load: 'languageOnly',

  /*
   * Allow keys to be phrases having `:`, `.`
   *
   * Note: in process of separating translations.json file into multiple files
   * check these options and also values we receive from BE on OC Fota Campaign Devices table
   * the values may contain such values (LA: test: wwww) which translates to (test. www)
   * */
  nsSeparator: '@@@',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  debug: false,
  // returnedObjectHandler: (key, value) => value.ROOT || key,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: true,
    useSuspense: true,
  },

  backend: {
    queryStringParams: { t: Date.now() },
  },
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  // @ts-ignore
  .init(i18nConfig);

export default i18next;
