import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import FormWithConfig, { Props as FormWithConfigProps } from '../FormWithConfig';

import ConditionalRender from '../ConditionalRender';
import Backdrop from '../Backdrop';

type Props = {
  getContent: () => Promise<any>;
  saveContent: ({ payload }) => Promise<any>;
  filterSavePayload?: (payload) => any;
} & Omit<FormWithConfigProps, 'onSubmit'>;

const FormContainer: FC<Props> = ({ getContent, saveContent, filterSavePayload, ...rest }) => {
  const [content, setContent] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getContent();
      setContent(data);
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [getContent, enqueueSnackbar, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;

      await saveContent({ payload });
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <ConditionalRender condition={loading}>
      <Backdrop invisible />
      <Container disableGutters maxWidth="lg">
        <FormWithConfig defaultValues={content} onSubmit={handleSubmit} {...rest} />
      </Container>
    </ConditionalRender>
  );
};

export default FormContainer;
