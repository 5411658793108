import React, { FC, useCallback, useEffect, useState } from 'react';
import { getWebinarId, putWebinarsId } from '../../../../../api';
import { WebinarsForm } from '../../../../../api/types';
import { useParams, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ConditionalRender from '../../../../../components/ConditionalRender';
import Backdrop from '../../../../../components/Backdrop';
import Container from '@material-ui/core/Container';
import EditFormWebinars from '../../../../../components/Webinars/FormContainer';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import className from './WebinarsEdidPage.module.scss';

type Props = unknown;

const EditWebinarsPage: FC<Props> = () => {
  const { webinarsId } = useParams();
  const [content, setContent] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getWebinarId({ urlParams: { webinarsId } });
      setContent(data);
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    if (typeof res?.image === 'string') {
      delete res.image;
    }

    ['start_date', 'end_date', 'testing_end_date'].forEach(field => {
      res[field] = new Date(res?.[field]).toISOString();
    });

    return res;
  };

  const handleSubmit = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      await putWebinarsId({
        payload,
        urlParams: { webinarsId },
      });

      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <ConditionalRender condition={loading}>
      <Backdrop invisible />
      <Container disableGutters maxWidth="lg">
        <Link className={className.link} to={{ pathname: `/webinars-management` }}>
          <Button variant="contained" size="large" color="default">
            {t('COMMON.BACK')}
          </Button>
        </Link>

        <Typography variant="h6" id="tableTitle" className={className.title}>
          {t('WEBINARS.EDIT')}
        </Typography>
        <EditFormWebinars defaultValues={content} onSubmit={handleSubmit} config={content} />
        <div className={className.buttonFooter}>
          <Link
            className={[className.link, className.mr_10].join(' ')}
            to={{ pathname: `/translation-test-edit/${content?.id}` }}
          >
            <Button variant="contained" size="large" color="default">
              {t('WEBINARS.EDIT_TEST')}
            </Button>
          </Link>
          <Link
            className={[className.link, className.mr_10].join(' ')}
            to={{ pathname: `/translation-opinion-edit/${content?.id}` }}
          >
            <Button variant="contained" size="large" color="default">
              {t('WEBINARS.EDIT_POLL')}
            </Button>
          </Link>
          <Link
            className={className.link}
            to={{ pathname: `/translation-realtime-poll-edit/${content?.id}` }}
          >
            <Button variant="contained" size="large" color="default">
              {t('WEBINARS.EDIT_REALTIME_POLL')}
            </Button>
          </Link>
        </div>
      </Container>
    </ConditionalRender>
  );
};

export default EditWebinarsPage;
