import { RouteConfig } from '../../../../types';
import EditWebinarsPage from './EditPage';
import withAdmin from '../../../../hocs/withAdmin';
import VideoLabelIcon from '@material-ui/core/SvgIcon/SvgIcon';
import EditTestWebinarsPage from './EditTest';
import EditOpinionWebinarsPage from './EditOpinion';
import EditRealtimePollWebinarsPage from './EditRealTimePoll';
import RealtimePollResultsWebinarsPage from './EditRealTimePoll/RealTimePollResults';

export enum WebinarRoute {
  WebinarsManagementId = ':webinarsId',
  WebinarsManagementTestId = ':webinarsTest',
  WebinarsManagementOpinionId = ':webinarsOpinion',
  WebinarsManagementRealtimePollId = ':webinarsRealtimePoll',
  WebinarsManagementRealtimeResultsPollId = ':webinarsRealtimePollResult',
}

export const webinarsRoutes: Record<WebinarRoute, RouteConfig> = {
  [WebinarRoute.WebinarsManagementId]: {
    path: '/edit-translation/:webinarsId',
    tKey: 'ROUTES.WEBINARS.EDIT',
    Icon: VideoLabelIcon,
    component: withAdmin(EditWebinarsPage),
    exact: true,
  },

  [WebinarRoute.WebinarsManagementTestId]: {
    path: '/translation-test-edit/:webinarsTest',
    tKey: 'ROUTES.WEBINARS_MANAGEMENT.TEST_EDIT',
    Icon: VideoLabelIcon,
    component: withAdmin(EditTestWebinarsPage),
    exact: true,
  },

  [WebinarRoute.WebinarsManagementOpinionId]: {
    path: '/translation-opinion-edit/:webinarsOpinion',
    tKey: 'ROUTES.WEBINARS_MANAGEMENT.OPINION_EDIT',
    Icon: VideoLabelIcon,
    component: withAdmin(EditOpinionWebinarsPage),
    exact: true,
  },
  [WebinarRoute.WebinarsManagementRealtimePollId]: {
    path: '/translation-realtime-poll-edit/:webinarsId',
    tKey: 'ROUTES.WEBINARS_MANAGEMENT.REALTIME_POLL_EDIT',
    Icon: VideoLabelIcon,
    component: withAdmin(EditRealtimePollWebinarsPage),
    exact: true,
  },
  [WebinarRoute.WebinarsManagementRealtimeResultsPollId]: {
    path: '/translation-realtime-poll-edit/:webinarsId/results',
    tKey: 'ROUTES.WEBINARS_MANAGEMENT.REALTIME_POLL_EDIT',
    Icon: VideoLabelIcon,
    component: withAdmin(RealtimePollResultsWebinarsPage),
    exact: true,
  },
};
