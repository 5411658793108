import React, { FC, useEffect, useMemo, useState } from 'react';
import { FieldConfig, FieldProps } from 'formik';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import { TextField, Button, FormControl, FormHelperText } from '@material-ui/core';
import { SelectProps } from '@material-ui/core';
import { Option } from '../../../../types';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
    formControl: {
      width: '100%',
    },
  }),
);

type Props = SelectProps &
  FieldConfig &
  FieldProps & { options: Option[]; mapoptions: (options: Option[], t: TFunction) => Option[] };

const AutocompleteField: FC<Props> = ({ field, options, mapoptions, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errors, setErrors] = React.useState<boolean>(false);

  let i = [];
  const handleClick = value => {
    const map = [];
    value.map(a => map.push(a.value));
    rest.form.values[field?.name] = map;
    if (map.length == 0) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  };
  const error = useMemo(() => {
    const err = rest.form.errors[field.name];

    return err ? String(err) : '';
  }, [field, rest.form.errors, rest.form.values]);

  useEffect(() => {
    if (field.value) {
      field.value.map(a => {
        options.map(b => {
          if (b.value == a) {
            i.push(b);
          }
        });
        return i;
      });
    }
  });
  return (
    <div className={classes.root}>
      <FormControl variant={rest.variant} error={!!error} className={classes.formControl}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={options}
          getOptionLabel={option => option.label}
          onChange={(event, value) => handleClick(value)}
          defaultValue={i}
          renderInput={value => (
            <TextField {...value} label={rest?.label} name={field?.name} variant="outlined" />
          )}
        />
        {errors == true && <FormHelperText>{t(error)}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default AutocompleteField;
