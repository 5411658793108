import { FormikConfig, Formik, Form, Field } from 'formik';
import React, { FC, useMemo } from 'react';
import { getInitialValues, getValidationSchema } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import styles from '../../FormWithConfig/FormWithConfig.module.scss';
import { FormEditWebinars } from 'src/types/forms';
import CustomTextField from 'src/components/FormWithConfig/FieldWithConfig/CustomTextField';
import FieldWithConfig from '../../FormWithConfig/FieldWithConfig';
import yup from '../../../utils/validation';
import { Button } from '@material-ui/core';

export type Props = {
  config: FormEditWebinars;
  onSubmit: FormikConfig<any>['onSubmit'];
  defaultValues?: Record<string, any>;
};
const EditFormWebinars: FC<Props> = ({ onSubmit, config, defaultValues }) => {
  const { t } = useTranslation();
  const input = [
    {
      name: 'is_draft',
      label: 'Чернетка',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'language',
      label: 'Мова',
      fieldType: 'radio',
      required: false,
      validate: null,
      options: [
        {
          label: 'Українська',
          value: 'ua',
        },
        {
          label: 'Русский',
          value: 'ru',
        },
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'name',
      label: 'Назва вебинара',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'description',
      label: 'Опис вебинара',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'start_date',
      label: 'Дати проведення вебинара (Початок)',
      fieldType: 'dateTimePicker',
      required: true,
      validate: yup.string().required(),
      fromutc: true,
    },
    {
      name: 'end_date',
      label: 'Дати проведення вебинара (Кінець)',
      fieldType: 'dateTimePicker',
      required: true,
      validate: yup.string().required(),
      fromutc: true,
    },
    {
      name: 'testing_end_date',
      label: 'Дата закриття доступу до тестування',
      fieldType: 'dateTimePicker',
      required: true,
      validate: yup.string().required(),
      fromutc: true,
    },
    {
      name: 'image',
      label: 'Зображення для сторінки вебинара',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
    {
      name: 'youtube_id',
      label: 'Youtube ID',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'text',
      label: 'Основний текст для сторінки вебинара',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'private',
      label: 'Закритий вебінар',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'points',
      label: 'Балів за вебінар',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 1,
      step: 1,
    },
    {
      name: 'success_percent',
      label: 'Процент правильних відповідей для проходження тесту',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
    {
      name: 'max_tries',
      label: 'Кількість спроб пройти тестування (0 - безліч)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'certificate_title',
      label: 'Назва сертифікату',
      fieldType: 'text',
      required: false,
      validate: null,
    },
  ];
  const initialValues = useMemo(() => getInitialValues(input, config), [input, config]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);
  return (
    <div className={''}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting }) => {
          return (
            <>
              <Form className={styles.form} autoComplete="off" noValidate>
                <div className={styles.wrapper}>
                  {input.map(field => {
                    return <FieldWithConfig key={field.name} {...field} />;
                  })}
                </div>
                <div className={styles.actions}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditFormWebinars;
