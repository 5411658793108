import React, { FC, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { getInitialValues, getValidationSchema } from '../../../../utils/helpers';
import { Form, Formik } from 'formik';
import classes from '../../WebinarsManagementPage/WebinarsManagementPage.module.scss';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import FieldWithConfig from '../../../../components/FormWithConfig/FieldWithConfig';
import yup from '../../../../utils/validation';

export type Props = {
  saveContent: ({ payload }) => Promise<any>;
  openModal: any;
  openIs: boolean;
  filterSavePayload?: (payload) => any;
};

const CreateEventPage: FC<Props> = ({
  openModal,
  openIs,
  filterSavePayload,
  saveContent,
  ...rest
}) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [submit, setSubmit] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const input = [
    {
      name: 'is_draft',
      label: 'Чернетка',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'language',
      label: 'Мова',
      fieldType: 'radio',
      required: false,
      validate: null,
      options: [
        {
          label: 'Українська',
          value: 'ua',
        },
        {
          label: 'Русский',
          value: 'ru',
        },
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'is_private',
      label: 'Закрита подія',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'name',
      label: 'Назва події',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'description',
      label: 'Опис події',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'start_date',
      label: 'Дати проведення події (Початок)',
      fieldType: 'dateTimePicker',
      required: false,
      validate: null,
    },
    {
      name: 'end_date',
      label: 'Дати проведення події (Кінець)',
      fieldType: 'dateTimePicker',
      required: false,
      validate: null,
    },
    {
      name: 'testing_end_date',
      label: 'Дата закриття доступу до тестування',
      fieldType: 'dateTimePicker',
      required: false,
      validate: null,
    },
    {
      name: 'event_plan',
      label: 'Файл для події',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
    },
    {
      name: 'image',
      label: 'Зображення для сторінки події',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
    {
      name: 'youtube_id',
      label: 'Youtube ID',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'points',
      label: 'Балів за подію',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      step: 1,
    },
    {
      name: 'success_percent',
      label: 'Процент правильних відповідей для проходження тесту',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
    {
      name: 'max_tries',
      label: 'Кількість спроб пройти тестування (0 - безліч)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'text',
      label: 'Основний текст для сторінки події',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'certificate_title',
      label: 'Назва сертифікату',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'partners_banner',
      label: 'Зображеня для партнерів',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
  ];

  const initialValues = useMemo(() => getInitialValues(input), [input]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);

  const handleClose = useCallback(() => {
    openModal(false);
  }, [openModal]);

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };
  const submitEvents = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      await saveContent({ payload });
      openModal(false);
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'lg'}
        open={openIs}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Formik
          onSubmit={submitEvents}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <>
                <Form className={classes.form} autoComplete="off" noValidate>
                  <AppBar className={classes.appBar}>
                    <Toolbar className={classes.flex_just}>
                      <div className={classes.flex}>
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {t('EVENT.CREATE')}
                        </Typography>
                      </div>
                      <div>
                        <Button autoFocus color="inherit" type="submit">
                          {t('COMMON.ADD_TO')}
                        </Button>
                      </div>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <div className={classes.wrapper}>
                      <div className={classes.isForm}>
                        {input.map(field => {
                          return <FieldWithConfig key={field.name} {...field} />;
                        })}
                      </div>
                    </div>
                  </DialogContent>
                </Form>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateEventPage;
