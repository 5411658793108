import React, { FC, useCallback, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ConditionalRender from '../../../../../components/ConditionalRender';
import Backdrop from '../../../../../components/Backdrop';
import className from '../../../WebinarsManagementPage/pages/EditPage/WebinarsEdidPage.module.scss';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getSpecializationsId, putEventById, putSpecializationsId } from 'src/api';
import FormControlCourses from '../../../CoursesManagementPage/FormControl';
import Typography from '@material-ui/core/Typography';
import { WebinarsForm } from '../../../../../api/types';
import yup from '../../../../../utils/validation';

type Props = unknown;

const CategoriesEditPage: FC<Props> = () => {
  const { categoriesId } = useParams();
  const [content, setContent] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getSpecializationsId({ urlParams: { categoriesId } });
      setContent(data);
    } catch {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    if (typeof res?.banner === 'string') {
      delete res.banner;
    }

    return res;
  };
  const input = [
    {
      name: 'name',
      label: 'Назва спеціальності',
      fieldType: 'text',
      required: false,
      validate: yup.string().required(),
    },
    {
      name: 'secondary_name',
      label: 'Назва категорії',
      fieldType: 'text',
      required: false,
      validate: yup.string().required(),
    },
    {
      name: 'sort_id',
      label: 'Порядковий номер',
      fieldType: 'number',
      required: false,
      validate: yup.string().required(),
    },
    {
      name: 'main',
      label: 'Додати в обрані на сторінку "Головна"',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'main_on_categories_page',
      label: 'Додати в обрані на сторінку "Розділи"',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'banner',
      label: 'Зображення для категорії',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
  ];

  const handleSubmit = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      await putSpecializationsId({
        payload,
        urlParams: { categoriesId },
      });
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <ConditionalRender condition={loading}>
      <Backdrop invisible />
      <Container disableGutters maxWidth="lg">
        <Link className={className.link} to={{ pathname: `/categories-management` }}>
          <Button variant="contained" size="large" color="default">
            {t('COMMON.BACK')}
          </Button>
        </Link>
        <Typography variant="h6" id="tableTitle" className={className.title}>
          Редагування категорії
        </Typography>

        <FormControlCourses
          onSubmit={handleSubmit}
          defaultValues={content}
          config={content}
          input={input}
        />
      </Container>
    </ConditionalRender>
  );
};

export default CategoriesEditPage;
