export const regionsOptions = [
  'REGIONS.CRIMEA',
  'REGIONS.VINNYTSIA',
  'REGIONS.VOLYN',
  'REGIONS.DNEPROPETROVSK',
  'REGIONS.DONETSK',
  'REGIONS.ZHYTOMYR',
  'REGIONS.TRANSCARPATHIAN',
  'REGIONS.ZAPOROZHYE',
  'REGIONS.IVANO-FRANKIVSK',
  'REGIONS.KYIV',
  'REGIONS.KYIVSKA',
  'REGIONS.KIROVOGRAD',
  'REGIONS.LUHANSK',
  'REGIONS.LVIV',
  'REGIONS.NIKOLAEV',
  'REGIONS.ODESSA',
  'REGIONS.POLTAVA',
  'REGIONS.RIVNE',
  'REGIONS.SEVASTOPOL',
  'REGIONS.SUMY',
  'REGIONS.TERNOPIL',
  'REGIONS.KHARKIV',
  'REGIONS.KHERSON',
  'REGIONS.KHMELNYTSKY',
  'REGIONS.CHERKASY',
  'REGIONS.CHERNIVTSI',
  'REGIONS.CHERNIHIV',
];
