import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { useMemo } from 'react';

const useRouter = () => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => {
    return qs.parse(location.search.substring(1));
  }, [location.search]);

  return {
    ...location,
    history,
    queryParams,
  };
};

export default useRouter;
