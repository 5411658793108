import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import yup from '../../../../utils/validation';
import { getInitialValues, getValidationSchema } from '../../../../utils/helpers';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { Form, Formik } from 'formik';
import classes from '../../WebinarsManagementPage/WebinarsManagementPage.module.scss';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import FieldWithConfig from '../../../../components/FormWithConfig/FieldWithConfig';
import { Specialization } from '../../../../types';
import { getCoursesId, getSpecializations } from '../../../../api';

export type Props = {
  saveContent: ({ payload }) => Promise<any>;
  openModal: any;
  reload: any;
  openIs: boolean;
  filterSavePayload?: (payload) => any;
};
const CreateCourses: FC<Props> = ({
  openModal,
  openIs,
  filterSavePayload,
  saveContent,
  reload,
  ...rest
}) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [submit, setSubmit] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const [specializations, setSpecializations] = useState<Specialization[]>();

  const fetchData = useCallback(async () => {
    const { data } = await getSpecializations();

    setSpecializations(data.results);
  }, [enqueueSnackbar]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { t } = useTranslation();
  const input = [
    {
      name: 'is_draft',
      label: 'Чернетка',
      fieldType: 'checkboxTextOne',
      required: false,
      validate: null,
    },
    {
      name: 'language',
      label: 'Мова',
      fieldType: 'radio',
      required: false,
      validate: null,
      options: [
        {
          label: 'Українська',
          value: 'ua',
        },
        {
          label: 'Русский',
          value: 'ru',
        },
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'add_to_favourites',
      label: 'Додати курс в обрані',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'name',
      label: 'Назва курса',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'description',
      label: 'Опис курса',
      fieldType: 'textarea',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'points',
      label: 'Балів за курс',
      fieldType: 'number',
      required: true,
      validate: yup.string().required(),
      min: 1,
      step: 1,
    },
    {
      name: 'author',
      label: 'Автор курса',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'specializations',
      label: 'Спеціальності користувачів, які зможуть приймати участь у навчанні',
      fieldType: 'autocomplete',
      required: true,
      validate: yup.string().required(),
      options: specializations
        ? specializations.map(spec => ({
            label: spec.name,
            value: spec.id,
          }))
        : [],
    },
    {
      name: 'start_date',
      label: 'Дати проведення курсу (початок)',
      fieldType: 'datePicker',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'end_date',
      label: 'Дати проведення курсу (кінец)',
      fieldType: 'datePicker',
      required: true,
      validate: yup.string().required(),
    },

    {
      name: 'certificate_title',
      label: 'Назва сертифікату',
      fieldType: 'text',
      required: false,
      validate: null,
    },
    {
      name: 'certificate_available',
      label: 'Наявність сертифікату',
      fieldType: 'checkboxTextOne',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'test_time',
      label: 'Час на проходження тесту (хвилини)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'success_percent',
      label: 'Процент правильних відповідей для проходження курсу',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
    {
      name: 'max_tries',
      label: 'Кількість можливостей пройти курс (0 - безліч)',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
    },
    {
      name: 'course_image',
      label: 'Зображення для сторінки курсу',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
    {
      name: 'text',
      label: 'Опис курса',
      fieldType: 'richText',
      required: false,
      validate: null,
    },
    {
      name: 'has_test',
      label: 'Додати тест після курсу',
      fieldType: 'CheckboxTextOneField',
      required: false,
      validate: null,
    },
    {
      name: 'test_questions_count',
      label: 'Кількість тестових питань',
      fieldType: 'number',
      required: false,
      validate: null,
      min: 0,
      max: 100,
    },
  ];
  const content = {
    certificate_available: true,
  };
  const initialValues = useMemo(() => getInitialValues(input, content), [input]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);

  const handleClose = useCallback(() => {
    openModal(false);
  }, [openModal]);

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };

  const submitWebinars = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      await saveContent({ payload });
      openModal(false);
      reload(true);
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'lg'}
        open={openIs}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Formik
          onSubmit={submitWebinars}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <>
                <Form className={classes.form} autoComplete="off" noValidate>
                  <AppBar className={classes.appBar}>
                    <Toolbar className={classes.flex_just}>
                      <div className={classes.flex}>
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {t('CATEGORIES.ADD_CATEGORIES')}
                        </Typography>
                      </div>
                      <div>
                        <Button autoFocus color="inherit" type="submit">
                          {t('COMMON.ADD_TO')}
                        </Button>
                      </div>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <div className={classes.wrapper}>
                      <div className={classes.isForm}>
                        {input.map(field => {
                          return <FieldWithConfig key={field.name} {...field} />;
                        })}
                      </div>
                    </div>
                  </DialogContent>
                </Form>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateCourses;
