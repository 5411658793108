import React, { FC, useCallback, useEffect, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { getInitialValues, getValidationSchema } from '../../../../utils/helpers';
import yup from '../../../../utils/validation';
import { postCoursesLessons, postEventPartners, putEventPartners } from '../../../../api';
import classes from './CreatePartnersEvent.module.scss';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar/AppBar';
import FieldWithConfig from '../../../../components/FormWithConfig/FieldWithConfig';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';

export type Props = {
  saveContent?: any;
  openModal: any;
  eventId: any;
  reload?: any;
  openIs?: boolean;
  config?: any;
  filterSavePayload?: (payload) => any;
};

const CreatePartnersEvent: FC<Props> = ({
  openModal,
  openIs,
  filterSavePayload,
  saveContent,
  reload,
  eventId,
  config,
  ...rest
}) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  // const [maxWidth, setMaxWidth] = React.useState<string>('lg');
  const [submit, setSubmit] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {}, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClose = useCallback(() => {
    openModal(false);
  }, [openModal]);
  const input = [
    {
      name: 'name',
      label: 'Назва партнера',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'link',
      label: 'Посилання на партнера',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'order_number',
      label: 'Порядок',
      fieldType: 'number',
      required: true,
      validate: yup.string().required(),
      min: 0,
    },
    {
      name: 'image',
      label: 'Логотип партнера',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
  ];
  const initialValues = useMemo(() => getInitialValues(input, config), [input, config]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);

  const submitPartners = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      if (config) {
        await putEventPartners({ payload, urlParams: { eventId, partnerId: config.id } });
        saveContent(array =>
          array.filter(a => {
            if (a.id === config.id) {
              Object.assign(a, payload);
            }
            return a;
          }),
        );
      } else {
        const { data } = await postEventPartners({ payload, urlParams: { eventId } });
        saveContent(array => [...array, data]);
      }
      openModal(false);
      reload(true);
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'lg'}
        open={openIs}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Formik
          onSubmit={submitPartners}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <>
                <Form className={classes.form} autoComplete="off" noValidate>
                  <AppBar className={classes.appBar}>
                    <Toolbar className={classes.flex_just}>
                      <div className={classes.flex}>
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {config ? t('EVENT.EDIT_PARTNERS') : t('EVENT.CREATE_PARTNERS')}
                        </Typography>
                      </div>
                      <div>
                        <Button autoFocus color="inherit" type="submit">
                          {config ? t('COMMON.SAVE') : t('COMMON.ADD_TO')}
                        </Button>
                      </div>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <div className={classes.wrapper}>
                      <div className={classes.isForm}>
                        {input.map(field => {
                          return <FieldWithConfig key={field.name} {...field} />;
                        })}
                      </div>
                    </div>
                  </DialogContent>
                </Form>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreatePartnersEvent;
