import React, { FC, useMemo } from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { FieldConfig, FieldProps } from 'formik';
import { FormControl, InputLabel, MenuItem, FormHelperText, makeStyles } from '@material-ui/core';
import { Option } from 'src/types';
import { TFunction } from 'i18next';

type Props = SelectProps &
  FieldConfig &
  FieldProps & { options: Option[]; mapoptions: (options: Option[], t: TFunction) => Option[] };

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectField: FC<Props> = ({ field, options, mapoptions, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const error = useMemo(() => {
    const err = rest.form.errors[field.name];

    return err ? String(err) : '';
  }, [field, rest.form.errors]);

  const opts = useMemo(() => (mapoptions ? mapoptions(options, t) : options), [
    options,
    t,
    mapoptions,
  ]);

  return (
    <FormControl variant={rest.variant} error={!!error} className={classes.formControl}>
      <InputLabel id="simple-select-label">{rest.label}</InputLabel>
      <Select labelId="simple-select-label" id="simple-select" {...rest} {...field}>
        {opts?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t(error)}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;
