import React, { FC } from 'react';
import DataTable from 'src/components/DataTable';
import { getEvents, deleteEventById, postEvents } from 'src/api';

import Container from '@material-ui/core/Container';
import { CellParams, ColDef, GridApi } from '@material-ui/data-grid';
import { formatDateCell } from '../../../utils/helpers/table';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DiaogDelete from '../../../components/Dialog/Delete';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import classes from '../WebinarsManagementPage/WebinarsManagementPage.module.scss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CreateEventPage from './CreateEventPage';
import CreateCoursesLesson from '../CoursesManagementPage/CreateLesson';
import { WebinarsForm } from '../../../api/types';
import CreateWebinarsModal from '../../../components/Webinars/CreateModal';
import SearchBar from '../../../components/DataTable/SearchBar';

type Props = unknown;

const EventsManagementPage: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [getReload, setGetReload] = React.useState();
  const [deleteItem, setDeleteItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState();

  const columns: ColDef[] = [
    { field: 'id', headerName: 'DATA_TABLE.ID', type: 'number' },
    { field: 'name', headerName: 'DATA_TABLE.NAME', width: 500 },
    {
      field: 'start_date',
      headerName: 'DATA_TABLE.DATE',
      width: 200,
      type: 'dateTime',
      valueFormatter: formatDateCell,
    },
    {
      field: 'place',
      headerName: 'DATA_TABLE.PLACE',
      width: 300,
    },
    {
      field: '',
      headerName: 'DATA_TABLE.ACTIONS',
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const id = params?.data.id;
        const onClick = () => {
          deleteClickOpen();
          setDeleteItem(id);
        };
        return (
          <>
            <Link aria-label="edit" color="primary" to={{ pathname: `/edit-event/${id}` }}>
              <IconButton aria-label="edit" color="primary">
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton aria-label="delete" color="secondary" onClick={onClick}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const deleteClickOpen = () => {
    setDeleteDialog(true);
  };

  const deleteClose = () => {
    setDeleteDialog(false);
  };
  const deleteActive = async (values, actions) => {
    try {
      await deleteEventById({ urlParams: { eventId: deleteItem } });
      setGetReload(true);
      enqueueSnackbar(t('COMMON.DELETE_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      setDeleteDialog(false);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const filterSavePayload = (values: WebinarsForm) => {
    const res = { ...values };

    ['image', 'event_plan', 'partners_banner'].forEach(field => {
      if (typeof res?.[field] === 'string') {
        delete res[field];
      }
    });

    ['start_date', 'end_date', 'testing_end_date'].forEach(field => {
      res[field] = new Date(res?.[field]).toISOString();
    });

    return res;
  };

  const handleSearch = async (values, actions) => {
    setSearch(values.search);
  };

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <SearchBar search={search} handleSearch={handleSearch} />
        <DataTable getRowsApi={getEvents} columns={columns} reload={getReload} search={search} />
        <DiaogDelete
          deleteDialog={deleteDialog}
          deleteClose={deleteClose}
          removeActive={deleteActive}
        />
      </Container>
      <div className={classes.wrapper_button}>
        <Button
          variant="contained"
          disabled={open}
          color="primary"
          aria-label="add"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Button>
      </div>
      <CreateEventPage
        openModal={setOpen}
        openIs={open}
        saveContent={postEvents}
        filterSavePayload={filterSavePayload}
      />
    </>
  );
};

export default EventsManagementPage;
