import React, { FC, useMemo } from 'react';
import {
  FormControl,
  TextFieldProps,
  FormLabel,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { FieldConfig, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = TextFieldProps & FieldConfig & FieldProps;

const CheckboxTextOneField: FC<Props> = ({ field, ...rest }) => {
  const { t } = useTranslation();
  const error = useMemo(() => {
    const err = rest.form.errors[field.name];

    return err ? String(err) : '';
  }, [field, rest.form.errors]);
  return (
    <FormControl component="fieldset" error={!!error}>
      <FormLabel component="legend">{rest?.label}</FormLabel>
      <FormControlLabel
        name={rest?.name}
        control={<Switch checked={field?.value == true ? true : false} color="primary" />}
        label={rest?.label}
        {...field}
      />
    </FormControl>
  );
};
export default CheckboxTextOneField;
