import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import { COOKIE_ACCESS_TOKEN } from 'src/constants/auth';
import { root } from 'src/api/apiUrls';

export type CustomAxiosRequestConfig = AxiosRequestConfig & {
  isPrivate?: boolean;
};

const API = axios.create({});

API.defaults.baseURL = root;

API.interceptors.request.use((config: CustomAxiosRequestConfig) => {
  const cookies = new Cookies();
  const accessToken = cookies.get(COOKIE_ACCESS_TOKEN);
  if (config.isPrivate && accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

export default API;
