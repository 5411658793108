import React, { FC } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
type Props = {
  deleteDialog: boolean;
  deleteClose: any;
  removeActive: any;
};

const DiaogDelete: FC<Props> = ({ deleteDialog, deleteClose, removeActive }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return (
    <Dialog
      open={deleteDialog}
      onClose={deleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Ви впевнені, що хочете видалити?'}</DialogTitle>
      <DialogActions>
        <Button onClick={deleteClose} color="primary">
          {t('COMMON.CLOSE')}
        </Button>
        <Button onClick={removeActive} color="secondary" autoFocus>
          {t('COMMON.DELETE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DiaogDelete;
