import React, { FC, useMemo } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { FieldConfig, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField/TextField';

type Props = TextFieldProps & FieldConfig & FieldProps;

const CustomNumberField: FC<Props> = ({ field, ...rest }) => {
  const { t } = useTranslation();

  const error = useMemo(() => {
    const err = rest.form.errors[field.name];

    return err ? String(err) : '';
  }, [field, rest.form.errors]);

  return <TextField error={!!error} type="number" helperText={t(error)} {...rest} {...field} />;
};

export default CustomNumberField;
