import React, { FC, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getInitialValues, getValidationSchema } from '../../../../utils/helpers';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { Form, Formik } from 'formik';
import classes from '../../WebinarsManagementPage/WebinarsManagementPage.module.scss';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import FieldWithConfig from '../../../../components/FormWithConfig/FieldWithConfig';
import yup from '../../../../utils/validation';

export type Props = {
  saveContent: ({ payload }) => Promise<any>;
  openModal: any;
  reload: any;
  openIs: boolean;
  filterSavePayload?: (payload) => any;
};
const CreateCategoryes: FC<Props> = ({
  openModal,
  openIs,
  filterSavePayload,
  saveContent,
  reload,
  ...rest
}) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [submit, setSubmit] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const input = [
    {
      name: 'name',
      label: 'Назва спеціальності',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'secondary_name',
      label: 'Назва категорії',
      fieldType: 'text',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'sort_id',
      label: 'Порядковий номер',
      fieldType: 'number',
      required: true,
      validate: yup.string().required(),
    },
    {
      name: 'main',
      label: 'Додати в обрані на сторінку "Головна"',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'main_on_categories_page',
      label: 'Додати в обрані на сторінку "Розділи"',
      fieldType: 'checkboxOne',
      required: false,
      validate: null,
    },
    {
      name: 'banner',
      label: 'Зображення для категорії',
      fieldType: 'fileUpload',
      required: false,
      maxfilesize: 10000000,
      acceptedfiles: ['image/jpeg', 'image/png'],
    },
  ];

  const initialValues = useMemo(() => getInitialValues(input), [input]);
  const validationSchema = useMemo(() => getValidationSchema(input), [input]);

  const handleClose = useCallback(() => {
    openModal(false);
  }, [openModal]);

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };
  const submitWebinars = async (values, actions) => {
    try {
      const payload = filterSavePayload ? filterSavePayload(values) : values;
      await saveContent({ payload });
      openModal(false);
      reload(true);
      enqueueSnackbar(t('COMMON.SAVED_SUCCESSFULLY'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('ERRORS.SOMETHING_WENT_WRONG'), { variant: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'lg'}
        open={openIs}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Formik
          onSubmit={submitWebinars}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <>
                <Form className={classes.form} autoComplete="off" noValidate>
                  <AppBar className={classes.appBar}>
                    <Toolbar className={classes.flex_just}>
                      <div className={classes.flex}>
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {t('CATEGORIES.ADD_CATEGORIES')}
                        </Typography>
                      </div>
                      <div>
                        <Button autoFocus color="inherit" type="submit">
                          {t('COMMON.ADD_TO')}
                        </Button>
                      </div>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <div className={classes.wrapper}>
                      <div className={classes.isForm}>
                        {input.map(field => {
                          return <FieldWithConfig key={field.name} {...field} />;
                        })}
                      </div>
                    </div>
                  </DialogContent>
                </Form>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateCategoryes;
