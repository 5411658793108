import { FieldConfig, FieldProps } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { TextFieldProps } from '@material-ui/core';
import styless from './TinymceEditorConfig.module.scss';

type Props = TextFieldProps & FieldConfig & FieldProps;

const TinymceEditor = ({ field, form, ...rest }: Props) => {
  const ref = useRef<any>(null);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [initialValue] = useState(field.value);

  const handleEditorChange = (content, editor) => {
    form.setFieldValue(field.name, content);
  };

  return (
    <>
      <label className={styless.label} htmlFor="">
        {rest?.label}
      </label>
      <Editor
        apiKey="db03vz9jkat4hivi66d1kpdtzjxn2bffbm85nbfw9fpw3yqo"
        initialValue={initialValue}
        init={{
          height: 300,
          menubar: false,
          language: 'uk',
          skin: 'material-classic',
          content_css: 'material-classic',
          icons: 'material',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'insertfile undo redo | styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | code',
          relative_urls: false,
          inline: false,
          preview_styles: false,
          paste_data_images: true,
        }}
        onEditorChange={handleEditorChange}
      />
    </>
  );
};

export default TinymceEditor;
