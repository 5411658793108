// import { isHippocrates } from './sites';

// const ASPROFOSVIT_COOKIE_ACCESS_TOKEN = 'asprofosvit_access_token';
// const ASPROFOSVIT_COOKIE_ACCESS_TOKEN_EXP = 'asprofosvit_access_token_exp';
// const ASPROFOSVIT_COOKIE_REFRESH_TOKEN = 'asprofosvit_refresh_token';
// const HIPPOCRATES_COOKIE_ACCESS_TOKEN = 'hippocrates_access_token';
// const HIPPOCRATES_COOKIE_ACCESS_TOKEN_EXP = 'hippocrates_access_token_exp';
// const HIPPOCRATES_COOKIE_REFRESH_TOKEN = 'hippocrates_refresh_token';

// export const COOKIE_ACCESS_TOKEN = isHippocrates
//   ? HIPPOCRATES_COOKIE_ACCESS_TOKEN
//   : ASPROFOSVIT_COOKIE_ACCESS_TOKEN;
//
// export const COOKIE_ACCESS_TOKEN_EXP = isHippocrates
//   ? HIPPOCRATES_COOKIE_ACCESS_TOKEN_EXP
//   : ASPROFOSVIT_COOKIE_ACCESS_TOKEN_EXP;
//
// export const COOKIE_REFRESH_TOKEN = isHippocrates
//   ? HIPPOCRATES_COOKIE_REFRESH_TOKEN
//   : ASPROFOSVIT_COOKIE_REFRESH_TOKEN;

export const COOKIE_ACCESS_TOKEN = 'hippocrates_shared_access_token';
export const COOKIE_ACCESS_TOKEN_EXP = 'hippocrates_shared_access_token_exp';
export const COOKIE_REFRESH_TOKEN = 'hippocrates_shared_fresh_token';
