import React, { FC, useMemo } from 'react';
import { Field } from 'formik';
import { InputBase } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import styles from './FieldWithConfig.module.scss';
import { FormFieldConfig } from '../../../types';
import CustomTextField from './CustomTextField';
import CustomChipInput from './CustomChipInput';
import FileUpload from './FileUpload';
import RichTextFieldOld from './RichTextField/RichTextField';
import DatePickerField from './DatePickerField';
import SelectField from './SelectField';
import DateTimePickersField from './DateTimePickersField';
import CustomerTextareaField from './CustomerTextareaField';
import CustomNumberField from './CustomNumberField';
import AutocompleteField from './AutocompleteField';
import RadioField from './RadioField';
import CheckboxOneField from './CheckboxOneField';
import CheckboxTextOneField from './CheckboxTextOneField';
import TinymceEditor from './TinymceEditor';

type Props = FormFieldConfig;

const FieldWithConfig: FC<Props> = ({
  name,
  fieldType,
  label,
  required,
  maxfilesize,
  acceptedfiles,
  options,
  mapoptions,
  min,
  max,
  step,
  fromutc,
}) => {
  const { t } = useTranslation();

  const FieldComponent = useMemo(() => {
    switch (fieldType) {
      case 'text': {
        return CustomTextField;
      }
      case 'textarea': {
        return CustomerTextareaField;
      }
      case 'number': {
        return CustomNumberField;
      }
      case 'chip': {
        return CustomChipInput;
      }
      case 'autocomplete': {
        return AutocompleteField;
      }
      case 'fileUpload': {
        return FileUpload;
      }
      case 'richText': {
        // return RichTextFieldOld;
        return TinymceEditor;
      }
      case 'datePicker': {
        return DatePickerField;
      }
      case 'dateTimePicker': {
        return DateTimePickersField;
      }
      case 'select': {
        return SelectField;
      }
      case 'radio': {
        return RadioField;
      }
      case 'checkboxOne': {
        return CheckboxOneField;
      }
      case 'checkboxTextOne': {
        return CheckboxTextOneField;
      }
      default: {
        return InputBase;
      }
    }
  }, [fieldType]);

  const fieldLabel = useMemo(() => {
    return t(label);
  }, [label, t]);

  return (
    <div className={styles.wrapper}>
      <Field
        name={name}
        component={FieldComponent}
        variant="outlined"
        label={fieldLabel}
        required={required}
        maxfilesize={maxfilesize}
        acceptedfiles={acceptedfiles}
        options={options}
        mapoptions={mapoptions}
        fromutc={fromutc}
        InputProps={{ inputProps: { min: min, max: max, step: step } }}
        classes={{
          root: styles.formField,
        }}
      />
    </div>
  );
};

export default FieldWithConfig;
